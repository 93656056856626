import React, { useContext } from "react";
import { ModalContext } from "../modal/ModalContext";
import CreateMarketDataRequest from "../../components/request/create-request-dialog/CreateMarketDataRequest";
import { REQUEST_STATUSES } from "../../constants/request";

const RequestedChanges = ({ext, status, editRequestData}) => {
  const {showInModal} = useContext(ModalContext);

  const addMarkets = ext.addMarkets.map((marketInfo)=>{
    return `${marketInfo.market}`;
  })

  const removeMarkets = ext.removeMarkets.map((marketInfo)=>{
    return `${marketInfo.market}`;
  })

  const handleClick = () => {
    showInModal(() => <CreateMarketDataRequest editRequestData={editRequestData}/>);
  }

  return <div className="table-market-box">
    <div>
      {addMarkets.length ? <div className="table-market-field addMarkets">{addMarkets.join(', ')}</div> : null}
      {removeMarkets.length ? <div className="table-market-field removeMarkets">{removeMarkets.join(', ')}</div> : null}
    </div>
    {status && status === REQUEST_STATUSES.OPEN
      ?
      <i title="Create Request" className="material-icons table-market-pencil" onClick={handleClick}>edit</i>
      :
      null
    }
  </div>
}

export default RequestedChanges;
