import startImg from "../../images/marketdata/start.svg";
import traderapi from "../../traderapi/api";
import {REQUEST_STATUSES} from "../../constants/request";
import {pushToast} from "../toaster/helper";

const StartProcess = ({context, request}) => {

  const startProcess = async (e) => {
    e.stopPropagation();

    const success = await traderapi.setRequestStatus(request.id, REQUEST_STATUSES.START)
    if(success) {
      context.refreshDataset('softRefresh');
      pushToast(`Request for ${request.applicationID.reference.client} is being fulfilled`)
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <img
        onClick={startProcess}
        src={startImg}
        alt="Start"
      />
    </div>
  )
}

export default StartProcess;
