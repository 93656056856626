import React from 'react';

const Bundles = ({bundle, onBundleButtonClick, applicationIsBlocked, dataWasChanged, buttonItem, primaryBundle}) => {
    const setButtonClassName = (index) => {
        if (index === 0) return "first-button";
        if (index === bundle.length - 1) return "last-button";
    };
    const onClickHandler = (e, name) => {
        onBundleButtonClick(e,name)
    };
    const setBundleColor = (name) => {
        if (name === buttonItem && buttonItem !== primaryBundle)
            return "waiting-bundle"
        if (name === buttonItem && buttonItem === primaryBundle && dataWasChanged)
            return "waiting-bundle"
        if (name === primaryBundle) return "primary-bundle";
    }
    return (
      <div className="marketdata-bundle">
        <span className="bundle-text">
          Bundle:
        </span>
        <div className="marketdata-button-group">
          {bundle.map((button, idx) => (
            <button
              key={idx}
              disabled={applicationIsBlocked}
              id={`radio-${idx}`}
              name={button.name}
              className={`bundle-button ${setButtonClassName(
                idx
              )} ${setBundleColor(button.name)}`}
              onClick={(e) => !button.disabled && onClickHandler(e, button.name)}
            >
              {button.name}
              <hr />
            </button>
          ))}
        </div>
      </div>
        
    )
}

export default Bundles;