import { useState } from "react";
import cn from 'classnames';
import {
  Button,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "shards-react";

import { FULL_REQUEST_FORMATTED_CATEGORY } from "../../../constants/request";

import './styles.css';

const AppliedValue = ({ setIsChangedAppliedValue, data }) => {
  const isMaxLossOrReEnable = [FULL_REQUEST_FORMATTED_CATEGORY.max_loss, FULL_REQUEST_FORMATTED_CATEGORY.re_enable].includes(data.Category.value);
  const receivedLimit = isMaxLossOrReEnable ? 10000000 : 100000000;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const handleCheckInputValue = (value) => {
    setIsValid((+value !== +data?.ext?.applied) && +value <= receivedLimit);
  }

  const handleInputField = ({ target }) => {
    const { value, maxLength } = target;
    const limitValue = value.slice(0, maxLength);
    setValue(limitValue);
  };

  const handleBlur = () => inputValue && setValue(inputValue);

  const setValue = (value) => {
    let resultValue = Number.isInteger(parseFloat(value)) ? parseInt(value, 10) : value;
    handleCheckInputValue(`${Math.abs(resultValue)}`)
    setInputValue(`${Math.abs(resultValue)}`);
  }

  const handleConfirmButton = () => {
    setIsChangedAppliedValue(+inputValue)
    setIsOpenModal(false)
  }

  return <>
    &nbsp;<span className="applied-value" onClick={() => setIsOpenModal(true)}><i className="material-icons">edit</i></span>
    <div className="applied-modal-container">
      <Modal size="sm" open={isOpenModal} toggle={() => setIsOpenModal(false)}>
        <ModalHeader className="modal-header">
          <span>Enter Applied Value</span>
        </ModalHeader>

        <div className="modal-wrapper">
          <ModalBody className="modal-body">

            <div className="modal-input">
              <span className="modal-input-label">Applied Value</span>
              <FormInput
                autoFocus
                type="number"
                value={inputValue}
                maxLength={9}
                min={0}
                max={receivedLimit}
                onChange={handleInputField}
                onBlur={handleBlur}
                className={cn({ 'modal-input-field--error': !isValid })}
              />
            </div>
            {!isValid && <div className="modal-error">Invalid Input</div>}
          </ModalBody>
          <ModalFooter className="modal-footer">
            <Button
              theme="white"
              disabled={!inputValue || !isValid}
              onClick={handleConfirmButton}
            >
              Save
            </Button>
            <Button
              theme="white"
              onClick={() => setIsOpenModal(false)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  </>

}

export default AppliedValue;
