import React, { useEffect, useState } from "react";
import momentTZ from "moment-timezone";

import constants from "../flux/constants";
import { utilityFunctions } from "../utils/utilityFunctions";

const { reviewStatuses } = constants;
const { DEFICIENT, UPDATED } = reviewStatuses;

const EditFormItem = ({
  value,
  label,
  userID,
  review,
  onChangeValueHandler,
  onReviewChangeHandler,
  checkDataSet,
  setFormIsEditing,
  formIsEditing,
  isImage = false,
}) => {
  const [imageOpened, setImageOpened] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageDownloaded, setCurrentImageDownloaded] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [removeImageIsHovered, setRemoveImageIsHovered] = useState(null);
  const [textareaHeight, setTextareaHeight] = useState(30);
  const efi = "edit-form-input";
  const efdi = "edit-form-disabled-input";
  const cp = "cursor-pointer";
  const cd = "cursor-default";

  const formatter = (value) => value ? utilityFunctions.currencyFormater().format(value) : "";

  useEffect(() => {
    if (isImage &&
         currentImageDownloaded &&
         (value.name.split(":")[0] === currentImageDownloaded.name && value.name.split(":")[1] !== currentImageDownloaded.fileName)
       ) {
        getImage()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, currentImageDownloaded]);

  useEffect(() => {
    if (isImage && !currentImageDownloaded) {
      getImage();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImage, currentImageDownloaded]);

  const getImage = async () => {
    const result = await new Promise((resolve) => {
      setIsPending(true);
      value.getter((image) => resolve(image));
    });
    if (result) {
      setCurrentImageDownloaded({
        name: value.name.split(":")[0],
        fileName: value.name.split(":")[1],
        img: result,
      });
      setIsPending(false);
    }
  };

  const openBase64NewTab = (base64Pdf) => {
    const base64ToArrayBuffer = (data) => {
      let binaryString = window.atob(data);
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    };
    let arrBuffer = base64ToArrayBuffer(base64Pdf);
    let blob = new Blob([arrBuffer], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  };

  const imageReturn = () => {
    const onImageClick = (image) => {
      if (image.img.length > 68) {
        setImageOpened(!imageOpened);
        setCurrentImage(image);
      }
    };
    const renderOpenedImage = () => {
      if (imageOpened) {
        return (
          <div
            className="opened-image-block"
            onClick={() => {
              setImageOpened(false);
              setCurrentImage(null);
            }}
          >
            <div className="image-block-background" />
            <div className="opened-image-wrapper">
              <span className="scan-title">{currentImage?.name}</span>
              <img
                src={currentImage?.img}
                alt="scan"
                className="opened-image"
              />
            </div>
          </div>
        );
      }
    };
    if (isPending) return <span>...</span>;
    if (currentImageDownloaded) {
      const isPDF =
        currentImageDownloaded?.img.split("/")[1].split(";")[0] === "pdf";
      return (
        <div
          onClick={() =>
            isPDF
              ? openBase64NewTab(
                  currentImageDownloaded?.img.split(",")[1],
                  currentImageDownloaded?.img.split(":")[1].split(";")[0]
                )
              : onImageClick(currentImageDownloaded)
          }
          className={`${efi} scans`}
        >
          {isPDF
            ? <>
            <img
                src={`${process.env.PUBLIC_URL}/pdf-icon.png`}
                className={"set-pdf-image-size"}
                alt="pdf"
              />
              <img
                src={`${process.env.PUBLIC_URL}/link.png`}
                className={"set-pdf-image-link-size"}
                alt="link"
              />
              </>
            : currentImageDownloaded.img.length < 68
              ? <span>N/A</span>
              : <img src={currentImageDownloaded.img} alt="currentImage" />
          }
          {renderOpenedImage()}
        </div>
      );
    }
  };

  const renderBlankField = (fieldType) => {
    if (!value.value) {
      const onBlankClick = () => {
        if (!value.readonly) {
          const found = document.getElementById(`${userID}-${label}`)
          if (fieldType === "picker") {
            found.showPicker()
          } else {
            found.click()
          }
        }
      }
      return (
        <div onClick={onBlankClick} className={`${efi}-blank-label`} />
      )
    }
  }
  const dateReturn = () => {
    const changeDataHandler = (e) => {
      if (!value.readonly) {
        let newValue = e.target.value;
        onChangeValueHandler(newValue, label);
      }
    };

    return (
      <input
        onClick={() => setFormIsEditing(true)}
        type={"date"}
        id={`${userID}-${label}`}
        value={value?.value?.split("T")[0] || ""}
        readOnly={value.readonly}
        onChange={changeDataHandler}
        className={valueClassName(value)}

      />
    );
  };

  const valueClassName = (value) => {
    return `${value?.readonly
      ? `${efi} ${efdi} ${cd}`
      : !formIsEditing
      ? `${efi} ${efdi} ${cp}`
      : `${efi}`}  ${value?.badvalue ? 'incorrect-value' : ''}`
  }

  const valueReturn = (inputType = "text", isDateTime) => {
    const setValue = () => {
      if (inputType === "url")
        return value?.id
      if (value?.changeFormat)
        return formatter(value?.value)
      if (isDateTime)
        return momentTZ(value.value).tz(value.timezone).format("YYYY.MM.DD hh:mm:ss a");
      return value?.value
    }
    return (
      <input
        onClick={() => setFormIsEditing(true)}
        type={inputType}
        id={value?.value}
        value={setValue()}
        onChange={(e) =>
          !value?.readonly && onChangeValueHandler(e.target.value, label)
        }
        className={valueClassName(value)}
      />
    )
  };

  const getExtRef = (v) => {
    if (v.isReference && v.external) {
      let url = "";
      if (v.withIDProp) {
        url = v.reference(v.id)
      } else {
        url = (typeof v.reference === "function" ? v.reference() : v.reference) +
        "/" +
        v.id;
      }
      return url;
    }
  };

  const defaultReturn = (inputType = "text") => {
    if (inputType === "url") {
      return (
        <a
          className={efi}
          target="_blank"
          rel="noopener noreferrer"
          href={getExtRef(value)}
        >
          {value?.id}
          {value?.isReference && value?.external && value?.id ? (
            <span className="material-icons">open_in_new</span>
          ) : null}
        </a>
      );
    } else {
      return (
        <input
          type={"text"}
          id={value}
          value={typeof value === "undefined" || value === null ? "" : value}
          onChange={(e) =>
            !value?.readonly && onChangeValueHandler(e.target.value, label)
          }
          onClick={(e) => {
            setFormIsEditing(true);
          }}
          className={valueClassName(value)}
        />
      );
    }
  };

  const booleanReturn = () => {
    const select = value.select || ["false", "true"];
    const renderOptions = select.map((item, index) => {
      return (
        <option value={item} key={index}>
          {item}
        </option>
      );
    });
    return (
      <select
        onClick={() => setFormIsEditing(true)}
        value={value.value ? select[1] : select[0]}
        disabled={value.readonly}
        className={valueClassName(value)}

        onChange={(e) =>
          !value.readonly &&
          onChangeValueHandler(e.target.value === select[1], label)
        }
      >
        {renderOptions}
      </select>
    );
  };

  const enumReturn = () => {
    const renderOptions = value?.values.map((item, index) => {
      return (
        <option value={item} key={index}>
          {item}
        </option>
      );
    });
    const setIncorrectValueStyles =
      value.value !== value.values.find((item) => item === value.value) &&
      "incorrect-value";
    return (
      <>
      {renderBlankField()}
      <select
        value={value.value}
        onClick={() => setFormIsEditing(true)}
        disabled={value.readonly}
        id={`${userID}-${label}`}
        className={
          value?.readonly
            ? `${efi} ${efdi} ${cd}`
            : !formIsEditing
            ? `${efi} ${efdi} ${cp} ${setIncorrectValueStyles}`
            : `${setIncorrectValueStyles} ${efi} ${cp}`
        }
        onChange={(e) =>
          !value.readonly && onChangeValueHandler(e.target.value, label)
        }
      >
        {renderOptions}
      </select>
      </>
    );
  };

  const listReturn = () => {
    const textAreaValue = value?.list?.join("\n");
    if (label === "changelog") {
      const renderChangelogInfo = () => {
        const date = textAreaValue?.split("- ").filter(n=>n).map((item,index) => {
          return (
            <tr key={index}>
              <td>{item.split(".:")[0]}</td>
              <td>{item.split(".:")[1]}</td>
            </tr>
          )
        })
        return date
      }
      return (
        <div className="changelog-table">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Event</th>
            </tr>
          </thead>
          <tbody>
            {renderChangelogInfo()}
          </tbody>
        </table>
        </div>
      )
    }

    return (
      <textarea
        onClick={() => setFormIsEditing(true)}
        className={valueClassName(value)}

        readOnly={value?.readonly}
        rows={Math.max(1, Math.min(value?.lines ?? 1, value?.list?.length))}
        value={textAreaValue}
      />
    );
  };

  const setItemBGColor = (isField) => {
    if (isField && (review?.status === DEFICIENT || review?.status === UPDATED))
      return "transparent";
    if (review?.status === DEFICIENT) return "rgb(255 176 176 / 50%)";
    if (review?.status === UPDATED) return "#EEEE9B";
    // return "white";
  };

  const renderCurrentChildren = () => {
    if (value?.isEnum) return enumReturn();
    if (value?.isDate) return dateReturn();
    if (value?.isDateTime) return valueReturn("text","datetime");
    if (value?.isBoolean) return booleanReturn();
    if (value?.isReference) return defaultReturn("url");
    if (isImage) return imageReturn();
    if (value?.isList) return listReturn();
    if (value?.isValue) return valueReturn();
    return defaultReturn();
  };

  const renderCommentField = () => {
    const onCommentCHange = (e) => {
      const { value, name, scrollHeight } = e.target;

      if (scrollHeight !== textareaHeight) {
        setTextareaHeight(scrollHeight)
      }

      if (name === `review-comment-${label}`) {
        onReviewChangeHandler(label, value, isImage);
      }
    };
    const deleteComment = (e) => {
      e.preventDefault();
      setTextareaHeight(30)
      setRemoveImageIsHovered(null);
      onReviewChangeHandler(label, "", isImage, true);
    };
    const checkboxValuesAbscence = () => {
      if (label === "pep") return !!value;
      return true;
    };
    if (
      utilityFunctions.getApplicationItemCommentableList().includes(label) &&
      checkboxValuesAbscence()
    ) {
    const detectHoverOn = (e) => {
      setRemoveImageIsHovered(label);
    }
    const detectHoverOut = (e) => {
      setRemoveImageIsHovered(null);
    }

    return (
        <div className="commented-field">
          <textarea
            rows="1"
            spellCheck="true"
            style={{ height: textareaHeight }}
            onClick={() => setFormIsEditing(true)}
            onChange={onCommentCHange}
            name={`review-comment-${label}`}
            value={review?.comment || ""}
            className={
              !formIsEditing ? `${efi} ${efdi} ${cp} textarea-comment` : `${efi} ${cp} textarea-comment`
            }
          />
          {review?.comment && (
            <button className="comment-delete-btn" onMouseOut={detectHoverOut}
            onMouseOver={detectHoverOn} name={label} onClick={deleteComment}>
              <img
                name={label}

                className={removeImageIsHovered === label ? "hovered-remove-image" : undefined}
                src={`${process.env.PUBLIC_URL}/cancel.png`}
                alt="cancel"
              />
            </button>
          )}
        </div>
      );
    }
  };

  return (
    <div
      className={`edit-form-item ${
        value?.isList || isImage ? "aligning-top" : ""
      }`}
      style={{ backgroundColor: setItemBGColor() }}
    >
      <div className="main-label">
        {value?.readonly && (
          <span className="material-icons lock-icon">lock</span>
        )}
        <span className="label-title">
          {utilityFunctions.changeLabelView(label)}
        </span>
      </div>
      {renderCurrentChildren()}
      {checkDataSet() && renderCommentField()}
    </div>
  );
};

export default EditFormItem;
