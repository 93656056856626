import React from "react";
import { Button } from "shards-react";

const TableButton = ({ data }) => {
  const { 'Actions': userCreds } = data;

  return (
    <div className="text-center">
      <Button
        theme="info"
        onClick={async (event) => {
          event.stopPropagation();
          await navigator.clipboard.writeText(userCreds);
          // pushToast(`VPN credentials for ${traderId} are copied.`);
        }}
      >
        Copy VPN credentials
      </Button>
    </div>
  )
}

export default TableButton;
