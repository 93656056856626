import traderapi from "../../traderapi/api";

class ReportDuplicateGovid {

    makeRecord(a) {
        return {
            name: a._id,
            count: a.count,
            items: a.ids
        }
    }
    //
    list(params, cb) {
        traderapi.getReportDuplicateGovid( (data) => {
            const items = data.map(this.makeRecord);
            cb({
                items,
                data,
            });
        }, params);
    }
}

export default ReportDuplicateGovid;