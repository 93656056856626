import React from 'react'
import traderapi from "../../traderapi/api";
import F from '../fields.js';
import TimeToResolution from "../../components/request/TimeToResolution";
import {
  REQUEST_FORMATTED_STATUSES,
} from "../../constants/request";
import DueDate from "../../components/request/DueDate";
import TraderId from "../../components/request/TraderId";
import Platform from "../../components/request/Platform";
import WhosDoing from "../../components/request/WhosDoing";
import RequestedChanges from "../../components/request/RequestedChanges";
import { tabsListData } from "../../components/datasetTabs/tabsListData";

class RequestDataset {
  makeRecord(a) {
    const { _id, createdAt, applicationID, ext, status, userID, time, sequenceNumber, schedule, priority } = a;
    const formattedStatus = status && REQUEST_FORMATTED_STATUSES[status];

    try {
      return {
        id: _id,
        schedule,
        priority,
        applicationID,
        status,
        time,
        createdAt,
        ext,
        assignee: userID,
        'Due Date': {
          label: 'dueDate',
          readonly: true,
          render: (rowItem) => <DueDate request={rowItem} status={status}/>
        },
        'Sequence Number': sequenceNumber,
        'Requested On': F.createFieldDateTime(time.OPEN ? time.OPEN : createdAt, { readonly: true }),
        'ID': F.createField(sequenceNumber, { readonly: true }),
        'Trader ID': {
          label: 'Trader ID',
          render: (rowItem, context) => <TraderId request={rowItem} context={context}/>
        },
        'Platform': {
          label: 'Platform',
          render: (rowItem, context) => <Platform platform={rowItem.applicationID?.software}/>,
        },
        'Account Type': applicationID?.accountType,
        'Platform Status': {
          label: 'Platform Status',
          render: rowItem => <div className='text-capitalize'>{rowItem.applicationID?.platformStatus || ''}</div>
        },
        'Requested Changes': {
          label: 'Requested Changes',
          readonly: true,
          render: () => <RequestedChanges ext={ext} editRequestData={a} status={status}/>
        },
        'Status': formattedStatus,
        'Who\'s Doing': {
          align: 'center',
          label: 'Who\'s Doing',
          render: (rowItem, context) => <WhosDoing request={rowItem} context={context}/>
        },
        'Time to resolution': {
          label: 'Time to resolution',
          readonly: true,
          render: (rowItem) => <TimeToResolution request={rowItem} />
        },
      }

    } catch (error) {
    }
  }

  list(params, cb) {
    traderapi.getRequests((data) => {
      const items = data.items.map(this.makeRecord);
      const tabsListKeys = Object.keys(tabsListData);
      let defaultHeaders;
      let header;

        tabsListKeys.forEach(k => {
          if (tabsListData[k].type === params.type) {
            defaultHeaders = tabsListData[k].defaultHeader
          }
        })

        if (defaultHeaders) {
          header = [...defaultHeaders.map(h => {
            return {
              id: h,
              colname: h,
              hidden: false
            }
          })];
        }

      cb({
        items,
        data,
        header
      });

    }, params);
  }
}

export default RequestDataset;
