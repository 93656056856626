import { pushToast } from "../toaster/helper";
import "./requests-styles.css";

const TraderId = ({ request }) => {
  const clientRef = request.applicationID?.reference?.client || "";

  const onClickHandle = (e) => {
    e.stopPropagation();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(clientRef);
      pushToast(`${clientRef} is copied.`);
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = clientRef;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.execCommand("copy");
      pushToast(`${clientRef} is copied.`);
      
      document.body.removeChild(textArea);
    }
  };

  return (
    <span className="trader-id-cell-styles" onClick={onClickHandle}>
      {clientRef}
    </span>
  );
};

export default TraderId;
