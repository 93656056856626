import { pushToast } from "components/toaster/helper"
import { TOAST_TYPE_ALERT } from "components/toaster/types"
import constants from "flux/constants"
import moment from "moment"

const getDayDiff = (date, date2 = moment()) => {
  return date2
    .startOf('day')
    .diff(date.startOf('day'), 'days')
}

const setStatusErrorHandler = (error, declineError) => {
  if (declineError) {
    pushToast(constants.requestErrorMessage, TOAST_TYPE_ALERT)
  }
  const msg = error.message && error.response?.data?.message
  pushToast(msg, TOAST_TYPE_ALERT)
  return false;
}

export { getDayDiff, setStatusErrorHandler }
