import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
} from "shards-react";
import Chart from "../../utils/chart";
import {Store} from "../../flux";

const MAX_LABEL_SIZE = 20;
const LABEL_OVERSIZE_SUFFIX = '...';
class ApplicationsByStatus extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    this.onDataChanged = this.onDataChanged.bind(this);
    this.state = {};
  }

  componentDidMount() {
    Store.resetDatasetListener(this.onDataChanged, true);
    this.onDataChanged();
  }

  componentWillUnmount() {
    Store.resetDatasetListener(this.onDataChanged, false);
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getCustomOrder(data, customOrder) {
    if(customOrder && data) {
      const initial = customOrder.reduce((o,s)=>{ o[s] = 0; return o}, {});
      const result = Object.entries(data).reduce((a,c) => { a[c[0]] = c[1]; return a; }, initial);
      Object.keys(result).forEach((k) => result[k] === 0 && delete result[k]);
      return result;
    }
    return data;
  }

  onDataChanged() {
    const { dataget, customColors, customOrder } = this.props;
    const data = this.getCustomOrder(dataget(), customOrder);
    if(!data) {
      return;
    }
    
    const chartData = {
      datasets: [
        {
          hoverBorderColor: "#ffffff",
          data: Object.values(data),
          backgroundColor: Object.keys(data).map((s)=>( (customColors&&customColors[s]) || this.getRandomColor()))
        }
      ],
      labels: Object.keys(data).map((d)=>{
        if(d && d.length>MAX_LABEL_SIZE) {
          return d.slice(0, MAX_LABEL_SIZE-LABEL_OVERSIZE_SUFFIX.length) + LABEL_OVERSIZE_SUFFIX;
        }
        return d;
      })
    };

    const chartConfig = {
      type: "pie",
      data: chartData,
      options: {
        ...{
          legend: {
            position: "right",
            labels: {
              padding: 5,
              bottom: 0,
              boxWidth: 10
            }
          },
          cutoutPercentage: 0,
          tooltips: {
            custom: false,
            mode: "index",
            position: "nearest"
          }
        },
        ...this.props.chartOptions
      }
    };

    new Chart(this.canvasRef.current, chartConfig);
  }
  
  render() {
    const { title } = this.props;
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="d-flex py-0">
          <canvas
            height="220"
            ref={this.canvasRef}
            className="blog-users-by-device m-auto"
          />
        </CardBody>
      </Card>
    );
  }
}

ApplicationsByStatus.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object
};

ApplicationsByStatus.defaultProps = {
  title: "Applications by status",
  chartData: {
    datasets: [
      {
        hoverBorderColor: "#ffffff",
        data: [5, 2, 4, 3, 1, 12 ],
        backgroundColor: [
          "rgba(134, 219, 247, 1)",
          "rgba(134, 219, 247, 0.6)",
          "rgb(237, 22, 47)",
          "rgb(255, 246, 0)",
          "rgba(255, 246, 0, 0.5)",
          "rgb(16, 243, 69)"
        ]
      }
    ],
    labels: ["Incomplete", "InProgress", "RequireInformation", "Received", "Updated", "Approved" ]
  }
};

export default ApplicationsByStatus;
