import React, {useContext} from "react";
import {ModalContext} from "../modal/ModalContext";
import CreateMarketDataRequest from "./create-request-dialog/CreateMarketDataRequest";

const CreateRequestButton = (props) => {
  const {showInModal} = useContext(ModalContext);
  return (
    <button {...props} onClick={() => {
      showInModal(()=> <CreateMarketDataRequest data={{label: "label", data: "data"}}/>)
    }
    }>Create Request</button>)
}

export default CreateRequestButton;
