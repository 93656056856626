import traderapi from "../../traderapi/api";
import { utilityFunctions } from "../../utils/utilityFunctions";
import F from '../fields.js';

class TradingPlatformDataset {

    makeRecord(a) {
        return {
            id: F.createFieldReference( "tradingplatforms", a._id),
            name: a.name,
            price: utilityFunctions.currencyFormater().format(a.price),
            description: a.description,
            software: a.software,
            softwareType: a.softwareType,
            traderIDprefix: a.traderIDprefix,
            reference: a.reference        }
    }
    //
    list(params, cb) {
        traderapi.getTradingPlatform((data) => {
            const items = data.tradingplatforms.map(this.makeRecord);
            cb({
                items,
                data
            });
            
        }, params);
    }
    //
    get(id, cb) {
        this.list({id}, cb)
    }
    //
    save(n, o, cb) {
        const mdp = {
            name: n.name,
            price: n.price,
            description: n.description,
            software: n.software,
            softwareType: n.softwareType,
            traderIDprefix: n.traderIDprefix,
            reference: n.reference
        };
        traderapi.saveEntity('static/tradingplatforms',(data) => {
            if(data.success) {
                cb(data, n, o);
            } else {
                console.error(`Updating tradingplatforms failed`, data);
                alert(data.message);
            }
        }, mdp);
    }
}

export default TradingPlatformDataset;