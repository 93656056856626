import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "shards-react";
import './TableModal.css'

const TableImageModal = ({isVisible, close, image}) => {
  const openBase64NewTab = (base64Pdf) => {
    const base64ToArrayBuffer = (data) => {
      let binaryString = window.atob(data);
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    };

    let arrBuffer = base64ToArrayBuffer(base64Pdf);
    let blob = new Blob([arrBuffer], { type: "application/pdf" });


    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }

    close()
  };

  const getBodyContent = () => {
    const isPDF = image?.split("/")[1].split(";")[0] === "pdf";

    if (!image) {
      return <>. . .</>;
    }

    if (isPDF && isVisible) {
      openBase64NewTab(image.split(",")[1]);
      return null;
    } else {
      return (
        <>
          <div>
            <img alt='' className={'modal-image'} src={image}/>
          </div>
        </>
      )
    }
  }

  return (
    <div>
      <Modal size="lg" open={isVisible} toggle={close}>
        <ModalHeader className="table-modal-header">Confirmation</ModalHeader>

        <div className="table-modal-wrapper">

          <ModalBody className="table-modal-body">
            {getBodyContent()}
          </ModalBody>
          <ModalFooter className="table-modal-footer">
            <Button
              theme="white"
              onClick={close}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
}

export default TableImageModal;
