import traderapi from "../../traderapi/api";
import F from '../fields.js';

class PresetDataset {

    makeRecord(a) {
        return {
            GENERAL: F.createSeparator('General'),
            id: F.createFieldReference("presets", a.presetID),
            name: a.name,
            description: a.description,
            active: F.createFieldBoolean( a.active, {select: ['Inactive', 'Active']}),
            created: F.createFieldDateTime(a.created, { readonly: true}),
            templates_application: F.createFieldList(a.templates_application?.map((c)=>(`${c.field}: ${c.value} : ${c.generate}`))||[] ,{readonly: true, lines: 7}),
            templates_salesforce: F.createFieldList(a.templates_salesforce?.map((c)=>(`${c.field}: ${c.value} : ${c.generate}`))||[] ,{readonly: true, lines: 7}),
        }
    }
    //
    list(params, cb) {
        traderapi.getPresets((data) => {
            const items = data.presets.map(this.makeRecord);
            cb({
                items,
                data
            });
            
        }, params);
    }
    //
    get(id, cb) {
        this.list({clientID: id}, cb)
    }
    //
    save(item, cb) {
    traderapi.saveApplication((data) => {
        cb(data);
    }, item);
    }
}

export default PresetDataset;