import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import SmallStats from "../components/common/SmallStats";
import ApplicationsOverview from "../components/blog/AppilcationsOverview";
import ApplicationsByStatus from "../components/blog/ApplicationsByStatus";
import { Store,Dispatcher,Events } from "../flux";
import moment from 'moment';
import RecentApplications from "../components/blog/RecentApplications";
import DatasetToolbox from "../components/dataset-toolbox/DatasetToolbox";
import RecentOnboarding from "../components/blog/RecentOnboarding";


const StatusColors = {
  Incomplete: "rgba(134, 219, 247, 0.5)",
  InProgress: "rgba(134, 219, 247, 1)",
  RequireInformation: "rgb(237, 22, 47)",
  Received: "rgb(255, 246, 0, 0.5)",
  Updated: "rgba(255, 246, 0, 1)",
  Approved: "rgb(16, 243, 69)",
  Rejected: "rgba(134, 219, 247, 0.5)",
  AccountEnabled: "rgb(16, 243, 69)",
  AccountCreated: "rgba(16, 243, 69, 0,5)",
};

const CountryColors = {
  Ukraine: "yellow",
  UA: "yellow",
  Russia: "red",
  RU: "red",
  Other: "rgba(0,0,0,0.6)",
  "null": "rgba(0,0,0,0.7)"
};

const TradingPlatformColors = {
  DAS: '#F5AD21',
  TradeZero: "rgba(91, 196, 129, 0.24)",
  Sterling: "rgb(33, 78, 126)",
  "null": 'rgba(0,0,0,0.6)'
};

const StepColors = {
  'Account info': "rgb(16, 243, 69, 0.1)",
  'Trading profile': "rgb(16, 243, 69, 0.3)",
  'Verification mail': "rgb(16, 243, 69, 0.6)",
  'Personal info' : "rgb(16, 69, 243, 0.1)",
  'Address': "rgb(16, 69, 243, 0.3)",
  'ID Verification': "rgb(16, 69, 243, 0.8)",
  'Payout Calculation': "rgb(16, 243, 69, 0.2)",
  'Legal': "rgb(16, 243, 69, 0.5)",
  'Completed': "rgb(16, 243, 69, 1)",
};

const StatusOrder = [
  "Incomplete",
  "InProgress",

  "Received",
  "Updated",
  "UnderReview",
  "RequireInformation",

  "Approved",
  "EnableRequest",
  "DisableRequest",

  "WirePending",
  "WireSent",
  "WireConfirmed",
  "WireFailed",

  "AccountCreated",
  "AccountEnabled",
  "AccountSuspended",
  "AccountClosed",
  "AccountReopened",

  "Cancelled",
  "Rejected",
  "Closed",
];

const StepOrder = [
  'Account info',
  'Trading profile',
  'Verification mail',
  'Personal info',
  'Address',
  'ID Verification',
  'Payout Calculation',
  'Legal',
  'Completed'
];

class AccountioOverview extends React.Component {
  constructor(props) {
    super(props);
    this.onDataChanged = this.onDataChanged.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.state = { data:null, chartData: []};
  }

  componentDidMount() {
    Store.resetDatasetListener(this.onDataChanged, true);
    Store.resetEmitter(true, Events.NEWSEARCH, this.onSearch);
    Dispatcher.dispatch({actionType: Events.SETWORKINGDATASET, payload: this.props.dataset});

    this.onDataChanged();
  }

  componentWillUnmount() {
    Store.resetDatasetListener(this.onDataChanged, false);
    Store.resetEmitter(false, Events.NEWSEARCH, this.onSearch);
  }

  onSearch() {
    //console.log("SEARCH", Store.getSearchPattern());
  }

  getWeeks(created, n, Nweeks) {
    const d = Object.values(created).reduce((a,o)=>(a+o), 0);
    const grow = (n>d) ? (d*100/(n-d)) : 1000;
    const weeks = new Array(Nweeks).fill(0);
    Object.entries(created).forEach((e)=>{
      var dd = Math.round(moment().diff(moment(e[0], "YYYY-MM-DD"), 'days')/7);
      if(dd>weeks.length) {
        dd = weeks.length - 1;
      } else if(dd<0) {
        dd = 0;
      }
      weeks[weeks.length - (dd+1)] += e[1];
    });
    const weeksGrow = weeks.map((v)=>(v));
    weeksGrow.forEach((e,i) => {
      if(i===0) {
        weeksGrow[i] = e;
      } else {
        weeksGrow[i] = (e + weeksGrow[i-1]);
      }
    });

    return {weeks, weeksGrow, grow, d};
  }

  defaultChartdata() {
    const chartData = [ {
      label:"Applications",
      value: "---",
      percentage: "---",
      increase: true,
      decrease: false,
      chartLabels: [],
      datasets: [
        {
          label: "created",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 0, 255, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: []
        },
        {
          label: "createdw",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 255, 0, 0.1)",
          borderColor: "rgb(0, 155, 0)",
          data: []
        }
    ]
  },
  {
    label:"Onboarding",
    value: '---',
    percentage: '---',
    increase: true,
    decrease: false,
    chartLabels: [0,1,2,3],
    datasets: [
      {
        label: "created",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        borderColor: "rgb(0, 184, 216)",
        data: [0,0,0,0]
      },
      {
        label: "createdw",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(0, 255, 0, 0.1)",
        borderColor: "rgb(0, 155, 0)",
        data: [0,0,0,0]
      }
    ]
  }];

  return chartData;
  }

  onDataChanged() {
    const { dataset } = this.props;
    const data = Store.getDataset(dataset);
    const stats = data.items;

    var chartData = this.defaultChartdata();

    if(stats.applications) {
      const day4weeksAgo = moment().subtract(4, 'weeks');
      const gt4 = (d) => {
        return moment(d, "YYYY-MM-DD").diff(day4weeksAgo) >= 0;
      }
      const sApp = this.getWeeks( Object.fromEntries(Object.entries(stats.applications.created).filter((e)=>(gt4(e[0])))), stats.applications.n, 4);
      const sReg = this.getWeeks( Object.fromEntries(Object.entries(stats.registrations.created).filter((e)=>(gt4(e[0])))), stats.registrations.n, 4);



      chartData = [ {
          label:"Applications",
          value: stats.applications.n,
          percentage: `${sApp.grow.toFixed(1)}%`,
          increase: (sApp.d>0),
          decrease: false,
          chartLabels: sApp.weeks.map((e,i)=>(i)),
          datasets: [
            {
              label: "created",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgba(0, 0, 255, 0.1)",
              borderColor: "rgb(0, 184, 216)",
              data: sApp.weeks
            },
            {
              label: "createdw",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgba(0, 255, 0, 0.1)",
              borderColor: "rgb(0, 155, 0)",
              data: sApp.weeksGrow
            }
        ]
      },
      {
        label:"Onboarding",
        value: stats.registrations.n,
        percentage: `${sReg.grow.toFixed(1)}%`,
        increase: (sReg.d>0),
        decrease: false,
        chartLabels: sReg.weeks.map((e,i)=>(i)),
        datasets: [
          {
            label: "created",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(0, 0, 255, 0.1)",
            borderColor: "rgb(0, 184, 216)",
            data: sReg.weeks
          },
          {
            label: "createdw",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(0, 255, 0, 0.1)",
            borderColor: "rgb(0, 155, 0)",
            data: sReg.weeksGrow
          }
        ]
    }
    ];
  }

    this.setState({data, chartData});
  }

  render() {
    const { chartData } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Accounti⚙ Overview"  className="text-sm-left mb-3" />
        <DatasetToolbox
          options={{refresh:()=>{
            this.setState({data:null, chartData:this.defaultChartdata()});
            Dispatcher.dispatch({actionType: Events.RELOADDATA, payload: this.props.dataset});

          }}}
          >

        </DatasetToolbox>
      </Row>

      {/* Small Stats Blocks */}
      <Row>
        <Col lg="3" className="col-lg mb-4">
          {chartData.map((stats, idx) => (
                    <Row key={`chrow-${idx}`}>

            <Col lg="12" md="12" sm="12" className="col-lg mb-4" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
            </Row>

          ))}

        </Col>

      {/* Users Overview */}
      <Col lg="9" md="12" sm="12" className="mb-4">
        <ApplicationsOverview dataget={() => { return Store.getDataset(this.props.dataset).items?.applications?.created}}/>
      </Col>
      </Row>
      <Row>
      {/* Users by Device */}
      <Col lg="6" md="6" sm="12" className="mb-4" >
        <ApplicationsByStatus
          title="Applications by status"
          customColors={StatusColors}
          customOrder={StatusOrder}
          dataget={()=> { return Store.getDataset(this.props.dataset).items?.applications?.status } }
        />
      </Col>

      <Col lg="6" md="6" sm="12" className="mb-4" >
        <ApplicationsByStatus
          title="Onboarding by step"
          dataget={()=> { return Store.getDataset(this.props.dataset).items?.registrations?.step } }
          customColors={StepColors}
          customOrder={StepOrder}
        />
      </Col>

      <Col lg="4" md="6" sm="12" className="mb-4" >
        <ApplicationsByStatus
          title="Applications by country"
          dataget={()=> { return Store.getDataset(this.props.dataset).items?.applications?.country } }
          customColors={CountryColors}
        />
      </Col>


      <Col lg="4" md="6" sm="12" className="mb-4" >
        <ApplicationsByStatus
          title="Applications by platform"
          dataget={()=> { return Store.getDataset(this.props.dataset).items?.applications?.software } }
          customColors={TradingPlatformColors}
        />
      </Col>

      <Col lg="4" md="6" sm="12" className="mb-4" >
        <ApplicationsByStatus
          title="Applications by groups"
          dataget={()=> { return Store.getDataset(this.props.dataset).items?.applications?.group } }
        />
      </Col>
      {/* Discussions */}
      <Col lg="12" md="12" sm="12" className="mb-4">
        <RecentApplications
          title="Recent applications"
          dataget={()=> { return Store.getDataset(this.props.dataset).items?.applications?.updatedLast }}
        />
      </Col>


      <Col lg="6" md="12" sm="12" className="mb-4">
        <RecentApplications
          title="Enable requests"
          dataget={()=> { return Store.getDataset(this.props.dataset).items?.applications?.enableRequest }}
        />
      </Col>

      <Col lg="6" md="12" sm="12" className="mb-4">
        <RecentApplications
          title="Disable requests"
          dataget={()=> { return Store.getDataset(this.props.dataset).items?.applications?.disableRequest }}
        />
      </Col>

      <Col lg="12" md="12" sm="12" className="mb-4">
        <RecentOnboarding
          title="Recent Onboarding forms"
          dataget={()=> { return Store.getDataset(this.props.dataset).items?.registrations?.updatedLast }}
        />
      </Col>


      {/* New Draft */}
      {/* <Col lg="4" md="6" sm="12" className="mb-4">
        <NewDraft title="Trader communication"/>
      </Col> */}

      {/* Top Referrals */}
      {/* <Col lg="5" md="12" sm="12" className="mb-4">
        <TopReferrals />
      </Col> */}
    </Row>
  </Container>
    );
  }
}

AccountioOverview.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

AccountioOverview.defaultProps = {
  smallStats: [
    {
      label: "Applications",
      value: "160",
      percentage: "4.7%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        }
      ]
    },
    {
      label: "Users",
      value: "6",
      percentage: "0.0%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [1, 2, 3, 3, 3, 4, 4]
        }
      ]
    },
    {
      label: "User groups",
      dataset: "groups",
      value: "3",
      percentage: "0.0%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      label: "Presets",
      value: "2",
      percentage: "0.0%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
  ]
};

export default AccountioOverview;
