const NOT_STARTED = 'Not started';
const TODAY = 'Today';
const TOMORROW = 'Tomorrow';
const NO_ONE = 'No one';

const REQUEST_STATUSES = {
    OPEN: 'OPEN',
    COMPLETED: 'COMPLETED',
    CANCELED: 'CANCELED',
    BLOCKED: 'BLOCKED',
    ONHOLD: 'ONHOLD',
    INPROGRESS: 'INPROGRESS',
    START: 'start',
    STOP: 'stop',
    RESOLVE: 'resolve',
    NEW_MESSAGE: 'NEW_MESSAGE',
    WAITING_FOR_CLIENT: 'WAITING_FOR_CLIENT',
    WAITING_FOR_3RD_PARTY: 'WAITING_FOR_3RD_PARTY',
    DECLINED: 'DECLINED',
    PENDING_EOD: 'PENDING_EOD',
    STALE: 'STALE',
};

const REQUEST_ACTION = {
    START: {
        label: 'Start',
        command: 'start',
    },
    CANCEL: {
        label: 'Cancel',
        command: 'cancel',
    },
    DECLINE: {
        label: 'Decline',
        command: 'decline',
    },
    PENDING_EOD: {
        label: 'Pending EOD',
        command: 'pendingeod',
    },
    RESOLVE: {
        label: 'Resolve',
        command: 'resolve',
    },
    WAITING_FOR_CLIENT: {
        label: 'Waiting for Client',
        command: 'waitingforclient',
    },
    STALE: {
        label: 'Stale',
        command: 'stale',
    },
    STOP: {
        label: 'Stop',
        command: 'stop',
    },
    WAITING_FOR_3RD_PARTY: {
        label: 'Waiting for 3rd Party',
        command: 'waitingfor3rdparty',
    },
    NEW_MESSAGE: {
        label: 'New Message',
        command: 'newMessage',
        noaction: true
    },
};

const REQUEST_STATUS_ACTION = {
    [REQUEST_STATUSES.OPEN]: [REQUEST_ACTION.START, REQUEST_ACTION.NEW_MESSAGE],
    [REQUEST_STATUSES.INPROGRESS]: [ REQUEST_ACTION.RESOLVE, REQUEST_ACTION.STOP, REQUEST_ACTION.DECLINE, REQUEST_ACTION.PENDING_EOD, REQUEST_ACTION.WAITING_FOR_CLIENT, REQUEST_ACTION.WAITING_FOR_3RD_PARTY, REQUEST_ACTION.NEW_MESSAGE],
    [REQUEST_STATUSES.ONHOLD]: [REQUEST_ACTION.START, REQUEST_ACTION.NEW_MESSAGE],
    [REQUEST_STATUSES.WAITING_FOR_3RD_PARTY]: [REQUEST_ACTION.START, REQUEST_ACTION.NEW_MESSAGE],
    [REQUEST_STATUSES.WAITING_FOR_CLIENT]: [ REQUEST_ACTION.RESOLVE, REQUEST_ACTION.STALE, REQUEST_ACTION.NEW_MESSAGE],
    [REQUEST_STATUSES.PENDING_EOD]: [REQUEST_ACTION.START, REQUEST_ACTION.NEW_MESSAGE],
    [REQUEST_STATUSES.COMPLETED]: [REQUEST_ACTION.NEW_MESSAGE],
    [REQUEST_STATUSES.CANCELED]: [REQUEST_ACTION.NEW_MESSAGE],
    [REQUEST_STATUSES.BLOCKED]: [REQUEST_ACTION.NEW_MESSAGE],
    [REQUEST_STATUSES.NEW_MESSAGE]: [REQUEST_ACTION.NEW_MESSAGE, REQUEST_ACTION.START],
    [REQUEST_STATUSES.DECLINED]: [REQUEST_ACTION.NEW_MESSAGE],
    [REQUEST_STATUSES.STALE]: [REQUEST_ACTION.NEW_MESSAGE],
};

const REQUEST_FORMATTED_STATUSES = {
    OPEN: '✉ New',
    COMPLETED: '✓ Resolved',
    CANCELED: '☠ Canceled',
    BLOCKED: '⛔ Blocked',
    ONHOLD: '🚧 On hold',
    INPROGRESS: '🚀 In progress',
    NEW_MESSAGE: '📬 New message',
    WAITING_FOR_CLIENT: '🤔 Waiting for client',
    WAITING_FOR_3RD_PARTY: '🤷 Waiting for 3rd party',
    DECLINED: '❌ Declined',
    PENDING_EOD: '⏳ Pending EOD',
    STALE: '🕰 Stale',
};

const REQUEST_INITIATORS = {
    USER: 'USER',
    SYSTEM: 'SYSTEM',
    TRADER: 'TRADER'
};

const REQUEST_TYPES = {
    MARKETDATA: 'MARKETDATA',
    RISKSETTING: 'RISKSETTING',
    INQUIRY: 'INQUIRY',
    QUESTION: 'QUESTION'
};

const REQUEST_FORMATTED_CATEGORY = {
    max_loss: 'Max Loss',
    floating_day_buying_power: 'Order Day Buying Power',
    floating_night_buying_power: 'Order Pre & Post Market Buying Power',
    night_buying_power: 'Pre & Post Market Buying Power',
    day_buying_power: 'Day Buying Power',
    auto_liquidate: 'Auto Liquidate',
    allowed_overnight_exposure: 'Allowed Overnight Exposure',
    max_position_exposure: 'Max Position Exposure',
    re_enable: 'Re-Enable',

};

const FULL_REQUEST_FORMATTED_CATEGORY = {
  ...REQUEST_FORMATTED_CATEGORY,
  re_enable: 'Re-Enable',
}

export {
    NO_ONE,
    REQUEST_STATUSES,
    REQUEST_INITIATORS,
    REQUEST_TYPES,
    REQUEST_FORMATTED_STATUSES,
    REQUEST_FORMATTED_CATEGORY,
    FULL_REQUEST_FORMATTED_CATEGORY,
    REQUEST_ACTION,
    REQUEST_STATUS_ACTION,
    NOT_STARTED,
    TODAY,
    TOMORROW,
};
