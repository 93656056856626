import React, { useEffect, useRef, useState } from "react";
import { utils } from "./utils";

const ChatMessageItem = ({
  isPrivate,
  isAdmin,
  item,
  adminID,
  returnAttachment,
  _internal,
  _public,
  setMessageDisplayType,
}) => {
  const [isOverAvatar, setIsOverAvatar] = useState(false);

  const overAvatarRef = useRef(null);

  useEffect(() => {
    const node = overAvatarRef.current;
    if (node) {
      node.addEventListener("mouseover", () => setIsOverAvatar(true));
      node.addEventListener("mouseout", () => setIsOverAvatar(false));
      return () => {
        node.removeEventListener("mouseover", () => setIsOverAvatar(true));
        node.removeEventListener("mouseout", () => setIsOverAvatar(false));
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overAvatarRef.current]);

  const setAvatarNameAbbr = () => {
    return isAdmin
      ? !!item.author._id
        ? item.author.name.split(" ").length > 1
          ? `${item.author.name.split(" ")[0].slice(0, 1)}${item.author.name
              .split(" ")[1]
              .slice(0, 1)}`
          : item.author.name.slice(0, 1)
        : "Tr"
      : "Z";
  };
  const setBGColor = () => {
    return isAdmin
      ? !!item.author._id
        ? item.author.name !== adminID
          ? isPrivate
            ? "incoming-admin-type"
            : "incoming-type"
          : undefined
        : "incoming-type"
      : !!item.author._id
      ? "incoming-type"
      : undefined;
  };

  return (
    <div className={`chat-message-item ${setMessageDisplayType()}`}>
      <p className={`${setBGColor()} ${isPrivate ? "greyed" : undefined}`}>
        {item.message}
        {setMessageDisplayType() === "left-align" && (
          <div
            id={item.author.name}
            className={`chat-message-avatar ${isAdmin ? "cursor-help" : undefined} ${setBGColor()}`}
            ref={overAvatarRef}
          >
            {setAvatarNameAbbr()}
          </div>
        )}
      </p>
      <div className="chat-message-attaches">
        {item.attachments?.map((file, findex) => {
          if (item.author.name === adminID)
            return returnAttachment(
              file,
              findex,
              file.attachmentType === _internal
            );
          if (file.attachmentType === _public)
            return returnAttachment(file, findex);
          return null
        })}
      </div>
      <span className="chat-message-date">{utils.setDate(item.date)}</span>
      {item.author.name === overAvatarRef.current?.id && isAdmin && isOverAvatar && (
        <div className={`chat-message-avatar-full ${setBGColor()}`}>
          {item.author.name}
        </div>
      )}
    </div>
  );
};

export default ChatMessageItem;
