import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, FormCheckbox, Row } from 'shards-react';
import PickMenu from '../components/pick-menu/PickMenu';
import { Dispatcher, Events, Store } from '../flux';
import './ColumnEditor.css';

const ColumnEditor = ({header, pages, dataset, toggleColumnEditor, updateHeaderInStore}) => {
  const [headerItems, setHeaderItems] = useState([]);
  const [dataWasChanged, setDataWasChanged] = useState(false);

  useEffect(() => {
    setHeaderItems(deepCopyFunction(header));
    return () => {
      setDataWasChanged(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onRowChangeHandler = (newItem) => {
    const newHeaderItems = [...headerItems];
    newHeaderItems.forEach(el => el.id === newItem.id ? el = newItem : null);
    setHeaderItems(newHeaderItems);
    updateHeaderInStore(newHeaderItems);
  }


  const sortColumnDown = (colName) => {
    const newHeaderItems = [...headerItems];

    const i = newHeaderItems.findIndex((e)=>e.id === colName);

    if(i<newHeaderItems.length-1 && i>0) {
      const t = newHeaderItems[i];
      newHeaderItems[i] = newHeaderItems[i+1]; 
      newHeaderItems[i+1] = t;

      setHeaderItems(newHeaderItems);
      updateHeaderInStore(newHeaderItems);
    }
  }
  const sortColumnUp = (colName) => {
    const newHeaderItems = [...headerItems];

    const i = newHeaderItems.findIndex((e)=>e.id === colName);

    if(i<newHeaderItems.length && i>1) {
      const t = newHeaderItems[i];
      newHeaderItems[i] = newHeaderItems[i-1]; 
      newHeaderItems[i-1] = t;

      setHeaderItems(newHeaderItems);
      updateHeaderInStore(newHeaderItems);
    }
  }


  const renderPickMenu = () => {
    const optionArray = [10, 25, 50, 100, 200, 500];
    const onQuantityChange = (value) => {
      Dispatcher.dispatch({actionType: Events.SETPAGINATIONVALUES,
        payload: {quantity: value, startValue: pages.page, dataSetName: dataset}
      });
    }

    
    if (pages.total){
      return (
        <PickMenu
          onChangeHandler={onQuantityChange}
          optionArray={optionArray}
          selectedValue={Store.getDataset(dataset).paginationValues.quantity}
          menuLabel="Pick rows quantity"
        />
      )
    }
  }

  const renderSort = () => {
    const optionArray = headerItems?.map((i)=>(i.id));
    const onSortFieldChange = (field) => {
      Dispatcher.dispatch({actionType: Events.SETSORT,
        payload: {field, dataSetName: dataset}
      });
    }

    const onSortOrderChange = (order) => {
      Dispatcher.dispatch({actionType: Events.SETSORT,
        payload: {order: order.target.value, dataSetName: dataset}
      });
    }

    return (
        <PickMenu
          onChangeHandler={onSortFieldChange}
          optionArray={optionArray}
          selectedValue={Store.getDataset(dataset).sortValues.field}
          menuLabel={["Sort", (
            <select className="select" key={3} defaultValue={Store.getDataset(dataset).sortValues.order} onChange={onSortOrderChange}>
            <option value="asc" key={1}>↑</option>
            <option value="desc" key={2}>↓</option>
            </select>
          ),
          "by"]}
        />
    )
  }

  const renderedItems = () => headerItems?.map((item, index) => 
    (
      <ColumnEditorItem
        onRowChangeHandler={onRowChangeHandler}
        dataWasChanged={dataWasChanged}
        setDataWasChanged={setDataWasChanged}
        columnDown={sortColumnDown}
        columnUp={sortColumnUp}
        item={item}
        first={index===0}
        last={index===headerItems.length-1}
        index={index}
        key={index}
      />
    )
  );
  const renderItemsTitle = (
    <div className="column-editor-row title-row">
      <span className="sort">
        <i className="material-icons">keyboard_arrow_up</i>
        <i className="material-icons">keyboard_arrow_down</i>
      </span>
      <span className="id">id</span>
      <span className="title">title</span>
      <span className="display">display</span>
    </div>
  );


  return (
      <Container fluid className="main-content-container search_processor">
      <Row>
        <Col className="px-0">
          <Card small className="small-card">
            <CardHeader className="border-bottom table-title-column-editor">
              <i className="material-icons pointer" onClick={() => toggleColumnEditor(false)}>keyboard_return</i>
              <h3 className="m-0">Settings</h3>
              {renderPickMenu()}
              {renderSort()}
            </CardHeader>
            <CardBody className="tables-card-body p-0 pb-3" id="card-body">
                <div className="column-editor-block">
                  {renderItemsTitle}
                  {renderedItems()}
                </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      </Container>
  )
}

export default ColumnEditor;


const ColumnEditorItem = ({item, onRowChangeHandler, setDataWasChanged, dataWasChanged, columnUp, columnDown, first, last, index}) => {
  const eci = "edit-column-item";
  const ecdi = "edit-column-disabled-item";
  const cp = "cursor-pointer";
  const changeHandler = (e) => {
    let newItem = item;
    if ((e.target.tagName === "LABEL" || e.target.classList.contains("custom-control-input")) && !item.mandatory) {
      setDataWasChanged(true);
      newItem.hidden = !newItem.hidden;
    } else if (e.target.tagName === "INPUT") 
      newItem.colname = e.target.value;
    onRowChangeHandler(newItem);
  }
  
  return (
      <div className="column-editor-row">
        <span className="sort">
          {index>1 && <i className="material-icons button" onClick={()=>columnUp(item.id)}>keyboard_arrow_up</i>}
          {!last && ! first && <i className="material-icons button" onClick={()=>columnDown(item.id)}>keyboard_arrow_down</i>}
        </span>
        <span className="id">{item.id}</span>
        <input
          type={"text"}
          onClick={() => setDataWasChanged(true)}
          id={item.id}
          value={item.colname}
          onChange={changeHandler}
          className={!dataWasChanged ? `title ${eci} ${ecdi} ${cp}` : `title ${eci}`}
        />      
        <FormCheckbox
          className="display"
          disabled={item.mandatory}
          toggle
          checked={!item.hidden}
          onChange={changeHandler}
        />

      </div>
  )
};

const deepCopyFunction = (inObject) => {
  let outObject, value, key;
  if (typeof inObject !== "object" || inObject === null) {
    return inObject
  }
  outObject = Array.isArray(inObject) ? [] : {}

  for (key in inObject) {
    value = inObject[key]
    outObject[key] = deepCopyFunction(value)
  }
  return outObject
};
