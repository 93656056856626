import moment from "moment";
import cn from "classnames";
import { TODAY, TOMORROW, REQUEST_STATUSES } from "../../constants/request";
import { DISABLE, ENABLE } from "../../components/datasetTabs/tabsListData";

const DueDate = ({ request, status }) => {
  const duedate = moment(request.schedule.duedate);
  let dayDiff = moment()
    .startOf('day')
    .diff(duedate.startOf('day'), 'days')

  const isImmediately = (request.ext?.action === DISABLE || request.ext?.action === ENABLE) && request.priority === 100;

  let result = <span className={cn("table-dueDate-field immediately-cell", { immediately: isImmediately })}>
    {isImmediately ? <span>Immediately</span> : duedate.format('YYYY.MM.DD')}
  </span>;

  if (isImmediately) {
    return result;
  }

  switch (dayDiff) {
    case 0:
      result = TODAY
      break
    case -1:
      result = TOMORROW
      break
    default:
      if (!(duedate.isBefore(new Date(), 'day') && status !== REQUEST_STATUSES.COMPLETED)) {
        result = duedate.format('YYYY.MM.DD')
      }
      break;
  }

  return result
}

export default DueDate;
