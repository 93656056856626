import React from "react";
import DatasetTabs from "../components/datasetTabs/DatasetTabs";

const datasetTabs = (options) => {
  class DatasetTabsView extends React.Component {
    render() {
      return (
        <DatasetTabs
          {...options}
          />
      )
    }
  }

  return DatasetTabsView;
}

export default datasetTabs;
