import React from 'react'
import axios from 'axios';
import { Dispatcher, Events, Store } from "../../flux";
import DatasetTab from "./DatasetTab";
import Tables from "../../views/Tables";
import { tabsListData, REQUEST_FORMATED_KEYS, REQUEST_FORMATED_LABELS_FOR_DISABLE_ACCOUNTS } from "./tabsListData";
import { ALL } from './tabsListData';

import './datasetTabs.css'

class DatasetTabs extends React.Component {
  cancelTokenSource = null;

  constructor(props) {
    super(props);

    const { tabSetName } = props;
    const { datasetName, defaultDateFilters, defaultStatusFilters } = tabsListData[tabSetName];

    this.state = {
      activeDataset: datasetName,
      dateFilters: defaultDateFilters || [],
      statusFilters: defaultStatusFilters || [],
      hideDatasetTabs: false,
    }
  }

  componentDidMount() {
    if (this.state.activeDataset) {
      const dateFilters = this.state.dateFilters;
      const statusFilters = this.state.statusFilters;
      this.updateTableData({ statusFilters, dateFilters });
    }
  }

  setActiveItem = (item = {}) => {
    const { tabSetName } = this.props;
    const dateFilters = item.dateFilters || this.state.dateFilters
    const statusFilters = item.statusFilters || this.state.statusFilters
    const { datasetName } = tabsListData[tabSetName];

    const dateFiltersLength = dateFilters.length;
    const statusFiltersLength = statusFilters.length;

    this.setState({
      ...this.state,
      ...item,
      activeDataset: datasetName
    }, () => {
      if (statusFiltersLength || dateFiltersLength) {
        this.updateTableData(item);
        this.updateDataSetFilters({ datasetName, statusFilters, dateFilters });
      }
    });
  };

  updateDataSetFilters = ({ datasetName, statusFilters, dateFilters }) => {
    const dataSetFilters = {};
    if ((datasetName === 'requestsRisk' || datasetName === 'requestsCustom') && statusFilters.length) {
      dataSetFilters.conditions = statusFilters
    }
    else {
      if (statusFilters.length) {
        dataSetFilters.statusCode = statusFilters
      }
      if (dateFilters.length) {
        dataSetFilters.conditions = dateFilters
      }
    }
    Dispatcher.dispatch({
      actionType: Events.SETFILTER, payload: {
        datasetName,
        params: {
          ...dataSetFilters
        },
      }
    });
  }

  updateTableData = (item = {}) => {
    const { tabSetName } = this.props;
    const dateFilters = item.dateFilters || this.state.dateFilters
    const statusFilters = item.statusFilters || this.state.statusFilters
    const { datasetName, tabsList, type } = tabsListData[tabSetName];

    const dateFiltersLength = dateFilters?.length;
    const statusFiltersLength = statusFilters?.length;
    const correctRequestKeys = datasetName === 'requestsDisable' ? REQUEST_FORMATED_LABELS_FOR_DISABLE_ACCOUNTS : REQUEST_FORMATED_KEYS
    const formattedDateFilters = dateFiltersLength && dateFilters.map(i => correctRequestKeys[i] || i);
    const formattedStatusFilters = statusFiltersLength && statusFilters.map(i => correctRequestKeys[i] || i);
    const [dateFilterItem] = dateFilters;
    const [statusFilterItem] = statusFilters;

    let params = {
      type: type,
      conditions: []
    };

    if (statusFilterItem && dateFilterItem) {
      params = {
        ...params,
        statusCode: formattedStatusFilters,
      }
    }

    if (statusFiltersLength === tabsList?.statusFilters?.length) {
      params = {
        ...params,
        statusCode: null
      }
    }

    if (dateFilterItem === ALL || statusFilterItem === ALL) {
      params = {
        ...params,
        conditions: []
      }
    } else {
      params = {
        ...params,
        conditions: dateFilterItem ? [...formattedDateFilters] : [...formattedStatusFilters]
      }
    }

    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Previous request cancelled due to new request.');
    }

    this.initializeToken();
    Store.setActiveTableFilters(params);

    Dispatcher.dispatch({
      actionType: Events.RELOADTABLEDATA, payload: {
        datasetName,
        pv: {
          quantity: 25,
          startValue: 1,
        },
        params: {
          ...params,
          cancelTokenSource: this.cancelTokenSource,
        },
      }
    });
  };


  applyRefOnFirstRenderActiveTable = () => {
    this.initializeToken();
  }

  initializeToken = () => {
    this.cancelTokenSource = axios.CancelToken.source();
  }

  resetTabFilters = (action) => {
    if (action) {
      this.setState({ hideDatasetTabs: action === 'hide' })
    } else {
      this.updateTableData();
    }
  }

  render() {
    const { tabSetName } = this.props;
    const { activeDataset, dateFilters, statusFilters, hideDatasetTabs } = this.state;
    const currentTabListData = tabSetName && tabsListData[tabSetName].tabsList;

    return (
      <div className="dataset-tabs-wrapper">
        <div className={`dataset-tabs-content ${hideDatasetTabs ? 'hide' : ''}`}>
          {
            currentTabListData && Object.keys(currentTabListData).map((item) => (
              <DatasetTab
                key={item}
                listName={item}
                data={currentTabListData[item]}
                setActiveItem={this.setActiveItem}
              />
            ))
          }
        </div>

        {activeDataset &&
          <Tables
            dataset={activeDataset}
            callback={this.resetTabFilters}
            tabSetName={tabsListData[this.props.tabSetName]}
            statusFilters={this.state.statusFilters || []}
            headerTitle={`${[dateFilters, statusFilters].flat().join(' + ')}`}
            applyRefOnFirstRenderActiveTable={this.applyRefOnFirstRenderActiveTable}
            initializeToken={this.initializeToken}
            cancelTokenSource={this.cancelTokenSource}
          />
        }
      </div>
    );
  }
}

export default DatasetTabs;

