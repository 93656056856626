import React from 'react'
import traderapi from "../../traderapi/api";
import F from '../fields.js';
import TimeToResolution from "../../components/request/TimeToResolution";
import {
  REQUEST_FORMATTED_STATUSES,
  REQUEST_FORMATTED_CATEGORY
} from "../../constants/request";
import TraderId from "../../components/request/TraderId";
import WhosDoing from "../../components/request/WhosDoing";
import { tabsListData } from "../../components/datasetTabs/tabsListData";
import { utilityFunctions } from "../../utils/utilityFunctions";

class RequestCustomDataset {
  makeRecord(a) {
    const {
      _id,
      createdAt,
      updatedAt,
      applicationID,
      ext,
      status,
      userID,
      time,
      sequenceNumber,
      schedule,
      priority,
      // type,
      balance,
      subtype,
    } = a;
    const formattedStatus = status && REQUEST_FORMATTED_STATUSES[status];
    const formattedCategory = ext?.field && (REQUEST_FORMATTED_CATEGORY[ext?.field] || 'N/A');
    const requestApplied = (ext?.applied && utilityFunctions.currencyFormater().format(ext?.applied)) || 'Not applied yet';

    try {
      return {
        id: _id,
        schedule,
        priority,
        applicationID,
        sequenceNumber,
        status,
        time,
        createdAt,
        updatedAt,
        ext,
        assignee: userID,
        balance: balance,

        'Status': F.createField(formattedStatus, { readonly: true }),
        'Requested On': F.createField(utilityFunctions.tableTimeFormat(createdAt), { readonly: true }),
        'Updated On': F.createField(utilityFunctions.tableTimeFormat(updatedAt), { readonly: true }),
        'ID': F.createField(sequenceNumber, { readonly: true }),
        'Trader ID': {
          label: 'Trader ID',
          render: (rowItem, context) => <TraderId request={rowItem} context={context}/>
        },

        'Account Type': F.createField(applicationID?.accountType, { readonly: true }),
        'Platform': F.createField(applicationID?.software, { readonly: true }),
        'Category': F.createField(formattedCategory, { readonly: true }),
        'Current': F.createField(utilityFunctions.currencyFormater().format(ext?.oldValue), { readonly: true }),
        'Requested': F.createField(utilityFunctions.currencyFormater().format(ext?.value), { readonly: true }),
        'Applied': F.createField(requestApplied, { readonly: true }),
        'Type': F.createField(subtype || 'N/A', { readonly: true }),
        'Balance': F.createField(utilityFunctions.currencyFormater().format(balance), { readonly: true }),
        'Full name': F.createField(userID?.name, { readonly: true }),
        'Subject': F.createField(ext?.subject || '', { readonly: true }),

        'Who\'s Doing': {
          align: 'center',
          label: 'Who\'s Doing',
          render: (rowItem, context) => <WhosDoing request={rowItem} context={context}/>
        },
        'Time to resolution': {
          label: 'Time to resolution',
          readonly: true,
          render: (rowItem) => <TimeToResolution request={rowItem} />
        },
      }

    } catch (error) {
      console.log(error)
    }
  }

  list(params, cb) {
    traderapi.getRequests((data) => {
      const items = data.items.map(this.makeRecord);
      const tabsListKeys = Object.keys(tabsListData);
      let defaultHeaders;
      let header;

        tabsListKeys.forEach(k => {
          if (tabsListData[k].type === params.type) {
            defaultHeaders = tabsListData[k].defaultHeader
          }
        })

        if (defaultHeaders) {
          header = [...defaultHeaders.map(h => {
            return {
              id: h,
              colname: h,
              hidden: false
            }
          })];
        }

      cb({
        items,
        data,
        header
      });

    }, params);
  }
  //
  get(id, cb) {
    this.list({id}, cb)
  }
}

export default RequestCustomDataset;
