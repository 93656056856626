import React from "react";
import stopImg from "../../images/marketdata/stop.svg"
import traderapi from "../../traderapi/api";
import {REQUEST_STATUSES} from "../../constants/request";
import {pushToast} from "../toaster/helper";
import { TOAST_TYPE_WARNING } from "../toaster/types";

const StopProcess = ({request, context}) => {

  const stopProcess = async (e) => {
    e.stopPropagation();

    await traderapi.setRequestStatus(request.id, REQUEST_STATUSES.STOP)
    context.refreshDataset('softRefresh');
    pushToast(`Request for ${request.applicationID.reference.client} was not done`, TOAST_TYPE_WARNING)
  }

  return (
    <div className="d-flex justify-content-center">
      <img
        onClick={stopProcess}
        src={stopImg}
        alt="Start"
      />
    </div>
  )
}

export default StopProcess;
