import {Dispatcher, Events} from "../../flux";

const pushToast = (message, type, timeout) => {
  Dispatcher.dispatch({
    actionType: Events.PUSH_TOAST,
    payload: {
      message,
      type,
      timeout,
    }
  });
}

export {pushToast}
