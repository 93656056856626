const createField = (value, options) => {
  return Object.assign({
    isValue: true,
    value
  }, options);
};

const createFieldReference = (reference, id, options) => {
    return Object.assign({
      isReference: true,
      reference,
      id
    }, options);
  };

  const createFieldEnum = (value, values, options) => {
    return Object.assign({
      isEnum: true,
      value,
      values
    }, options);
  };

const createColumnInCSV = (value, options) => {
  return Object.assign({
    value,
  }, options)
}

  const createFieldList = ( list, options) => {
    return Object.assign({
      isList: true,
      list,
      readonly: true
    }, options);
  };

  const createFieldDate = (value, options) => {
    return Object.assign({
      isDate: true,
      value: value
    }, options);
  };

  const createFieldDateTime = (value, options) => {
    return Object.assign({
      isDateTime: true,
      value: value,
      timezone: "America/New_York"
    }, options);
  };

  const createFieldBoolean = (value, options) => {
    return Object.assign({
      isBoolean: true,
      value
    }, options);
  };

  const createSeparator = (label) => {
    return {
      isSeparator: true,
      label,
      readonly: true
    }
  };

  const createFieldImage = (name, getter) => {
    return {
      isImage: true,
      name,
      getter
    }
  };

  const createFieldImages = (images) => {
    return {
      isImages: true,
      images
    }
  };

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    createField,
    createFieldBoolean,
    createFieldDate,
    createColumnInCSV,
    createFieldDateTime,
    createFieldEnum,
    createFieldImage,
    createFieldImages,
    createFieldList,
    createFieldReference,
    createSeparator,
};
