import traderapi from "../../traderapi/api";
import F from '../fields.js';
import constants from "../constants";
import {Button} from "shards-react";

class WireDataset {

    makeRecord(a) {
        const { applicationID } = a;
        try {
            return {
                'Trader Id': F.createField(applicationID?.reference?.client, {readonly: true}),
                'Account Name': F.createField(applicationID?.personal?.firstName ? `${applicationID?.personal?.firstName} ${applicationID?.personal?.lastName}` : '', {readonly: true}),
                'Request Type': F.createField(a.type, {readonly: true}),
                'Requested On': F.createFieldDateTime(a.requestedOn, {readonly: true}),
                'Application Approval': F.createFieldDateTime(applicationID?.statusUpdatedAt, {readonly: true}),
                'Amount Claimed': F.createField(a.amount?.claimed, {readonly: true, changeFormat: true}),
                'Amount Received': F.createField(a.amount?.received, {readonly: true, changeFormat: true}),
                'Amount Pending': F.createField(a.amount?.pending, {readonly: true, changeFormat: true}),
                'Received On': F.createFieldDateTime(a.received?.date, {readonly: true}),
                'Claimed Via': F.createField(a?.claim?.source, {readonly: true}),
                'Claimed On': F.createFieldDateTime(a?.claim?.date, {readonly: true}),
                status: F.createFieldEnum(a.status, constants.ApplicationStatus, {readonly: true}),
                wireId: F.createField(a._id, {readonly: true}),
                applicationId: F.createField(applicationID?._id, {readonly: true}),
                claim: a?.claim,
                Confirmation: {
                  label: 'Confirmation',
                  value: '',
                  render: (rowItem, context) => {
                    if(!rowItem.claim?.confirmation){
                      return <>{""}</>
                    }
                    return (
                      <div className="text-center">
                        <Button
                          theme="info"
                          onClick={async (event) => {
                            event.stopPropagation()
                            context.setState({isImageModalVisible: true})
                            context.setState({modalImage: null})
                            const img = await traderapi.getConfirmation(rowItem.wireId.value)
                            context.setState({modalImage: img})
                          }}
                        >
                          View
                        </Button>
                      </div>
                    )
                  }
                },
            }

        } catch (error) {
        }
    }
    //
    list(params, cb) {
        traderapi.getClaimed((data) => {
            const items = data.items.map(this.makeRecord);

            cb({
                items,
                data
            });

        }, params);
    }
}

export default WireDataset;
