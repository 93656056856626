import React from "react";
import {Redirect, Route} from "react-router-dom";

// Layout Types
import {AuthLayout, DefaultLayout} from "./layouts";

// Route Views
import AccountioOverview from "./views/AccountioOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewApplication from "./views/AddNewApplication";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import BlogPosts from "./views/BlogPosts";
import Login from "./components/auth/Login";
import tableData from "./views/TableData";
import datasetTabs from "./views/DatasetTabs";
import traderapi from "./traderapi/api";
import { Dispatcher, Events } from "./flux";
import DuplicateReport from "./views/DuplicateReport";

export const layouts = [
  {
    component: DefaultLayout,
    exact: true,
    routes: [
      {
        path: "/",
        component: () => <Redirect to="/d" />
      },
    ]
  },
  {
    component: DefaultLayout,
    routes: [
      {
        path: "/d",
        component: AccountioOverview,
        options: { dataset: "stats"}
      },
      {
        path: "/report/dn",
        component: DuplicateReport,
        options: { dataset: "reportduplicatename", title: "Duplicate name"}
      },
      {
        path: "/report/dg",
        component: DuplicateReport,
        options: { dataset: "reportduplicategovid", title: "Duplicated Government ID"}
      },
      {
        path: "/report/de",
        component: DuplicateReport,
        options: { dataset: "reportduplicateemail", title: "Duplicate email",}
      },
      {
        path: "/report/ed",
        component: DuplicateReport,
        options: { dataset: "reportexpireddocument", title: "Expiring ID *30d",}
      },
      {
        path: "/report/aa",
        component: tableData({dataset:"reportanalyticsall"})
      },
      {
        path: "/report/stats",
        component: tableData({dataset:"reportusagestatistics"})
      },
      {
        path: "/report/tradeProAML",
        component: tableData({dataset:"reportTradeProAML"})
      },
      {
        path: "/report/newAccountsSterling",
        component: tableData({dataset:"reportSterlingTrader"})
      },
      {
        path: "/pb/pending",
        component: tableData({dataset:"pending"})
      },
      {
        path: "/pb/claimed",
        component: tableData({dataset:"claimed"})
      },
      {
        path: "/pb/received",
        component: tableData({dataset:"received"})
      },

      {
        path: "/request/today",
        component: datasetTabs({ tabSetName: 'marketDataSet' })
      },
      {
        path: "/request/enable-today",
        component: datasetTabs({ tabSetName: 'accountsToEnableSet' })
      },
      {
        path: "/request/disable",
        component: datasetTabs({ tabSetName: 'accountsToDisableSet' })
      },
      {
        path: "/request/risk",
        component: datasetTabs({ tabSetName: 'requestRisk' })
      },
      {
        path: "/request/custom",
        component: datasetTabs({ tabSetName: 'requestCustom' })
      },
      {
        path: "/na/sterlingCreate",
        component: tableData({dataset:"report/Sterling/create"})
      },
      {
        path: "/na/sterlingRequested",
        component: tableData({dataset:"report/Sterling/requested"})
      },
      {
        path: "/na/DASCreate",
        component: tableData({dataset:"report/DAS/create"})
      },
      {
        path: "/na/DASRequested",
        component: tableData({dataset:"report/DAS/requested"})
      },
      {
        path: "/na/tradeZeroCreate",
        component: tableData({dataset:"report/TradeZero/create"})
      },
      {
        path: "/na/tradeZeroRequested",
        component: tableData({dataset:"report/TradeZero/requested"})
      },
      // {
      //   path: "/na/takionCreate",
      //   component: tableData({dataset:"report/Takion/create"})
      // },
      {
        path: "/user-profile-lite",
        component: UserProfileLite
      },
      {
        path: "/add-new-application",
        component: AddNewApplication
      },
      {
        path: "/errors",
        component: Errors
      },
      {
        path: "/a",
        component:  tableData({dataset:"a"})
      },
      {
        path: "/v/ops",
        component:  tableData({dataset:"v/ops"})
      },
      {
        path: "/v/trader",
        component:  tableData({dataset:"v/trader"})
      },
      {
        path: "/groups",
        component:  tableData({dataset:"groups"})
      },
      {
        path: "/users",
        component:  tableData({dataset:"users"})
      },
      {
        path: "/presets",
        component:  tableData({dataset:"presets"})
      },
      {
        path: "/activations",
        component:  tableData({dataset:"activations"})
      },
      {
        path: "/calculationrates",
        component:  tableData({dataset:"calculationrates"})
      },
      {
        path: "/countryrates",
        component:  tableData({dataset:"countryrates"})
      },
      {
        path: "/documenttemplates",
        component:  tableData({dataset:"documenttemplates"})
      },
      {
        path: "/marketdatabundles",
        component:  tableData({dataset:"marketdatabundles"})
      },
      {
        path: "/marketdataproviders",
        component:  tableData({dataset:"marketdataproviders"})
      },
      {
        path: "/r",
        component:  tableData({dataset:"r"})
      },
      {
        path: "/tradingplatforms",
        component:  tableData({dataset:"tradingplatforms"})
      },
      {
        path: "/countries",
        component:  tableData({dataset:"countries"})
      },
      {
        path: "/blog-posts",
        component: BlogPosts,
      },
      {
        path: "/components-overview",
        component: ComponentsOverview,
      },
    ]
  },
  {
    component: AuthLayout,
    unPrivate: true,
    routes: [
      {
        path: "/login",
        component: Login,
      },
    ]
  }
]

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => {
    if(traderapi.getToken()?.length > 0) {
      if (window.location.pathname === "/") {
        return <Redirect to="/d" />
      }
      return <Component {...props} />
    } else {
      Dispatcher.dispatch({actionType: Events.SET_FIRST_REDIRECTED_URL, payload: window.location.pathname});
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }
  }} />
)
