import {REQUEST_STATUSES, NO_ONE} from "../../constants/request";
import {Store} from "../../flux";
import Complete from "./Complete";
import StopProcess from "./StopProcess";
import StartProcess from "./StartProcess";


const WhosDoing = ({request, context}) => {
  const isSameUser = request.assignee?.username === Store.getLoggedUser().username;

  if (request.status === REQUEST_STATUSES.INPROGRESS && isSameUser) {
    return (
      <div className="d-flex justify-content-center request-process">
        <Complete request={request} type='resolve' context={context} />
        &nbsp;
        <StopProcess request={request} context={context} />
      </div> );
  }

  if (request.status === REQUEST_STATUSES.ONHOLD && isSameUser) {
    return (
      <>
        <div className="justify-content-center d-none request-start">
          <StartProcess
            request={request}
            context={context}
          />
        </div>
        <div className="justify-content-center d-flex no-assignee">{request.assignee?.name}</div>
      </>
    )
  }

  if ( [REQUEST_STATUSES.OPEN, REQUEST_STATUSES.NEW_MESSAGE].includes(request.status)) {
    return (
      <>
        <div className="justify-content-center d-none request-start">
          <StartProcess
            request={request}
            context={context}
          />
        </div>
        <div className="justify-content-center d-flex no-assignee">{NO_ONE}</div>
      </>
    )
  }

  if (request.status === REQUEST_STATUSES.OPEN) {
    return (
      <div className="justify-content-center d-flex">
        {NO_ONE}
      </div>
    )
  }

  return <div className="d-flex justify-content-center">
    {request.assignee?.name}
  </div>
}

export default WhosDoing;
