import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";

import { Dispatcher, Events } from "../../../flux";
import logoimage from "../../../images/accountio_logo.png";
import './MainSidebar.css'

const SidebarMainNavbar = ({hideLogoText, formIsEditting}) => {

  const handleToggleSidebar = () => {
    Dispatcher.dispatch({
      actionType: Events.TOGGLE_SIDEBAR
    });
  }

  const onLogoClick = (e) => {
    if (formIsEditting)
      Dispatcher.dispatch({actionType: Events.SET_REDIRECTED_URL, payload: '/d'});
      Dispatcher.dispatch({actionType: Events.TOGGLE_DIALOG_WINDOW, payload: true});
  }

  return (
    <div className="main-navbar" onClick={onLogoClick}>
      <Navbar
        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
        type="light"
      >
        <NavbarBrand
          className="w-100 mr-0"
          href={formIsEditting ? undefined :`${process.env.REACT_APP_BASENAME||""}/d`}
          style={{ lineHeight: "25px" }}
        >
          <div className="d-flex m-auto main-logo-block">
            <img
              id="main-logo"
              className="d-inline-block align-top main-image-logo"
              src={logoimage}
              alt="Accounti⚙ Dashboard"
            />
            {!hideLogoText && (
              <span className="d-none d-md-inline ml-1 main-logo-title">
              Accounti⚙ Dashboard
              </span>
            )}
          </div>
        </NavbarBrand>
        {/* eslint-disable-next-line */}
        <a
          className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
          onClick={handleToggleSidebar}
        >
          <i className="material-icons">&#xE5C4;</i>
        </a>
      </Navbar>
    </div>
  );
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

export default SidebarMainNavbar;
