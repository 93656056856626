import React from "react";

import constants from "../types.js";

import "./chat-popup.css";

const ChatPopup = ({ popupState, onPopupClickHandler }) => {
  const { popupTypes } = constants;
  const renderPopupButtons = () => {
    switch (popupState.type) {
      case popupTypes.RESOLVE_REQUEST:
      case popupTypes.CANCEL_REQUEST:
      case popupTypes.REMOVE_ATTACH:
      case popupTypes.NO_RESPONSE:
        return (
          <div className="chat-popup-buttons">
            <button
              className={`${popupState.type === popupTypes.CANCEL_REQUEST ? "yes-remove-button" : "yes-button"}`}
              onClick={() => onPopupClickHandler(popupState.type)}
            >
              Yes
            </button>
            <button
              className="no-button"
              onClick={() => onPopupClickHandler(popupTypes.NO_RESPONSE)}
            >
              No
            </button>
          </div>
        );
      case popupTypes.INFORMATIONAL:
        return (
          <div className="chat-popup-buttons">
            <button
              className="yes-button"
              onClick={() => onPopupClickHandler(popupTypes.INFORMATIONAL)}
            >
              Okay
            </button>
          </div>
        );
      default:
        break;
    }
  };

  if (popupState.show)
    return (
      <div className="chat-popup-wrapper">
        <div className="popup-black-area" />
        <div className="chat-popup-area">
          {!!popupState?.title?.length && (
            <div className="chat-popup-title">{popupState.title}</div>
          )}
          <div className="popup-message">{popupState.message}</div>
          {renderPopupButtons()}
        </div>
      </div>
    );
  return null;
};

export default ChatPopup;
