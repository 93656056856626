import traderapi from "../../traderapi/api";
import F from '../fields.js';

class NewAccountsTradeZero {
    makeRecord(a, headers) {
        const res = {
            select: F.createFieldBoolean(false,{select: ['',' ADD '], inplaceEditor: true, selectCounter: true})
        };
        headers.forEach((h) => {
            res[h] = a[h];
        });
        return res;
    }

    list(params, cb) {
        traderapi.getNewAccountsTradeZero((data) => {
            const items = data?.report?.data.map((a) => {
                return this.makeRecord(a, data?.report?.headers)
            });

            const header = [{ id: 'select', colname: 'select', checkbox: true }, ...data?.report?.headers.map((h)=>{
                return {
                    id: h,
                    colname: h,
                    hidden: h === 'id'
                }
            })];
            cb({
                items,
                data,
                header
            });
        }, params);
    }
}

export default NewAccountsTradeZero;
