import React from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Button,
  Badge
} from "shards-react";
import { withRouter } from "react-router";
import { Dispatcher, Events, Store } from "../../../flux";
import "../../../assets/searchbar.css";


class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      searchResult: 0,
      searchLabel: "",
      searchDataset:""
    };

    this.onChange = this.onChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.onSearchDone = this.onSearchDone.bind(this);
    this.onChangeDataset = this.onChangeDataset.bind(this);
    this.setSearchValue = this.setSearchValue.bind(this);
  }

  componentDidMount() {
    Store.addSearchDoneListener(this.onSearchDone);
    Store.resetSetSearchDatasetListener(this.onChangeDataset, true);
  }

  componentWillUnmount() {
    Store.removeSearchDoneListener(this.onSearchDone);
    Store.resetSetSearchDatasetListener(this.onChangeDataset, false);
  }

  onChangeDataset(searchDataset) {
    const search = Store.getSearchPattern();

    this.setState({
      search,
      searchLabel: search,
      searchDataset: searchDataset
    });

  }

  onSearchDone() {
    this.setState({
      ...this.state,
      searchResult: Store.getSearchRecordsNumber()
    });
  }

  setSearchValue(search) {
    // Dispatcher.dispatch({
    //   actionType: Events.SETSORT,
    //   payload: {dataSetName: this.props.dataset, search}
    // });

    this.setState({searchLabel: search,}, () => {
      Dispatcher.dispatch({
        actionType: Events.NEWSEARCH,
        payload: search
      });
      Dispatcher.dispatch({
        actionType: Events.RELOADDATA,
        payload: this.state.searchDataset
      });
    });
  }

  setSearch(search, request) {
    this.setState({search});

    if (search === '' || request) {
      this.setSearchValue(search);
    }
  }

  onChange({ target }) {
    this.setSearch(target.value);
  };

  onKeyDown = ({ keyCode }) => {
    const { search } = this.state;

    if (keyCode === 13) {
      this.setSearch(search, true);
    }
  };

  clearSearch() {
    this.setSearch("");
  };

  render() {
    const {search, searchResult, searchLabel} = this.state;

    const searchResultClass = (searchResult>0)?((!!search)?'search_found':''):'search_empty';

    return (
    <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex"
      onSubmit={(event)=>{ event.preventDefault();}}
    >
      { !!searchLabel &&
        <div className="navbar-search-label">
          <Badge theme="secondary">{searchLabel}</Badge>
        </div>
      }
    <InputGroup seamless className="ml-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">search</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput
        className={`navbar-search ${searchResultClass}`}
        placeholder={Store.getActiveDataset()?.searchPlaceholder || "Search ..."}
        value={search}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        tabIndex="0"
      />
      <InputGroupAddon type="append">
        <Button className="search_cross" theme="outline" onClick={this.clearSearch}><i className="material-icons">close</i></Button>
      </InputGroupAddon>
    </InputGroup>
    </Form>
    )
  };
}

export default withRouter(SearchBar);
