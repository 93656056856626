import { Dispatcher, Events } from "../../flux";
import { useHistory, useLocation } from "react-router-dom";

const SidebarBadge = ({ to = '/', children, onClick, color, datasetStates: { datasetName, params } = {} }) => {
  let history = useHistory();
  let location = useLocation();

  const clickHandler = async (event) => {
    onClick && onClick(event)
    if (datasetName && params) {
      event.preventDefault();
      if (location.pathname !== to) {
        await history.push({ pathname: to });
      }
      await Dispatcher.dispatch({ actionType: Events.SETACTIVEDATASETTAB, payload: { datasetName, params } });
      await Dispatcher.dispatch({ actionType: Events.RELOADTABLEDATA, payload: { datasetName, params } });
    }
  }
  return (children ?
    <div className="sidebar-badge ml-1"
      style={color && { backgroundColor: color }}
      onClick={clickHandler}>{children}</div> : null);
};

export default SidebarBadge;
