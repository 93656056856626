import traderapi from "../../traderapi/api";
import F from '../fields.js';
import constants from "../constants";
import {Store} from "../../flux";

const crmpath = () => {
    return Store.getLoggedUser().configuration.crmurl;
}
const sumsubPath = (id) => {
    return `https://cockpit.sumsub.com/checkus/#/applicant/${id}/client/basicInfo?clientId=zimtra_asset_management_sezc`
}
class ApplicationDataset {

    makeRecord(a) {
      const sortExample = ["photoID", "photoIDback", "identity", "address"];
      const createFiledForSumSub = () => {
        if (!!a.sumsubID) {
            return F.createFieldReference(sumsubPath, a.sumsubID, {readonly: true, external: true, withIDProp: true})
        }
        return F.createField("N/A", {readonly: true})
      }

      try {
          return {
              ABOUTYOU: F.createSeparator('About You'),
              // personal
              title: F.createFieldEnum(a.personal?.title, constants.TitleList),
              firstName: a.personal?.firstName,
              middleName: a.personal?.middleName,
              lastName: a.personal?.lastName,
              dateofbirth: F.createFieldDate(a.dateofbirth),
              citizenship: a.personal?.citizenship,
              pep: (a.pep&&a.pep.pep)?a.pep.details:'',
  
              CONTACTINFO: F.createSeparator('Contact Information'),
              phone: a.phone,
              street: a.address?.street,
              street2: a.address?.street2,
              city: a.address?.city,
              postalCode: a.address?.postalCode,
              country: a.address?.country,
  
              GOVERNAMENT: F.createSeparator('ID Verification'),
              // document
              idtype: F.createFieldEnum(a.document?.idtype, constants.IDTypeList),
              idnumber: a.document?.idnumber,
              idexpiration: F.createFieldDate(a.document?.idexpiration),
              hasExpirationDate: F.createFieldBoolean(!!a.document?.idexpiration, {select: ['No', 'Yes'], readonly:true}),
              scans: a.document?.scanFiles && F.createFieldImages(Object.entries(a.document?.scanFiles).map((s)=>(
                F.createFieldImage( `${s[0]}:${s[1].filename}`, (cb)=>(traderapi.getScan(cb, a._id, s[1].scanID)))
              )).sort((a,b) => {
                  return sortExample.indexOf(a.name.split(":")[0]) - sortExample.indexOf(b.name.split(":")[0])
                })),
  
              
              // agreement
              AGREEMENT: F.createSeparator('Schedule A'),
              accountType: a.accountType,
              tradingPlatform: F.createFieldEnum(a.software, constants.SoftwareList, {readonly: true}),
              tradingPlatformType: F.createFieldEnum(a.softwareType, constants.SoftwareTypeList, {readonly: true}),
              performanceBond: F.createField(a.agreement?.rules?.performanceBond, {changeFormat: true}),
              initialBuyingPower: F.createField(a.agreement?.rules?.initialBuyingPower, {changeFormat: true}),
              approvedOvernightBuyingPower: F.createField(a.agreement?.rules?.approvedOvernightBuyingPower, {changeFormat: true}),
              maximumDailyLoss: F.createField(a.agreement?.rules?.maximumDailyLoss, {changeFormat: true}),
              maximumTraderBalanceDrawDown: "50%",
  
              ACCOUNTINFO: F.createSeparator('Account info'),
              // traderType: F.createFieldEnum(a.traderType, constants.TraderTypeList, {readonly: true}), // => this field is not used anywhere in onboardingform2 project neither in dashboard project, probably this field was used in older versions of onboarding proj
              status: F.createFieldEnum( a.status, constants.ApplicationStatus ),
              tradingExperience: F.createFieldEnum( a.tradingExperience, constants.TradingExperienceList),
  
              // enablements
              entitlements: F.createFieldList(a.agreement?.enablements?.map((e)=>(`${e.kind} : ${e.name} : ${e.value}`))||[], {old:true}),
  
              // signs
              signs: F.createFieldList(a.signs?.map((s)=>(`${s.signDate} : ${s.signID} : ${s.signVersion}`))||[]),
  
  
  
              SYSTEM: F.createSeparator('System'),
              // reference
              id: F.createFieldReference( "a", a._id, {readonly: true}),
              email: F.createField(a.personal?.email, {readonly: true}),
              sumsubID: createFiledForSumSub(),
              referenceClient: F.createField(a.reference?.client, {readonly: true}),
              referenceCRM: F.createFieldReference( crmpath, a.reference?.crm?.id, {readonly: true, external: true}),
              statusNotes: F.createField(a.statusNotes, {readonly: true}),
              statusUpdatedAt: F.createFieldDateTime(a.statusUpdatedAt, {readonly: true}),
              user: F.createFieldReference("users", a.userID, {readonly: true}),
  
              // changelog
              changelog: F.createFieldList(a.changelog?.map((c)=> (`${c.when}: ${c.event}`))||null ,{lines: 7}),
  
              createdAt: F.createFieldDateTime(a.createdAt, {readonly: true}),
              updatedAt: F.createFieldDateTime(a.updatedAt, {readonly: true}),
              review: F.createColumnInCSV(a.review, {noDownload: true}),
  
          }
        
      } catch (error) {
      }
    }
    //
    list(params, cb) {
        traderapi.getApplications((data) => {
            const items = data.items.map(this.makeRecord);
            cb({
                items,
                data
            });

        }, params);
    }
    //
    get(id, cb) {
        this.list({clientID: id}, cb)
    }
    //
    save(n, o, cb) {
        const a = {
            clientID: n.id.id
        };

        const s = {
            clientID: n.id.id
        };
        var hasApplicationUpdate = false;
        for (const [key, value] of Object.entries(n)) {
            const oo = o[key];
            var nv;
            var ov;
            if(typeof value === 'object') {
                nv = value?.value;
                ov = oo?.value;
            } else {
                nv = value;
                ov = oo;
            }
            if(nv!==ov) {
                console.log(`CHANGE: ${key} new: ${nv} old: ${ov}`);
                var changing = true;
                switch(key) {
                    case 'firstName':
                    case 'lastName':
                    case 'middleName':
                    case 'email':
                    case 'title':
                    case 'citizenship':
                        a.personal = Object.assign(a.personal || {}, {[key]: nv});
                        break;
                    //
                    case 'street':
                    case 'city':
                    case 'state':
                    case 'postalCode':
                    case 'country':
                        a.address = Object.assign(a.address || {}, {[key]: nv});
                        break;
                    //
                    case 'dateofbirth':
                    case 'phone':
                    case 'tradingExperience':
                    case 'software':
                    case 'softwareType':
                    case 'traderType':
                        a[key] = nv;
                        break;
                    //
                    case 'pep':
                        a.pep = { pep: !!nv, details: nv }
                        break;
                    //
                    case 'idtype':
                    case 'idnumber':
                    case 'idexpiration':
                        a.document = Object.assign(a.document || {}, {[key]: nv});
                        break;
                    //
                    case 'performanceBond':
                    case 'initialBuyingPower':
                    case 'approvedOvernightBuyingPower':
                    case 'maximumDailyLoss':
                        a.agreement = a.agreement || {};
                        const { performanceBond, initialBuyingPower, approvedOvernightBuyingPower, maximumDailyLoss } = n;
                        a.agreement.rules = { performanceBond, initialBuyingPower, approvedOvernightBuyingPower, maximumDailyLoss };
                        break;
                    //
                    case 'referenceClient':
                        a.reference = { client: nv };
                        break;

                    case 'status':
                        s.newstatus = nv;
                        changing = false;
                        break;
                    case 'notes':
                        s.notes = nv;
                        changing = false;
                        break;
                    default:
                        changing = false;
                }
                hasApplicationUpdate ||= changing;
            }
        }

        const statusUpdate = (s) => {
            traderapi.statusApplication((data)=>{
                if(data.success) {
                    cb(data,n,o)
                } else {
                    console.error(`Updating application status failed`, data);
                    alert(data.message);
                }
            }, s);
        };

        if(hasApplicationUpdate) {
            traderapi.saveApplication((data) => {
                if(data.success) {
                    if(s.newstatus) {
                        statusUpdate(s,cb);
                    } else {
                        cb(data, n, o);
                   }
                } else {
                    console.error(`Updating applicaiton failed`, data);
                    alert(data.message);
                }
            }, a);
        } else {
            if(s.newstatus) {
                statusUpdate(s,cb);
            } else {
                alert("No changes to save application");
            }
        }
    }
}

export default ApplicationDataset;
