import React from "react";
import "./DialogPopup.css";

const DialogPopup = ({
  openedDialog,
  popupData,
  onCancelButtonClick,
  onSaveButtonClick,
  onStayButtonClick,
  onFormRefreshClickHandler,
  clickedRefreshForm,
  children,
}) => {
  const renderButtons = () => {
    if (
      popupData.type === "require" ||
      popupData.type === "approve" ||
      popupData.type === "reject" ||
      popupData.type === "clear" 
    ) {
      const setHeader = () => {
        if (popupData.type === "require") return "Require Information?";
        if (popupData.type === "approve") return "Approve Application?";
        if (popupData.type === "reject") return "Reject Application?";
        if (popupData.type === "clear") return "Clear Comments?";
      }
      return (
        <div className="dialog-popup">
          <div className="button-block">
            <header>{setHeader()}</header>
            <div className="popup-button-text">
              {popupData.text}
            </div>
            <div className="popup-buttons-in-block">
              <button onClick={() => popupData.cb(true)}>Yes, {popupData.type}</button>
              <button onClick={() => onStayButtonClick()}>Cancel</button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="dialog-popup">
        <div className="button-block">
          <header>Save changes ?</header>
          <div className="popup-button-text">
            You are leaving this page. Do you want to Save your changes ?
          </div>
          <div className="popup-buttons-in-block">
            <button onClick={(e) => onSaveButtonClick(e, true)}>Save</button>
            <button onClick={(e) => onCancelButtonClick(e, true)}>Cancel</button>
            <button onClick={() => onStayButtonClick()}>Stay</button>
          </div>
        </div>
      </div>
    );
  };
  if (openedDialog) {
    return (
      <>
        <div>{children}</div>
        <div className="opened-dialog-block">
          <div className="dialog-block-background" />
          {!clickedRefreshForm ? (
            renderButtons()
          ) : (
            <div className="dialog-popup">
              <span>Are you sure you want to refresh the data ?</span>
              <div className="button-block">
                <button onClick={() => onFormRefreshClickHandler()}>
                  Refresh
                </button>
                <button onClick={() => onStayButtonClick()}>Cancel</button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  return <div>{children}</div>;
};

export default DialogPopup;
