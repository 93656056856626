import traderapi from "../../traderapi/api";
import F from '../fields.js';

class UserDataset {

    makeRecord(u) {
        return {
        GENERAL: F.createSeparator('General'),
        id: F.createFieldReference("users", u.id),
        username: u.username,
        name: u.name,
        email: u.email,

        admin: F.createFieldBoolean(u.admin),
        active: F.createFieldBoolean(u.active),

        webhook: u.webhook.url,
        count: u.webhook.attemptCount,
        delay: u.webhook.attemptDelay,
        error: u.webhook.error,

        SYSTEM: F.createSeparator('System'),
        groupAdmin: F.createFieldBoolean(u.acl?.agencyAdmin),
        systemAdmin: F.createFieldBoolean(u.acl?.systemAdmin),

        groups: F.createFieldReference("groups", u.agencyID),
        }
    }
    //
    list(params, cb) {
        traderapi.getUsers((data) => {
            const items = data.map(this.makeRecord);
            cb({
                items,
                data
            });
            
        }, params);
    }
    //
    get(id, cb) {
        this.list({id}, cb)
    }
    //
    save(item, cb) {
        traderapi.saveUser((data) => {
          cb(data);
     }, item);
    }
}

export default UserDataset;