import React, {useContext} from "react";

import {ModalContext} from "./ModalContext";
import {Modal as ShardsModal} from "shards-react";

import "../table-modal/TableModal.css"
import "./modal.css"

const Modal = ({children}) => {
  const {hideModal, modalSize} = useContext(ModalContext);
  return (
    <ShardsModal size={modalSize} open={true} toggle={hideModal}>
      {children}
    </ShardsModal>
  )
};

export default Modal;
