import React, { useRef } from "react";
import constants from "../types";

import "./chat-upload-button.css";

const ChatUploadButton = ({
  show = true,
  setPopupState,
  onAttachmentChange,
  attachments,
  actionType = constants.uploadTypes.IMAGE,
}) => {

  const { popupTypes, uploadTypes } = constants;
  const maxSizeImage = 5242880;
  const maxSizeFile = 52428800;
  const inputRef = useRef(null);
  const handleUpload = () => {
    inputRef.current?.click();
  };

  const backAnswer = (max, size) => {
    setPopupState({
      show: true,
      title: "Informational message",
      message: max
        ? `Your file exceeded max size of ${size} mb`
        : "Wrong file type",
      type: popupTypes.INFORMATIONAL,
    });
  };
  const handleDisplayFileDetails = (e) => {
    if (inputRef.current?.files?.length === 1) {
      if (inputRef.current?.files?.length + attachments?.length > 5) {
        return setPopupState({
          show: true,
          title: "Informational message",
          message: "max 5 attachments for one message",
          type: popupTypes.INFORMATIONAL,
        });
      }
      if (actionType === uploadTypes.IMAGE && inputRef?.current?.files[0]?.size > maxSizeImage) {
        return backAnswer(true, maxSizeImage / 1024 / 1024);
      }
      if (actionType === uploadTypes.ATTACHMENT && inputRef?.current?.files[0]?.size > maxSizeFile) {
        return backAnswer(true, maxSizeFile / 1024 / 1024);
      }
      onAttachmentChange(inputRef?.current?.files[0], true);
    }
    if (inputRef.current?.files?.length > 1) {
      if (inputRef.current?.files?.length + (attachments?.length || 0) > 5) {
        return setPopupState({
          show: true,
          title: "Informational message",
          message: "max 5 attachments for one message",
          type: popupTypes.INFORMATIONAL,
        });
      }
      const filesToUpload = []
      Object.values(inputRef?.current?.files).forEach((file) => {
        if (actionType === uploadTypes.IMAGE && file.size > maxSizeImage) {
          return backAnswer(true, maxSizeImage / 1024 / 1024);
        }
        if (actionType === uploadTypes.ATTACHMENT && file.size > maxSizeFile) {
          return backAnswer(true, maxSizeFile / 1024 / 1024);
        }
        filesToUpload.push(file)
      });
      onAttachmentChange(filesToUpload, true);
    }
  };
  return (
    <div className="chat-upload-button-block">
      <input
        ref={inputRef}
        onClick={(e) => {
          e.target.value = "";
        }}
        onChange={handleDisplayFileDetails}
        accept="image/png, image/jpeg, image/jpg, image/gif, image/bmp,
        video/mp4, video/mov, video/wmv, video/avi, video/flv, .pdf, .csv, .txt, .xls, .xlsx"
        className="d-none"
        type="file"
        multiple
      />
      <button
        id="chat-upload-button"
        onClick={handleUpload}
        className={`chat-upload-button ${show ? undefined : "d-none"}`}
      >
        Attach file
      </button>
    </div>
  );
};

export default ChatUploadButton;
