import React, {useContext, useEffect, useState} from "react"
import {NewRequestContext} from "./NewRequestContext";
import {ModalContext} from "../../modal/ModalContext";
import AsyncSelect from "react-select/async";
import {useHistory} from "react-router-dom";
import traderapi from "../../../traderapi/api";
import debounce from "lodash.debounce";
import { Store } from "../../../flux";


const TraderSelect = ({traderInfo}) => {
  const history = useHistory()
  const {hideModal, setModalSize} = useContext(ModalContext)
  const {
    traderId, setTraderId,
    source, setSource, setRequestedItems,
    setProviders, setExtensions,
  } = useContext(NewRequestContext);
  const [invalidTP, setInvalidTP] = useState(false)

  const nextButtonEnabled = () => {
    return (!!traderId && source !== '' && !invalidTP)
  }

  useEffect(() => {
    setModalSize('md')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (traderId && !traderInfo) {
      getRequests(traderId)
    }
    if (!traderId && traderInfo) {
      traderapi.searchTraders(traderInfo.applicationID.reference.client).then((response) => {
        const found = {
          value: response.items[0].reference?.client || 'none',
          label: `${response.items[0].personal.firstName} ${response.items[0].personal.lastName}`,
          id: response.items[0]._id,
          software: response.items[0].software,
          softwareType: response.items[0].softwareType
        }
        setTraderId(found)
        setSource("phone");
        getRequests(found)
        history.push({
          pathname: '/details-mnu',
          state: {header: 'Change Request on behalf of Client: Market Data'}
        })
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traderInfo, traderId]);
  
  const formatOptionLabel = ({value, label}) => {
    return (
      <div className="format-options-block">
        <div className="w-50">{value}</div>
        <div className="w-50 mx-2">({label})</div>
      </div>
    )
  }
  const getRequests = (traderId) => {
    const result = Store.getTradingPlatforms().find(t => t.software === traderId.software && t.softwareType === traderId.softwareType);
    if (result) { setInvalidTP(false) 
    } else setInvalidTP(true);
    getAccRequests(traderId.id); // get requests after application has been chosen
    getAccExtensions(traderId.id); // get exts after application has been chosen
    const newProviders = {};
    newProviders.MDProviders = Store.getMarketdataProviders();// get marketdata providers after application has been chosen
    newProviders.TPProviders = Store.getTradingPlatforms();// get trading platforms after application has been chosen
    setProviders(newProviders);
  }

  const getAccRequests = (id) => {
    try {
      traderapi.getRequests((response) => setRequestedItems(response.items), {
        applicationID: id,
        type: "MARKETDATA",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAccExtensions = async (id) => {
    try {
      const response = await traderapi.getExt(id, {
        code: "trading/marketdata",
      });
      setExtensions(response.list);
    } catch (error) {
      console.log(error);
    }
  };

  const loadTraders = debounce((search, callback) => {
    setTraderId("")
    try {
      traderapi.searchTraders(search).then((response) => {
        callback(response.items.map((trader) => {
          return {
            value: trader.reference?.client || 'none',
            label: `${trader.personal.firstName} ${trader.personal.lastName}`,
            id: trader._id,
            software: trader.software,
            softwareType: trader.softwareType
          }
        }))
      })
    } catch (error) {
      console.log(error)
      callback([])
    }
  }, 600)

  if (!traderInfo)return (
    <>
      <div className="trader-select-block">
        <div className="d-flex mb-2">
          <div className="received-by-label">
            Received by
          </div>
          <div className="received-by-list">
            <select
              className="form-control"
              placeholder="Select source"
              value={source}
              onChange={(event) => setSource(event.target.value)}
            >
              <option value="" disabled>Select source</option>
              <option value="phone">Phone</option>
              <option value="email">Email</option>
            </select>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-25 d-flex flex-column justify-content-center">
            Trader ID
          </div>
          <div className="w-75 trader-id-list">
            <AsyncSelect
              styles={
                {option: base => {
                    return({
                    ...base,
                    backgroundColor: "white",
                    color: "black",
                    borderBottom: "1px solid lightgrey",
                    "&:active": {
                      backgroundColor: "white",
                    }
                  })},
                  control: base => {
                    return({
                    ...base,
                    borderColor: "black",
                    borderRadius: 8,
                    cursor: "text",
                    "&:hover": {
                      borderColor: "black",
                    }
                  })}
                }
              }
              placeholder="Search Trader ID or name"
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              name="traderId"
              formatOptionLabel={formatOptionLabel}
              loadOptions={loadTraders}
              onChange={(option) => setTraderId(option)}
            />
          </div>
        </div>
          {invalidTP && <div className="mt-4 not-match-block">No configuration exists for {traderId?.software} {traderId?.softwareType}. This request is not possible</div>}
      </div>
      <div>
        <hr/>
      </div>
      <div className="d-flex justify-content-center">
        {nextButtonEnabled() ?
          <button
            type="button"
            className="btn btn-primary w-25 mx-1"
            onClick={() => {
              history.push({
                pathname: '/details-mnu',
                state: {header: 'New Request on behalf of Client: Market Data'}
              })
            }}
          >Next
          </button>
          :
          <button
            type="button"
            disabled
            className="btn btn-outline-secondary w-25 mx-1"
          >Next</button>
        }
        <button
          type="button"
          className="btn btn-outline-secondary w-25 mx-1"
          onClick={hideModal}
        >
          Cancel
        </button>
      </div>
    </>
  )
  return null;
};

export default TraderSelect
