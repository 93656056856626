import { baseurl } from "../../../traderapi/api";
const axios = require("axios").default;

const chatAPI = {
  getChat: async (appID, requestID) => {
    try {
      const response = await axios.get(`${baseurl}/chat/${appID}/${requestID}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (error.response.status === 404) return { success: true, messages: [] };
      console.trace(`ERROR: ${error}`);
    }
  },
  sendMessage: async (requestID, body, action = "") => {
    try {
      const response = await axios.put(
        `${baseurl}/request/${requestID}/${action}`,
        body
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.trace(`ERROR: ${error}`);
    }
  },
  sendAttachment: async (appID, body) => {
    const formData = new FormData();
    body.attachments.forEach(at => {
      formData.append("attachments[]", at.file);
    })
    const response = await axios.post(`${baseurl}/chat/attachments/${appID}`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      },
    });
    return response.data;
  },
  getAttachPreview: async (appID, attachID) => {
    const response = await axios.get(`${baseurl}/chat/attachments/${appID}/${attachID}`, {
      responseType: "blob",
    });
    return response;
  },
};

export default chatAPI;
