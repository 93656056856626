import {pushToast} from "../components/toaster/helper";
import {TOAST_TYPE_ALERT} from "../components/toaster/types";
import { setStatusErrorHandler } from "components/request/helper";

const axios = require('axios').default;

export const baseurl = process.env.REACT_APP_ZIMTRAAPI;

const getToken = () => localStorage.getItem('token');

const login = async (username, password) => {
    return axios.post( `${baseurl}/users/login` , {
        username,
        password
    }).then((response) => {
        if(response.status === 200) {
            console.log(`INFO: Successfully logged ${username}`);
            localStorage.setItem('token', response.data.token);
            return response.data;
        }
    });
};

const logout = (redirect) => {
    localStorage.removeItem('token');
    if (redirect) window.location.replace('/');
};

const getUsers = async (cb) => {
    axios.get( `${baseurl}/users/list`)
        .then((response) => {
            if(response.status ===200) {
                cb(response.data);
            }
        })
        .catch((err) => {
            console.trace(`ERROR: ${err}`);
        })
};

const getGroups = async (cb) => {
    axios.get( `${baseurl}/agency/list`)
        .then((response) => {
            if(response.status ===200) {
                cb(response.data);
            }
        })
        .catch((err) => {
            console.trace(`ERROR: ${err}`);
        })
};

const getPresets = async (cb) => {
    axios.get( `${baseurl}/preset/list`)
        .then((response) => {
            if(response.status ===200) {
                cb(response.data);
            }
        })
        .catch((err) => {
            console.trace(`ERROR: ${err}`);
        })
};

const getRSMetadata = async (params) => {
  axios.get( `${baseurl}/request/risk-setting-metadata`, params)
    .then((response) => {
      if(response.status === 200) {
        return response.data;
      }
    })
    .catch((err) => {
      console.trace(`ERROR: ${err}`);
    })
};

const getList = (list) => {
  return async (cb, params = {}) => {
    const { cancelTokenSource, ...otherParams } = params;

    const requestConfig = {
      params: otherParams,
    };

    if (cancelTokenSource) {
      requestConfig.cancelToken = cancelTokenSource.token;
    }

    return axios.get(`${baseurl}/${list}`, requestConfig)
      .then((response) => {
        if (response && response.status === 200) {
          cb(response.data);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          console.trace(`ERROR: ${err}`);
        }
      });
  };
};
const getRequestByID = async (id) => {
    return axios.get(`${baseurl}/request/list?id=${id}`)
      .then((response) => {
        if (response && response.status === 200) {
          return response.data;
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          console.trace(`ERROR: ${err}`);
        }
      });
  };;

const myself = getList('users/myself');

const getActivations = getList('activations');
//
const getCalculationRates = getList('static/calculationrates');
const getCountryRates = getList('static/countryrates');
const getTradingPlatform = getList('static/tradingplatforms');
const getDocumentTemplate = getList('static/documenttemplate');
const getMarketDataProviders = getList('static/marketdataproviders');
const getCountries = getList('static/country');

const getApplications = getList('application/list');
const getRegistrations = getList('draft/list');

const getStats = getList('test/dashboard/stats');

const getReportDuplicateGovid = getList('report/duplicate/govid');
const getReportDuplicateName = getList('report/duplicate/name');
const getReportDuplicateEmail = getList('report/duplicate/email');
const getReportExpiredDocument = getList('report/expired/document');
const getReportTradeProAML = getList('report/constructor/tradeProAML');
const getNewAccountsSterling = getList('report/constructor/newAccountsSterling');
const getNewAccountsTradeZero = getList('report/constructor/newAccountsTradeZero');
const getNewAccountsDAS = getList('report/constructor/newAccountsDAS');
const getNewAccountsTakion = getList('report/constructor/newAccountsTakion');
const getReportTradingPlatform = getList('report/constructor/newaccounts');
const getReportAnalyticsAll = getList('report/analytics/all');
const getStatistic = getList('application/portal-usage/statistic');

const getClaimed = getList('wire/list');
const getRequests = getList('request/list');

const saveWire = (cb, data) => {
  axios({
    method: 'POST',
    url: `${baseurl}/wire`,
    data
  }).then((response) => {
    cb(response.data)
  }).catch((err) => {
      console.trace(`ERROR: ${err}`);
      cb(err.response.data);
    })
}

const sendReport = (cb, data, reportUrl) => {
  axios({
    method: 'POST',
    url: `${baseurl}/report/send/${reportUrl}`,
    data
  }).then((response) => {
    cb(response.data)
  }).catch((err) => {
    console.trace(`ERROR: ${err}`);
    cb(err.response?.data);
  })
}

const saveApplication = async(cb, a) => {
    axios.post( `${baseurl}/application`, a)
        .then((response) => {
            cb(response.data);
        })
        .catch((err) => {
            console.trace(`ERROR: ${err}`);
            cb(err.response.data);
        })
}

const saveApplicationReview = async(cb, a, id) => {
    axios.post( `${baseurl}/application/${id}/review`, a)
        .then((response) => {
            cb(response.data);
        })
        .catch((err) => {
            console.trace(`ERROR: ${err}`);
            cb(err.response.data);
        })
}


const saveEntity = async(entity, cb, a) => {
    axios.post( `${baseurl}/${entity}`, a)
        .then((response) => {
            cb(response.data);
        })
        .catch((err) => {
            console.trace(`ERROR: ${err}`);
            cb(err.response.data);
        })
}

const statusApplication = async(cb, a) => {
    axios.put( `${baseurl}/application/status`, a)
        .then((response) => {
            if(response.status === 200) {
                cb(response.data);
            } else {
                cb(response.data);
            }
        })
        .catch((err) => {
            console.trace(`ERROR: ${err}`);
            cb(err.response.data);
        })
}

const getSearchData = async(data) => {
    try {
        const response = await axios.get( `${baseurl}/application/list?search=${data}`)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        console.trace(`ERROR: ${error}`);
    }
}

const getChangeLog = async(appID) => {
    try {
        const response = await axios.get( `${baseurl}/application/${appID}/history`)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        console.trace(`ERROR: ${error}`);
    }
}

const getExt = async(appID, params) => {
    try {
        const response = await axios.get( `${baseurl}/application/ext/${appID}`, params)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        console.trace(`ERROR: ${error}`);
    }
}

const getConfirmation = (wireId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${baseurl}/wire/${wireId}/confirmation`,
        {
          responseType: "blob"
        }
      )

      if (response.status === 200) {
        const reader = new FileReader()
        reader.readAsDataURL(response.data);
        reader.onload = () => {
          const image = reader.result;
          let dataReceived = response.data.type
          resolve(`data:${dataReceived};base64,${image.split(",")[1]}`)
        }
      } else {
        reject(response.statusText)
      }
    } catch (error) {
      reject(error)
    }
  })
}

const getScan = async (cb, clientID, scanID) => {
    try {
      const response = await axios.get( `${baseurl}/application/documents/${clientID}/${scanID}`, {responseType: "blob"})
        if(response.status === 200) {
          const reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = () => {
            const image = reader.result;
            let dataReceived = response.data.type
            if(dataReceived.split("/")[1] === "pdf") dataReceived = "application/pdf";
            cb(`data:${dataReceived};base64,${image.split(",")[1]}`);
          };
        }
    } catch (error) {
        console.trace(`ERROR: ${error}`);
    }
};

const setRequestStatus = async (id, status, declineError) => {
  try {
    const response = await axios.put(`${baseurl}/request/${id}/${status}`)
    if(response.status === 200) {
      return response.data
    }
  } catch (error) {
    return setStatusErrorHandler(error, declineError)
  }
}

const createUpdateMDRequest = async (isCreate, body, requestID) => {
  try {
    let response = null;
    if (isCreate) {
      response = await axios.post(`${baseurl}/request`, body)
    } else {
      response = await axios.put(`${baseurl}/request/${requestID}`, body)
    }
    if(response.status === 200) {
      return response.data
    }
  } catch (error) {
    const msg = error.message && error.response?.data?.message
    pushToast(msg, TOAST_TYPE_ALERT)
    return false
  }
}

const searchTraders = async (search) => {
  try {
    const response = await axios.get( `${baseurl}/application/list`, {
      params: {
        search: search,
        select: ['personal.firstName', 'software', 'softwareType', 'personal.lastName', 'reference.client'],
        status: ['AccountEnabled']
      }
    })
    if(response.status === 200) {
      return response.data
    }
  } catch (error) {
    const msg = error.message && error.response?.data?.message
    pushToast(msg, TOAST_TYPE_ALERT)
    return false
  }
}

const setRequestAppliedValue = async (data) => {
  try {
    const response = await axios.put(`${baseurl}/request/${data?.id}/apply`, {
      value: data.value
    })
    if(response.status === 200) {
      return response.data
    }
  } catch (error) {
    const msg = error.message && error.response?.data?.message
    pushToast(msg, TOAST_TYPE_ALERT)
    return false
  }
}

const traderapi = {
    login,
    myself,
    logout,
    getToken,
    getUsers,
    getGroups,
    getChangeLog,
    getSearchData,
    getPresets,
    getRSMetadata,
    getExt,
    createUpdateMDRequest,
    getApplications, saveApplication, statusApplication, saveApplicationReview,
    getReportDuplicateGovid, getReportDuplicateName, getReportDuplicateEmail, getReportExpiredDocument,
    getReportTradeProAML, sendReport,
    getNewAccountsSterling, getNewAccountsDAS, getNewAccountsTakion, getNewAccountsTradeZero,
    getActivations,
    getRegistrations,
    getCalculationRates,
    getCountryRates,
    getTradingPlatform,
    getClaimed, saveWire,
    getDocumentTemplate,
    getMarketDataProviders,
    getCountries,
    getScan,
    getConfirmation,
    getStats,
    getReportTradingPlatform,
    saveEntity,
    getRequests,
    getRequestByID,
    setRequestStatus,
    searchTraders,
    getReportAnalyticsAll,
    setRequestAppliedValue,
    getStatistic
};
export default traderapi;
