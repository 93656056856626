import traderapi from "../../traderapi/api";
import momentTZ from "moment-timezone";

class ReportUsageStatistics {

  makeRecord({ fullName, email, date, tradeGroup, event, ...rest }) {
    const res = {
      "Email": email || null,
      "Full name": fullName || '',
      "Date and time": momentTZ(rest.date).tz("America/New_York").format('YYYY.MM.DD, HH:MM:SS A') || null,
      "Trade Group": tradeGroup || null,
      "Event": event || null,
    };

    return res;
  }
  //
  list(params, cb) {
    traderapi.getStatistic((data) => {
      const items = data?.items?.map(this.makeRecord);
      cb({
        items,
        data,
      });
    }, params);
  }
}

export default ReportUsageStatistics;
