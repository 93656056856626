import React, {useState} from "react";

export const NewRequestContext = React.createContext({});
export const NewRequestContextProvider = ({children}) => {
  const [source, setSource] = useState('');
  const [confirmationType, setConfirmationType] = useState("")
  const [traderId, setTraderId] = useState();
  const [requestDetails, setRequestDetails] = useState();
  const [entitlementsData, setEntitlementsData] = useState();
  const [requestedMarketData, setRequestedMarketData] = useState(null);
  const [providers, setProviders] = useState(null);
  const [requestedItems, setRequestedItems] = useState(null);
  const [extensions, setExtensions] = useState(null);
  const [isNewApplication, setIsNewApplication] = useState(true);

  return (
    <>
      <NewRequestContext.Provider value={{
        traderId, setTraderId,
        source, setSource,
        requestDetails, setRequestDetails,
        entitlementsData, setEntitlementsData,
        requestedMarketData, setRequestedMarketData,
        providers, requestedItems, setRequestedItems,
        setProviders, extensions, setExtensions,
        isNewApplication, setIsNewApplication,
        confirmationType, setConfirmationType
      }}>
        {children}
      </NewRequestContext.Provider>
    </>
  );
};
