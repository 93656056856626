import React from "react";

import DefaultLayout from "../../modal/DefaultLayout";
import {MemoryRouter, Switch, Route, withRouter} from "react-router-dom";
import TraderSelect from "./TraderSelect";
import DetailsMenu from "./DetailsMenu";
import {NewRequestContextProvider} from "./NewRequestContext";
import Confirmation from "./Confirmation";

const RoutedLayout = withRouter(({location,editRequestData }) => (
  <DefaultLayout header={location.state?.header}>
    <Switch>
      <Route path="/trader-select">
        <TraderSelect traderInfo={editRequestData}/>
      </Route>
      <Route path="/details-mnu">
        <DetailsMenu traderInfo={editRequestData}/>
      </Route>
      <Route path="/confirmation">
        <Confirmation/>
      </Route>
    </Switch>
  </DefaultLayout>
))


const CreateMarketDataRequest = ({editRequestData, data}) => {
  return (
    <NewRequestContextProvider>
      <MemoryRouter
        initialEntries={[
          {
            pathname: "/trader-select",
            state: {header: `${!!editRequestData ? "Change" : "New"} Request on behalf of Client: Market Data`}
          },
          "/details-mnu",
          "/confirmation",
        ]}
        initialIndex={0}
      >
        <RoutedLayout editRequestData={editRequestData}/>
      </MemoryRouter>
    </NewRequestContextProvider>
  )
};

export default CreateMarketDataRequest;
