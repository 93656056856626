import cloneDeep from 'lodash/cloneDeep';

const Events = {
  CHANGE: "CHANGE",
  CHANGE_BADGES: "CHANGE",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  TOGGLE_POPUP: "TOGGLE_POPUP",
  TOGGLE_IS_LOADING: "TOGGLE_IS_LOADING",
  //
  NEWSEARCH: "NEWSEARCH",
  SEARCHDONE: "SEARCHDONE",
  DATASETUPDATED: "DATASETUPDATED",
  USERLOGIN: "USERLOGIN",
  USERDATA: "USERDATA",
  USERLOGOUT: "USERLOGOUT",
  RELOADDATA: "RELOADDATA",
  RELOADTABLEDATA: "RELOADTABLEDATA",
  DOWNLOAD: "DOWNLOAD",
  SETWORKINGDATASET: "SETWORKINGDATASET",
  SETACTIVEDATASETTAB: "SETACTIVEDATASETTAB",
  SETACTIVEREQUESTID: "SETACTIVEREQUESTID",
  SETACTIVEDATASET: "SETACTIVEDATASET",
  SETPAGINATIONVALUES: "SETPAGINATIONVALUES",
  SETSORT: "SETSORT",
  SETFILTER: "SETFILTER",
  SETTABLEFILTERS: "SETTABLEFILTERS",
  OPENREFERENCE: "OPENREFERENCE",
  SAVEDATASET: "SAVEDATASET",
  SET_HEADER: "SET_HEADER",
  SET_REDIRECTED_URL: "SET_REDIRECTED_URL",
  SET_FIRST_REDIRECTED_URL: "SET_FIRST_REDIRECTED_URL",
  TOGGLE_DIALOG_WINDOW: "TOGGLE_DIALOG_WINDOW",
  SET_MARKET_DATA_PROVIDERS: "SET_MARKET_DATA_PROVIDERS",
  SET_TRADING_PLATFORMS: "SET_TRADING_PLATFORMS",
  SET_RS_METADATA: "SET_RS_METADATA",
  //
  CREATE_DATASET_VIEW: "CREATE_DATASET_VIEW",
  DELETE_DATASET_VIEW: "DELETE_DATASET_VIEW",
  //
  CREATE_DATASET_REPORT: "CREATE_DATASET_REPORT",
  GET_DATASET_CLAIMED: "GET_DATASET_CLAIMED",
  //
  SET_BADGES_DATA: "SET_BADGES_DATA",
  GET_BADGES_DATA: "GET_BADGES_DATA",
  //
  PUSH_TOAST: "PUSH_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST",

  ATTACH_APPLICATION: "ATTACH_APPLICATION",
  DETACH_APPLICATION: "DETACH_APPLICATION",

  ATTACH_REQUEST: "ATTACH_REQUEST",
  DETACH_REQUEST: "DETACH_REQUEST",

  ATTACH_CHAT: "ATTACH_CHAT",
  DETACH_CHAT: "DETACH_CHAT",
  NEW_CHAT_MESSAGE: "NEW_CHAT_MESSAGE",

  REFRESH_STATS: "REFRESH_STATS",
};

const reviewStatuses = {
  OK: "ok",
  DEFICIENT: "deficient",
  UPDATED: "updated",
}

const reportStatuses = {
  CREATE: "create",
  REQUESTED: "requested",
}

const allowedPaginationedDatasets = [
  "r",
  "a",
  "v/trader",
  "v/ops",
  "requests",
  "requestsEnable",
  "requestsDisable",
  "requestsRisk",
  "requestsCustom",
  "reportusagestatistics"
];

const ApplicationStatus = [
  'Incomplete',
  'InProgress',

  'Received',
  'UnderReview',
  'RequireInformation',

  'Approved',
  'Cancelled',
  'Rejected',
  'Updated',
  'Closed',

  'EnableRequest',
  'DisableRequest',

  'WirePending',
  'WireSent',
  'WireConfirmed',
  'WireFailed',

  'AccountCreated',
  'AccountEnabled',
  'AccountSuspended',
  'AccountClosed',
  'AccountReopened',
];

const TradingExperienceList = ['active', 'learn', 'tiny'];

const SoftwareList = ['Takion', 'Sterling', 'DAS', 'TradeZero'];

const SoftwareTypeList = ['Pro', 'Web'];

const TitleList = ['Mr.', 'Mrs.', 'Ms.', 'Mx.', 'Miss'];

const TraderTypeList = ['Pro', 'Beginner'];

const IDTypeList = ['passport', 'driverlicense', 'idcard'];

const requestErrorMessage = ( 
  <div>
    <span>This request cannot be resolved.</span>
    <br />
    <span>It was declined by SYSTEM because another request of the same category was created.</span>
  </div>
);

const RequestsDatasetList = ['requests', 'requestsEnable', 'requestsDisable', 'requestsRisk', 'requestsCustom'];
const ApplicationsDatasetList = ['a', 'r', 'v/ops', 'v/trader'];
const NegateDatasetList = [
  'reportTradeProAML',
  'reportSterlingTrader',
  'report/Sterling/create',
  'report/Sterling/requested',
  'report/TradeZero/create',
  'report/TradeZero/requested',
  'report/DAS/create',
  'report/DAS/requested',
  'report/Takion/create',
  'reportanalyticsall',
  'reportusagestatistics',
]
const SubmittableHeaderDatasetList = ['reportTradeProAML', 'TradeZero', 'reportSterlingTrader', 'Sterling', 'DAS', 'Takion', 'report']
const ReportsDatasetList = ['reportTradeProAML', 'reportSterlingTrader']
const PBDatasetList = ['claimed', 'pending', 'received']
const NewAccounts = ['newAccountsSterling', 'newAccountsDAS', 'newAccountsTakion']
const TradePlatformDatasets = ['Sterling', 'DAS', 'Takion', 'TradeZero']
const TabsHeaderDatasetList = ['requests', 'requestsEnable', 'requestsDisable', 'requestsRisk', 'requestsCustom']
const defaultApplicationHeaderItems = [
  "#",
  "email",
  "firstName",
  "lastName",
  "citizenship",
  "country",
  "tradingExperience",
  "status",
  "accountType",
  "tradingPlatform",
  "statusUpdatedAt",
  "createdAt",
  "updatedAt"
];

const applicationDataSetSortingList = {
  firstName: {
    name: 'personal.firstName',
    order: 'desc',
    search: ''
  },
  lastName: {
    name: 'personal.lastName',
    order: 'desc',
    search: ''
  },
  citizenship: {
    name: 'personal.citizenship',
    order: 'desc',
    search: ''
  },
  country: {
    name: 'address.country',
    order: 'desc',
    search: ''
  },
  accountType: {
    name: 'accountType',
    order: 'desc',
    search: ''
  },
  tradingPlatform: {
    name: 'software',
    order: 'desc',
    search: ''
  },
  status: {
    name: 'status',
    order: 'desc',
    search: ''
  },
  statusUpdatedAt: {
    name: 'statusUpdatedAt',
    order: 'desc',
    search: ''
  },
  createdAt: {
    name: 'createdAt',
    order: 'desc',
    search: ''
  },
  updatedAt: {
    name: 'updatedAt',
    order: 'asc',
    search: '',
    active: true
  }
}

const applicationIncompleteDataSetSortingList = {
  country: {
    name: 'country',
    order: 'desc',
    search: ''
  },
  accountType: {
    name: 'accountType',
    order: 'desc',
    search: ''
  },
  createdAt: {
    name: 'createdAt',
    order: 'desc',
    search: ''
  },
  updatedAt: {
    name: 'updatedAt',
    order: 'asc',
    search: '',
    active: true
  },
  firstName: {
    name: 'firstName',
    order: 'desc',
    search: ''
  },
  lastName: {
    name: 'lastName',
    order: 'desc',
    search: ''
  },
  citizenship: {
    name: 'citizenship',
    order: 'desc',
    search: ''
  },
  tradingPlatform: {
    name: 'tradingplatform',
    order: 'desc',
    search: ''
  },
  step: {
    name: 'onboardingState',
    order: 'desc',
    search: ''
  }
}

const applicationDataClone = cloneDeep(applicationDataSetSortingList);
const reverseApplicationDataSetSortingList = Object.keys(applicationDataClone).reduce((acc, key) => {
  acc[key] = {
    ...applicationDataSetSortingList[key],
    order: applicationDataSetSortingList[key].order === 'asc' ? 'desc' : 'asc'
  };
  return acc;
}, {});

const performanceBondDataSetSortingList = {
  'Trader Id': {
    name: 'applicationID.reference.client',
    order: 'desc',
    search: ''
  },
  'Account Name': {
    name: 'applicationID.personal.firstName',
    order: 'desc',
    search: ''
  },
  'Application Approval': {
    name: 'applicationID.statusUpdatedAt',
    order: 'desc',
    search: '',
  },
  'Requested On': {
    name: 'requestedOn',
    order: "desc",
    search: "",
  },
  'Claimed Via': {
    name: 'claim.source',
    order: 'desc',
    search: ''
  },
  'Claimed On': {
    name: 'claim.date',
    order: 'desc',
    search: ''
  },
  'Amount Pending': {
    name: 'amount.pending',
    order: 'desc',
    search: '',
    active: true
  },
}

const marketdataDataSetSortingList = {
  'Due Date': {
    name: 'schedule.duedate',
    order: 'desc',
    search: ''
  },
  'Requested On': {
    name: 'createdAt',
    order: 'asc',
    search: '',
    active: true
  },
  'ID': {
    name: 'sequenceNumber',
    order: 'desc',
    search: ''
  },
  'Trader ID': {
    name: 'applicationID.reference.client',
    order: 'desc',
    search: ''
  },
  'Platform': {
    name: 'applicationID.software',
    order: 'desc',
    search: ''
  },
  'Status': {
    name: 'status',
    order: 'desc',
    search: ''
  },
  "Who's Doing": {
    name: 'userID.name',
    order: 'desc',
    search: ''
  }
}

const accountEnableDataSetSortingList = {
  'Due Date': {
    name: 'schedule.duedate',
    order: 'desc',
    search: ''
  },
  'Requested On': {
    name: 'createdAt',
    order: 'asc',
    search: '',
    active: true
  },
  'ID': {
    name: 'sequenceNumber',
    order: 'desc',
    search: ''
  },
  'Trader ID': {
    name: 'applicationID.reference.client',
    order: 'desc',
    search: ''
  },
  'Platform': {
    name: 'applicationID.software',
    order: 'desc',
    search: ''
  },
  'Account Type': {
    name: 'applicationID.accountType',
    order: 'desc',
    search: ''
  },
  'Platform Status': {
    name: 'applicationID.platformStatus',
    order: 'desc',
    search: ''
  },
  'Status': {
    name: 'status',
    order: 'desc',
    search: ''
  },
  "Who's Doing": {
    name: 'userID.name',
    order: 'desc',
    search: ''
  },
}

const customRequestsDataSetSortingList = {
  'Requested On': {
    name: 'createdAt',
    order: 'asc',
    search: '',
  },
  'Updated On': {
    name: 'updatedAt',
    order: 'desc',
    search: '',
    active: true
  },
  'ID': {
    name: 'sequenceNumber',
    order: 'desc',
    search: ''
  },
  'Trader ID': {
    name: 'applicationID.reference.client',
    order: 'desc',
    search: ''
  },
  'Platform': {
    name: 'applicationID.software',
    order: 'desc',
    search: ''
  },
  'Account Type': {
    name: 'applicationID.accountType',
    order: 'desc',
    search: ''
  },
  'Status': {
    name: 'status',
    order: 'desc',
    search: ''
  },
  "Who's Doing": {
    name: 'userID.name',
    order: 'desc',
    search: ''
  },
}

const riskSettingsDataSetSortingList = {
  ...cloneDeep(customRequestsDataSetSortingList),
  'Category': {
    name: 'ext.field',
    order: 'desc',
    search: ''
  },
  'Current': {
    name: 'ext.oldValue',
    order: 'desc',
    search: ''
  },
  'Requested': {
    name: 'ext.value',
    order: 'desc',
    search: ''
  },
  'Applied': {
    name: 'ext.applied',
    order: 'desc',
    search: ''
  },
  'Type': {
    name: 'subtype',
    order: 'desc',
    search: ''
  },
  'Balance': {
    name: 'balance',
    order: 'desc',
    search: ''
  }
};

const usageStatisticSetSortingList = {
  'Email': {
    name: 'email',
    order: 'desc',
    search: ''
  },
  'Full name': {
    name: 'fullName',
    order: 'asc',
    search: '',
  },
  'Date and time': {
    name: 'date',
    order: 'desc',
    search: '',
    active: true
  },
  'Trade Group': {
    name: 'tradeGroup',
    order: 'desc',
    search: ''
  },
  'Event': {
    name: 'event',
    order: 'desc',
    search: ''
  },
}


const constants = {
  Events,
  requestErrorMessage,
  reviewStatuses,
  reportStatuses,
  ApplicationStatus,
  TradingExperienceList,
  SoftwareList,
  SoftwareTypeList,
  TraderTypeList,
  IDTypeList,
  TitleList,
  RequestsDatasetList,
  ApplicationsDatasetList,
  NegateDatasetList,
  ReportsDatasetList,
  NewAccounts,
  allowedPaginationedDatasets,
  TradePlatformDatasets,
  PBDatasetList,
  defaultApplicationHeaderItems,
  SubmittableHeaderDatasetList,
  TabsHeaderDatasetList,
  applicationDataSetSortingList,
  reverseApplicationDataSetSortingList,
  performanceBondDataSetSortingList,
  marketdataDataSetSortingList,
  accountEnableDataSetSortingList,
  riskSettingsDataSetSortingList,
  customRequestsDataSetSortingList,
  applicationIncompleteDataSetSortingList,
  usageStatisticSetSortingList
};

export default constants;
