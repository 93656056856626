import React from "react";

const Platform = ({platform}) => {
  let link;

  if (platform === 'Takion') {
    link = 'http://10.101.3.101/TakionATM/';
  }
  if (platform === 'Sterling') {
    link = 'https://sem.sterlingtradingtech.com/auth/';
  }
  if (link) {
    return <a href={link} target="_blank" rel="noreferrer">{platform}</a>
  } else {
    return <>{platform}</>
  }
}

export default Platform;
