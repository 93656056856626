import React, { useState } from "react";

import ChatHeader from "./ChatHeader";
import ChatMessage from "./ChatMessage";
import ChatPopup from "./chat-popup/ChatPopup";
import constants from "./types";

import "./chat-window.css";

const ChatWindow = ({
  //other
  show = true, // show chat window
  senderType = "Trader", // See only public messages; (change to [adminType] to see internal support messages.)
  adminType = "Support", // admin type; see all types of messages
  adminID = null, // if the sender is admin we need to varify admin sender among other internal admins' messages in message history
  onBackClick, // handles behavior after back button clicked
  // header
  headerItems, // items to display in the header. Receives object {[item name]:[item value]}
  headerButtonType = "nobutton", // "nobutton" / "cancel" / "resolved"
  onHeaderButtonClick = null, // handles behavior after header button clicked if any exists
  customHeaderComponent = null, // custom header component
  //message
  onMessageSend,// handles behavior after "Send" button clicked
  messageTypes = { _internal: "PRIVATE", _public: "PUBLIC" }, // types for display message; internal type to show only for admins, public - for all
  messageData = [], // message data; Receives array with objects with such params: [{id: string, body: string, time: dateValue, sender: string, type: string, attachedFiles?: array},.....]
  chatDataIsDownloading = false, // while chat data is dowloading
  showUploadButton = true, // whether show or not upload button
  onUploadAttachment = null, // handler to set attache to be uploaded or to be removed
  attachIsUploading = false, // while attach is uploading
  attachIsDownloading = false, // while attach is downloading
  onHistoryAttachClick = null, //handles when click on attachment name in message history to open attachment
}) => {

  const defaultPopupState = {
    type: "",
    show: false,
    title: "",
    message: "",
    cb: null,
  };
  const [popupState, setPopupState] = useState(defaultPopupState);

  const onPopupClickHandler = (type) => {
    const { popupTypes } = constants;
    switch (type) {
      case popupTypes.NO_RESPONSE:
      case popupTypes.INFORMATIONAL:
        setPopupState(defaultPopupState);
        break;
      case popupTypes.REMOVE_ATTACH:
      case popupTypes.CANCEL_REQUEST:
      case popupTypes.RESOLVE_REQUEST:
        popupState.cb();
        setPopupState(defaultPopupState);
        break;

      default:
        setPopupState(defaultPopupState);
        break; 
    }
  };

  if (show)
    return (
      <div className="chat-wrapper">
        {onBackClick && (
          <button
            className="chat-window-back-button"
            onClick={onBackClick}
          >{`< Back`}</button>
        )}
        {customHeaderComponent 
          ? customHeaderComponent
          : <ChatHeader
              headerItems={headerItems}
              onHeaderButtonClick={onHeaderButtonClick}
              headerButtonType={headerButtonType}
              setPopupState={setPopupState}
            />
        }
        <ChatMessage
          senderType={senderType}
          adminID={adminID}
          messageTypes={messageTypes}
          adminType={adminType}
          messageData={messageData}
          chatDataIsDownloading={chatDataIsDownloading}
          attachIsUploading={attachIsUploading}
          onMessageSend={onMessageSend}
          setPopupState={setPopupState}
          showUploadButton={showUploadButton}
          onUploadAttachment={onUploadAttachment}
          attachIsDownloading={attachIsDownloading}
          onHistoryAttachClick={onHistoryAttachClick}
        />
        <ChatPopup
          popupState={popupState}
          onPopupClickHandler={onPopupClickHandler}
        />
      </div>
    );
  return null;
};

export default ChatWindow;
