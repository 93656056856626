import React from "react";

import {ModalHeader, ModalBody} from "shards-react";

import "../table-modal/TableModal.css"

const DefaultLayout = ({children, header}) => {
  return (
    <>
      {header &&
      <ModalHeader className="table-modal-header">
        {header}
      </ModalHeader>
      }

      <div className="table-modal-wrapper">
        <ModalBody className="table-modal-body">
          {children}
        </ModalBody>
      </div>
    </>
  )
};

export default DefaultLayout;
