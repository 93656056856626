import traderapi from "../../traderapi/api";
import F from '../fields.js';

class CountryRateDataset {

    makeRecord(a) {
        return {
            id: F.createFieldReference( "countryrates", a._id),
            country: a.country,
            accountTypeRef: a.accountTypeRef,
            classifier: a.classifier,
            rates: F.createFieldList(a.tiers.map((t)=>{
              switch (a.classifier) {
                case 'shares':
                  return `${t.min}...${t.max} COMM: ${t.commission}`;
                case 'payout':
                  return `${t.min}...${t.max} PAYOUT: ${t.payout}`;
                default:
                  return '';
              }
            }), { lines: 7})
        }
    }
    //
    list(params, cb) {
        traderapi.getCountryRates((data) => {
            const items = data.map(this.makeRecord);
            cb({
                items,
                data
            });

        }, params);
    }
    //
    get(id, cb) {
        this.list({id}, cb)
    }
    //
    save(item, cb) {
        traderapi.saveApplication((data) => {
        cb(data);
    }, item);
    }
}

export default CountryRateDataset;
