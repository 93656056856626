import React, {useEffect, useState} from 'react'
import {
  Button,
  FormInput,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "shards-react";
import traderapi from "../../traderapi/api";

import './TableModal.css'
import {Link} from "react-router-dom";

const PENDING = 'Pending';
const CLAIMED = 'Claimed';
const RECEIVED = 'Received';

const claimSources = [
  "Phone",
  "Email",
]

const receivedLimit = 100000000;

const TableModal = (props) => {
  const {dataModal, openModal, modalToggle, refreshCallback} = props
  const {
    'Account Name': accountName,
    'Trader Id': traderId,
    applicationId,
    status,
    wireId,
  } = dataModal;

  const optionArray = [CLAIMED, RECEIVED, PENDING].filter(option => option.toLowerCase() !== status?.value)
  const [option, setOption] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [claimSource, setClaimSource] = useState(claimSources[0])

  const isReceivedSelected = option === RECEIVED;
  const renderOptions = optionArray
    .map((item, index) => <option value={item} key={index}>{item}</option>);

  useEffect(() => {
    const filtered = optionArray.filter(option => option !== status?.value);
    setOption(filtered[0])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataModal])

  const handleConfirmButton = () => {
    const params = {
      status: option.toLowerCase(),
      amount: inputValue,
      wireID: wireId.value,
    };

    if(option === CLAIMED){
      params.source = claimSource
    }

    traderapi.saveWire((data) => {
      if (data.success) {
        refreshCallback()
        return data;
      } else {
        console.error(`Updating status failed`, data);
        alert(data.message);
      }
    }, params)

    modalToggle();
  }

  const handleBlur = () => {
    if (inputValue) {
      const formattedValue = inputValue.replace(/[e,+,-]/g, '');

      setInputValue(formattedValue);
    }
  }

  const handleInputField = ({ target }) => {
    const { value, maxLength } = target;
    const limitValue = value.slice(0, maxLength);

    if (value <= receivedLimit) {
      setInputValue(limitValue);
    }
  };

  return (
    <div>
      <Modal size="sm" open={openModal} toggle={modalToggle}>
        <ModalHeader className="table-modal-header">
          <Link to={`/a/${applicationId?.value}`} className="table-modal-header-link" target="_blank">
            <span className="header-user-name" title={traderId?.value}>{traderId?.value}</span>-
            <span className="header-user-name" title={accountName?.value}>{accountName?.value}</span>
          </Link>
        </ModalHeader>

        <div className="table-modal-wrapper">
          <ModalBody className="table-modal-body">
            <FormSelect
              className="table-modal-select"
              value={option}
              onChange={({target}) => {
                setOption(target.value)
              }}
            >
              {renderOptions}
            </FormSelect>

            {isReceivedSelected &&
            <div className="table-modal-input">
              <span className="table-modal-input-label">$</span>
              <FormInput
                autoFocus
                type="number"
                value={inputValue}
                maxLength={9}
                min={0}
                max={receivedLimit}
                onChange={handleInputField}
                onBlur={handleBlur}
              />
            </div>
            }

            {(option === CLAIMED) &&
              <FormSelect
                className="table-modal-select"
                value={claimSource}
                onChange={({target}) => {
                  setClaimSource(target.value)
                }}
              >
                {
                  claimSources.map((item, index) =>
                    <option value={item} key={index}>{item}</option>)
                }
              </FormSelect>
            }

          </ModalBody>
          <ModalFooter className="table-modal-footer">
            <Button
              theme="white"
              disabled={isReceivedSelected && !inputValue}
              onClick={handleConfirmButton}
            >
              Confirm
            </Button>
            <Button
              theme="white"
              onClick={modalToggle}
            >
              Cancel
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
}

export default TableModal;
