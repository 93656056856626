import React from "react";
import traderapi from "../../traderapi/api";
import {Redirect} from "react-router-dom";
import {Dispatcher, Events} from "../../flux/index";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      redirect: false,
      invalidCredentials: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    traderapi.login(this.state.username, this.state.password)
      .then((data) => {
        this.setState({redirect: true});
        Dispatcher.dispatch({
          actionType: Events.USERLOGIN
        });
        if(data.success) {
          traderapi.myself(async (data)=>{
            Dispatcher.dispatch({actionType:Events.USERDATA, payload: data});
          })
        }
      })
      .catch((e) => {
        console.trace(`ERROR: ${e}`);
        this.setState({invalidCredentials: true});
      });
  }

  render() {
    const { username, password, redirect } = this.state;

    if (redirect) {
      return <Redirect to='/'/>;
    }

    return <form onSubmit={this.handleSubmit}>
      <h3>Sign In</h3>

      {this.state.invalidCredentials && (
        <div className="alert alert-danger text-center" role="alert">
          Wrong username or password.
        </div>
      )}

      <div className="form-group">
        <input value={username} onChange={this.handleChange} type="text" name="username" className="form-control" placeholder="Enter username" />
      </div>

      <div className="form-group">
        <input value={password} onChange={this.handleChange} type="password" name="password" className="form-control" placeholder="Enter password" />
      </div>

      <button disabled={!this.state.username || !this.state.password} type="submit" className="btn btn-primary btn-block">SUBMIT</button>
    </form>
  }
}

export default Login;
