import traderapi from "../../traderapi/api";
import F from '../fields.js';

class CountryDataset {

    makeRecord(a) {
        return {
            id: F.createFieldReference("countries", a._id),
            code: a.code,
            name: a.name,
            allowtrade: F.createFieldBoolean(a.allowtrade, {select: ['Prohibited', 'Allow trade']}),
            translation: F.createFieldList(a.translation.map((t)=>(`${t.lang} : ${t.name}`)))
        }
    }
    //
    list(params, cb) {
        traderapi.getCountries((data) => {
            const items = data.countries.map(this.makeRecord);
            cb({
                items,
                data
            });
            
        }, params);
    }
    //
    get(id, cb) {
        this.list({id}, cb)
    }
    //
    save(item, cb) {
        traderapi.saveApplication((data) => {
          cb(data);
        }, item);
    }
}

export default CountryDataset;