import moment from "moment";
import momentTZ from "moment-timezone";
import { pushToast } from "../components/toaster/helper";
import { TOAST_TYPE_ALERT } from "../components/toaster/types";
import { TODAY, YESTERDAY } from "../components/datasetTabs/tabsListData";

export const utilityFunctions = {
  transformToNestedField: (fieldName, isImage) => {
    if (isImage) {
      return `document.scanFiles.${fieldName}`;
    }
    switch (fieldName) {
      case "city":
      case "country":
      case "postalCode":
      case "street":
      case "street2":
        return `address.${fieldName}`;
      case "idexpiration":
      case "idnumber":
      case "idtype":
        return `document.${fieldName}`;

      case "pep":
      case "details":
        return `pep.${fieldName}`;
      case "citizenship":
      case "firstName":
      case "lastName":
      case "middleName":
      case "title":
        return `personal.${fieldName}`;
      default:
        return fieldName;
    }
  },
  currencyFormater: () => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    })
  },
  checkIfNotValidMarketdataRequest: (localValue) => {
    return (
      localValue === "Invalid date" ||
      momentTZ(localValue).isBefore(new Date(), "day") ||
      momentTZ(localValue).isAfter(momentTZ().add(1, "year"))
    )
  },
  convertMarkets: (markets) => {
    if (!!markets?.length) {
      if (typeof markets[0] === "object") {
        return markets.map(m => `${m.group}-${m.market}`)
      } else return markets
    }
    return markets
  },
  transfromFromNestedField: (field) => {
    if (field?.split(".").length > 1) {
      return field?.split(".").at(-1);
    }
    return field;
  },
  removeUnnecessaryProps: (object, keys) => {
    return Object.entries(object).reduce((res, [key, value]) => (
      keys.indexOf(key) === -1 ? { ...res, [key]: value } : res
    ), {})
  },
  addColumnsException: (data, v, h) => {
    if (h === 'Full name' && data?.applicationID?.personal) {
      return `${data?.applicationID?.personal?.firstName} ${data?.applicationID?.personal?.lastName}`;
    }
    if (h === "Who's Doing") {
      return data?.["Full name"].value || "N/A"
    }
    return v
  },
  getApplicationItemCommentableList: () => {
    return [
      "title",
      "firstName",
      "middleName",
      "lastName",
      "citizenship",
      "dateofbirth",
      "phone",
      "pep",
      "street",
      "street2",
      "city",
      "postalCode",
      "country",
      "idtype",
      "idnumber",
      "idexpiration",
      "scans",
      "identity",
      "photoIDback",
      "photoID",
      "address",
    ];
  },
  changeLabelView: (label) => {
    switch (label) {
      case "title":
        return "Salutation";
      case "pep":
        return "PEP Comment";
      case "phone":
        return "Phone Number (including country code)";
      case "street":
        return "Street Address";
      case "street2":
        return "Street Address (additional)";
      case "postalCode":
        return "ZIP/Postal code";
      case "idexpiration":
        return "Expiry date";
      case "address":
        return "Proof of Physical Address";
      case "identity":
        return "Self Photo";
      case "firstName":
        return "First Name";
      case "middleName":
        return "Middle Name";
      case "lastName":
        return "Last Name";
      case "dateofbirth":
        return "Date Of Birth";
      case "idtype":
        return "ID Type";
      case "idnumber":
        return "ID Number";
      case "performanceBond":
        return "Performance Bond Amount";
      case "maximumDailyLoss":
        return "maximum Daily Loss Limit";
      case "maximumTraderBalanceDrawDown":
        return "Maximum Trader Balance Draw Down";
      case "approvedOvernightBuyingPower":
        return "Approved Overnight Buying Power";
      case "initialBuyingPower":
        return "Initial Buying Power";
      case "tradingPlatformType":
        return "Trading Platform Type";
      case "tradingPlatform":
        return "Trading Platform";
      case "accountType":
        return "Account Type";

      default:
        return label;
    }
  },
  deepCopyFunction: (inObject) => {
    let outObject, value, key;
    if (typeof inObject !== "object" || inObject === null) {
      return inObject;
    }
    outObject = Array.isArray(inObject) ? [] : {};

    for (key in inObject) {
      value = inObject[key];
      outObject[key] = utilityFunctions.deepCopyFunction(value);
    }
    return outObject;
  },
  nativeDateTransform: (date, backBirth = false) => {
    if (date) {
      if (date.split("T").length > 1) {
        return date.split("T")[0];
      }
      if (backBirth) {
        const month = date.split("-")[1];
        const day = date.split("-")[2];
        const year = date.split("-")[0];
        return `${month}-${day}-${year}`
      }
      const month = date.split("-")[0];
      const day = date.split("-")[1];
      const year = date.split("-")[2];
      return `${year}-${month}-${day}`
    }
  },
  compareTwoObjectValues: (newData, oldData, isArray) => {
    if (isArray) {
      return newData.every((obj) => {
        const found2Obj = oldData?.value?.find((obj2) => {
          if (obj2["field"] === obj["field"]) return obj2;
          if (obj["status"] === "ok") return obj;
          return null;
        });
        if (found2Obj) {
          if (obj["status"] === "ok") return false;
          return obj["comment"] === found2Obj["comment"];
        }
        return false;
      });
    } else {
      return Object.entries(newData)
        .filter((f) => {
          if (f[1] === null) return f;
          if (typeof f[1] === "object" && !f[1].isSeparator) return f;
          if (typeof f[1] === "string") return f;
          return null;
        })
        .filter((f) => f[0] !== "review")
        .every((ar) => {
          const found2Obj = oldData[ar[0]];
          if (typeof ar[1] === "object") return ar[1].value === found2Obj.value;
          return ar[1] === found2Obj;
        });
    }
  },

  downloadReport: (dataset, items) => {
    if (!items || !items.length) {
      pushToast('No data returned, nothing to export?', TOAST_TYPE_ALERT);
      return;
    }
    const data = ([
      Object.keys(items[0]).join(','),
      ...items.map((i) => {
        return (
          Object.keys(i).map((k) => {
            const v = i[k];
            if (typeof v != 'object') {
              return typeof v === 'undefined' ? '' : v;
            } else if (typeof v === 'object') {
              if (v) {
                if (v.isDate || v.isDateTime) {
                  return v.value || '';
                } else if (v.isBoolean) {
                  const select = v.select || ['false', 'true'];
                  return v.value ? select[1] : select[0];
                } else if (v.isSeparator) {
                  return v.label.toUpperCase();
                } else if (v.isReference) {
                  if (typeof v.reference === 'function') {
                    return `${v.reference()}/${v.id}`;
                  } else {
                    return `${v.reference}/${v.id}`;
                  }
                } else if (v.isEnum) {
                  return v.value || '';
                } else if (v.isList) {
                  return '(list)';
                } else if (v.isImages) {
                  return '(images)';
                } else if (v.isImage) {
                  return '(image)';
                } else {
                  return typeof v.value === 'undefined' ? '' : v.value;
                }
              } else {
                return '';
              }
            }
            return '';
          }).map((s) => s?.toString().replace(/,/g, ';').replace(/(\n|\r)+/g, ' ')).join(",")
        );
      })].join("\n"));

    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(data);
    let newYorkTimeFormat = moment(new Date()).tz("America/New_York").format("YYYYMMDD-HH_mm_ss");
    let tempLink = document.createElement("a");
    let name;
    let toastText

    switch (dataset) {
      case 'reportTradeProAML':
        name = 'TradePro_AML-';
        toastText = `TradePro AML report generated successfully. Number of Applications included: ${items.length}.`;
        break;
      case 'report/TradeZero/create':
        newYorkTimeFormat = moment(new Date()).tz("America/New_York").format("YYYYMMDD");
        name = 'TRB705_COPIA_NEW_ACCOUNTS_';
        toastText = 'Report generated successfully';
        break;
      default:
        name = dataset;
        toastText = 'Report generated successfully';
        break;
    }

    name = `${name}${newYorkTimeFormat}.csv`

    tempLink.href = dataStr;
    tempLink.setAttribute("download", name);
    tempLink.click();

    pushToast(toastText)
  },
  tableTimeFormat: (date, withSeconds) => {
    if (!date) return 'Pending';

    let result = momentTZ(date).tz("America/New_York");
    let dayDiff = momentTZ().tz("America/New_York")
      .startOf('day')
      .diff(momentTZ(date).tz("America/New_York").startOf('day'), 'days');
    let format = "h:mm A";
    if (withSeconds) {
      format = "h:mm:ss A"
    }

    switch (dayDiff) {
      case 0:
        result = `${TODAY} at ${result.format(format)}`;
        break
      case 1:
        result = `${YESTERDAY} at ${result.format(format)}`;
        break
      default:
        result = result.format(`YYYY.MM.DD ${format}`);
        break
    }

    return result;
  }

};
