import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import traderapi from "../../../../traderapi/api";
import './NavbarNav.css';
import {Store, Events, Dispatcher} from "../../../../flux";
import Avatar from "react-avatar";



export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      user: Store.getLoggedUser()
    };
    this.onUserData = this.onUserData.bind(this);
    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  componentDidMount() {
    Store.resetEmitter(true, Events.USERDATA, this.onUserData);
  }
  componentWillUnmount() {
    Store.resetEmitter(false, Events.USERDATA, this.onUserData);
  }

  onUserData() {
    this.setState({user: Store.getLoggedUser()});
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    const onDropdownClickHandler = (e) => {
      if (Store.getPopupState()) {
        Dispatcher.dispatch({actionType:Events.TOGGLE_DIALOG_WINDOW, payload: true});
        if (e.target.name === "userprofile") {
          Dispatcher.dispatch({actionType: Events.SET_REDIRECTED_URL, payload: "user-profile"});
        }
        if (e.target.name === "logout") {
          Dispatcher.dispatch({actionType: Events.SET_REDIRECTED_URL, payload: '/trigger-logout'});
        }
      }
    }

    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={(props) => <NavLink {...props} />} className="text-nowrap px-3 dropdown-toggle-block">
          <Avatar name={this.state.user.name} size="45" round="10px" color="#007bff"/>
          {" "}
          <span className="d-none d-md-inline-block">{this.state.user.name}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={(props) => <Link {...props} />} name="userprofile" onClick={onDropdownClickHandler} to={Store.getPopupState() ? "#" : "user-profile"}>
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          {/* <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem name="logout" onClick={Store.getPopupState() ? onDropdownClickHandler : traderapi.logout} className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
