import { useEffect, useState, useCallback } from 'react'
import { ALL, TODAY, IMMEDIATELY, UNRESOLVED, REQUEST_FORMATED_LABELS } from './tabsListData';
import { Store } from "../../flux";

function replaceSpacesInClassname(item) {
  return item.replaceAll(' ', '-');
}

const priorities = [IMMEDIATELY, TODAY, UNRESOLVED];

const DatasetTabs = ({ data, listName, setActiveItem }) => {
  const firstElement = data?.slice(0, 1) || [];
  let defaultElements = priorities.filter(priority => data.includes(priority));

  if (defaultElements.length === 0) {
    defaultElements.push(...firstElement);
  }

  const [activeTabs, setActiveTabs] = useState(defaultElements);
  const activeTabsLength = activeTabs?.length;

  const handleClick = (e, item) => {
    const isAllItems = activeTabs.includes(ALL);
    let newActiveTabs = [item];

    if ((e.ctrlKey || e.shiftKey) && item !== ALL) {
      e.stopPropagation();
      e.preventDefault();

      if (isAllItems) {
        return;
      }

      if (activeTabs.includes(item)) {
        if (activeTabsLength > 1) {
          newActiveTabs = activeTabs.filter((tab) => tab !== item);
        }
      } else {
        newActiveTabs = [...activeTabs, item];
      }
    }

    setActiveTabs(newActiveTabs);
    setActiveItem({
      [listName]: newActiveTabs,
    });
  }

  const handleActiveTab = useCallback(({ datasetName, params = {} }) => {
    const { conditions, statusCode } = params;
    let formattedData = conditions?.map(condition => REQUEST_FORMATED_LABELS[condition] || condition);
    switch (datasetName) {
      case 'requestsEnable':
      case 'requestsDisable':
        setActiveTabs(listName === 'dateFilters' ? formattedData : statusCode);
        setActiveItem({
          statusFilters: statusCode,
          dateFilters: formattedData
        });
        break;
      case 'requestsRisk':
      case 'requestsCustom':
        setActiveTabs(formattedData);
        setActiveItem({
          [listName]: formattedData,
        });
        break;
      default:
        break;
    }
  }, [listName, setActiveItem]);

  useEffect(() => {
    Store.addSetActiveTabListener(handleActiveTab);
    return () => Store.removeSetActiveTabListener(handleActiveTab);
  }, [handleActiveTab])

  return (
    <ul className="dataset-tabs-box">
      {data?.map((item) => (
        <li
          key={item}
          className={`dataset-tab-item
           ${replaceSpacesInClassname(item.toLowerCase())}
           ${activeTabs.includes(item) ? 'active' : ''}
           ${listName === 'statusFilters' && item === ALL ? 'ml-3' : ''}
          `}
          onClick={(e) => handleClick(e, item)}
        >
          {item}
        </li>
      ))}
    </ul>
  );
}

export default DatasetTabs;
