import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import constants from "./constants";
import sidebarNavItems from "../data/sidebar-nav-items";
import traderapi from "../traderapi/api";
import ApplicationDataset from "./dataset/application";
import RegistrationDataset from "./dataset/registration";
import GroupDataset from "./dataset/group";
import UserDataset from "./dataset/user";
import PresetDataset from "./dataset/preset";
import CountryDataset from "./dataset/country";
import CalculationRateDataset from "./dataset/calculationrate";
import CountryRateDataset from "./dataset/countryrate";
import TradingPlatformDataset from "./dataset/tradingplatform";
import DocumentTemplateDataset from "./dataset/documenttemplate";
import MarketDataBundleDataset from "./dataset/marketdatabundle";
import MarketDataProviderDataset from "./dataset/marketdataprovider";
import StatisticsDataset from "./dataset/statistics";
import ReportDuplicateName from "./dataset/reportname";
import ReportDuplicateEmail from "./dataset/reportemail";
import ReportDuplicateGovid from "./dataset/reportgovid";
import ReportExpiredDocument from "./dataset/reportdocument";
import ReportAnalyticsAll from "./dataset/reportanalyticsall";
import ReportUsageStatistics from "./dataset/reportusagestatistics";
import ReportTradeProAML from "./dataset/reportAML";
import WireDataset from "./dataset/wire";
import NewAccountsSterling from "./dataset/newAccountsSterling";
import NewAccountsTradeZero from "./dataset/newAccountsTradeZero";
import NewTraderReportTradingPlatform from "./dataset/reportTradingPlatform";
import RequestDataset from "./dataset/request";
import RequestCustomDataset from "./dataset/requestCustom";
import { DISABLE, ENABLE, INCOMING, tabsListData, TODAY, UNRESOLVED, IMMEDIATELY, REQUEST_FORMATED_KEYS } from "../components/datasetTabs/tabsListData";
import { Manager } from "socket.io-client";
import merge from "lodash.merge";
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

const emptyFilter = () => {
  return {
    searchPattern: '',
    searchRecords: 0,
    filters: []
  }
};

let _store = {
  isLoading: false,
  menuVisible: false,
  formIsEditting: false,
  openedDialogWindow: false,
  navItems: sidebarNavItems.filter(navItem => !(navItem.show === false)),
  searchDataset: '',
  activeDataSet: null,
  redirectedURL: '',
  firstRedirectedURL: '',
  user: {},
  badgesData: {},
  toasterElements: [],
  activeRequestID: null,
  activeTableFilters: null,
  filter: {
    a: emptyFilter(),
    users: emptyFilter(),
    groups: emptyFilter(),
    presets: emptyFilter(),
    activations: emptyFilter(),
    calculationrates: emptyFilter(),
    countryrates: emptyFilter(),
    documenttemplates: emptyFilter(),
    marketdatabundles: emptyFilter(),
    marketdataproviders: emptyFilter(),
    r: emptyFilter(),
    tradingplatforms: emptyFilter(),
    countries: emptyFilter(),
    stats: emptyFilter(),
    reportduplicatename: emptyFilter(),
    reportduplicateemail: emptyFilter(),
    reportduplicategovid: emptyFilter(),
    reportexpireddocument: emptyFilter(),
    reportTradeProAML: emptyFilter(),
    newAccountsSterling: emptyFilter(),
    newAccountsTradeZero: emptyFilter(),
    claimed: emptyFilter(),
    reportanalyticsall: emptyFilter(),
    reportusagestatistics: emptyFilter()
  },
  dataset: {
    a: null,
    r: null,
    users: null,
    groups: null,
    presets: null,
    activations: null,
    calculationrates: null,
    countryrates: null,
    documenttemplates: null,
    marketdatabundles: null,
    marketdataproviders: null,
    tradingplatforms: null,
    countries: null,
    stats: null,
    reportduplicatename: null,
    reportduplicateemail: null,
    reportduplicategovid: null,
    reportexpireddocument: null,
    reportTradeProAML: null,
    newAccountsSterling: null,
    newAccountsTradeZero: null,
    claimed: null,
    reportanalyticsall: null,
    reportusagestatistics: null,
  },
  marketdataProviders: null,
  tradingPlatforms: null,
  socketManager: null,
  applicationSocket: null,
  statsSocket: null,
  requestSocket: null,
  chatSocket: null,
  rsMetadata: null
};

const getPageValues = (dataSetName) => {
  if (_store.dataset[dataSetName] === null) return { quantity: 25, startValue: 1 }
  return _store.dataset[dataSetName].paginationValues
};

const getSortValues = (dataSetName) => {
  if (_store.dataset[dataSetName] === null) return {};
  return _store.dataset[dataSetName].sortValues;
};

const createDataset = (Api, params = {}) => {
  return Object.assign({
    api: new Api(),
    items: [],
    singles: [],
    requested: [],
    raw: {},
    page: 1,
    total: 0,
    paginationValues: {
      quantity: 25,
      startValue: 1
    },
    searchPlaceholder: "Search ...",
    sortValues: {
      updatedAt: {
        name: 'updatedAt',
        order: 'asc',
        search: '',
        active: false
      },
    },
    hasData: false
  }, params);
};

class Store extends EventEmitter {
  constructor() {
    super();
    Dispatcher.register(this.registerToActions.bind(this));
    this.datasetList = this.datasetList.bind(this);
    this.datasetUpdated = this.datasetUpdated.bind(this);
    this.connectServer = this.connectServer.bind(this);
    this.disconnectServer = this.disconnectServer.bind(this);

    this.resetEmitter(true, constants.Events.USERDATA, this.connectServer);
    this.resetEmitter(true, constants.Events.USERLOGOUT, this.disconnectServer);

    //
    _store.dataset.a = createDataset(ApplicationDataset,
      {
        tableHeader: `Applications`,
        singleHeader: `Application`,
        defaultHeader: constants.defaultApplicationHeaderItems,
        supportSort: true,
        sortValues: cloneDeep(constants.applicationDataSetSortingList)
      }
    );
    _store.dataset.r = createDataset(RegistrationDataset,
      {
        tableHeader: `Incomplete applications`,
        singleHeader: `Incomplete application`,
        supportSort: true,
        sortValues: cloneDeep(constants.applicationIncompleteDataSetSortingList)
      }
    );
    _store.dataset.groups = createDataset(GroupDataset, {
      tableHeader: `Groups`,
      singleHeader: `User group`
    });

    _store.dataset.users = createDataset(UserDataset, {
      tableHeader: `System users`,
      singleHeader: `System user`,
    });

    _store.dataset.presets = createDataset(PresetDataset, {
      tableHeader: `Presets`,
      singleHeader: `Integration preset`,
    });

    _store.dataset.countries = createDataset(CountryDataset, {
      tableHeader: "Countries",
      singleHeader: `Country`,
    });

    _store.dataset.calculationrates = createDataset(CalculationRateDataset, {
      tableHeader: "CalculationRates",
      singleHeader: `Calculation rates`,
    });

    _store.dataset.countryrates = createDataset(CountryRateDataset, {
      tableHeader: "CountryRates",
      singleHeader: `Country rates`,
    });

    _store.dataset.tradingplatforms = createDataset(TradingPlatformDataset, {
      tableHeader: "TradingPlatforms",
      singleHeader: `Trading platform`,
    });

    _store.dataset.documenttemplates = createDataset(DocumentTemplateDataset, {
      tableHeader: "DocumentTemplates",
      singleHeader: "Document template",
    });

    _store.dataset.marketdatabundles = createDataset(MarketDataBundleDataset, {
      tableHeader: "MarketDataBundles",
      singleHeader: "Market data bundle",
    });

    _store.dataset.marketdataproviders = createDataset(MarketDataProviderDataset, {
      tableHeader: "MarketDataProviders",
      singleHeader: `Market data provider`,
    });

    _store.dataset.stats = createDataset(StatisticsDataset, {
      items: { length: 0 }
    });

    _store.dataset.reportduplicateemail = createDataset(ReportDuplicateEmail);
    _store.dataset.reportduplicatename = createDataset(ReportDuplicateName);
    _store.dataset.reportduplicategovid = createDataset(ReportDuplicateGovid);
    _store.dataset.reportexpireddocument = createDataset(ReportExpiredDocument);
    _store.dataset.reportanalyticsall = createDataset(ReportAnalyticsAll, {
      tableHeader: `Onboarding analytics`,
    });
    _store.dataset.reportusagestatistics = createDataset(ReportUsageStatistics, {
      tableHeader: `Usage statistics`,
      supportSort: true,
      sortValues: cloneDeep(constants.usageStatisticSetSortingList)
    });

    this.createDatasetView({
      name: 'reportTradeProAML',
      api: ReportTradeProAML,
      params: {
        tableHeader: 'TradePro AML',
        singleHeader: 'TradePro AML',
        searchPlaceholder: 'Search by TraderID, first or last name',
      },
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'status', value: 'notsent' }
        ]
      }
    });

    this.createDatasetView({
      name: 'newAccountsSterling',
      api: NewAccountsSterling,
      params: {
        tableHeader: 'New Accounts - Sterling Create',
        singleHeader: 'New Accounts - Sterling Create',
        searchPlaceholder: 'Search by TraderID, first or last name, email',
      },
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'status', value: 'notsent' }
        ]
      }
    });

    this.createDatasetView({
      name: 'newAccountsTradeZero',
      api: NewAccountsTradeZero,
      params: {
        tableHeader: 'New Accounts - TradeZero Create',
        singleHeader: 'New Accounts - TradeZero Create',
        searchPlaceholder: 'Search by TraderID, first or last name, email',
      },
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'status', value: 'notsent' }
        ]
      }
    });

    this.createDatasetView({
      name: 'pending',
      api: WireDataset,
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'status', value: 'pending' }
        ]
      },
      params: {
        searchPlaceholder: 'Search by TraderID, first or last name',
        tableHeader: 'Pending',
        singleHeader: 'Pending',
        defaultHeader: [
          '#',
          'Trader Id',
          'Account Name',
          'Application Approval',
          'Amount Pending',
          'Request Type',
        ],
        supportSort: true,
        sortValues: cloneDeep({
          ...omit(constants.performanceBondDataSetSortingList, ['Requested On', 'claimed Via', 'claimed On']),
        })
      },
    });

    this.createDatasetView({
      name: 'claimed',
      api: WireDataset,
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'status', value: 'claimed' }
        ]
      },
      params: {
        searchPlaceholder: 'Search by TraderID, first or last name',
        tableHeader: 'Claimed',
        singleHeader: 'Claimed',
        defaultHeader: [
          '#',
          'Trader Id',
          'Account Name',
          'Request Type',
          'Requested On',
          'Amount Pending',
          'Claimed Via',
          'Claimed On',
          'Confirmation',
        ],
        supportSort: true,
        sortValues: cloneDeep({
          ...omit(constants.performanceBondDataSetSortingList, ['Application Approval']),
        })
      },
    });

    this.createDatasetView({
      name: 'received',
      api: WireDataset,
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'status', value: 'received' },
          { field: 'sortKey', value: 'received.date' },
          { field: 'sortDir', value: '-1' }
        ]
      },
      params: {
        searchPlaceholder: 'Search by TraderID, first or last name',
        tableHeader: 'Received',
        singleHeader: 'Received',
        defaultHeader: [
          '#',
          'Trader Id',
          'Account Name',
          'Request Type',
          'Requested On',
          'Amount Pending',
          'Amount Received',
          'Claimed Via',
          'Claimed On',
          'Confirmation',
        ],
        supportSort: true,
        sortValues: cloneDeep({
          ...omit(constants.performanceBondDataSetSortingList, ['Application Approval', 'Amount Pending']),
          'Amount Received': {
            name: 'amount.received',
            order: 'desc',
            search: '',
            active: true
          },
          'Amount Pending': {
            name: 'amount.pending',
            order: 'asc',
            search: '',
          },
        })
      },
    });

    this.createDatasetView({
      name: 'v/ops',
      api: ApplicationDataset,
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'status', value: ['Received', 'Updated', 'UnderReview', 'EnableRequest', 'DisableRequest'] }
        ]
      },
      params: {
        tableHeader: `Need to review`,
        singleHeader: `Application`,
        defaultHeader: constants.defaultApplicationHeaderItems,
        supportSort: true,
        sortValues: cloneDeep(constants.applicationDataSetSortingList)
      },

    });
    this.createDatasetView({
      name: 'v/trader',
      api: ApplicationDataset,
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'status', value: ['RequireInformation'] }
        ]
      },
      params: {
        tableHeader: `Waiting response`,
        singleHeader: `Application`,
        defaultHeader: constants.defaultApplicationHeaderItems,
        supportSort: true,
        sortValues: cloneDeep(constants.applicationDataSetSortingList)
      }
    });

    this.createDatasetView({
      name: 'requests',
      api: RequestDataset,
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'type', value: 'MARKETDATA' },
          { field: 'statusCode', value: UNRESOLVED },
          { field: 'conditions', value: [TODAY] },
        ]
      },
      params: {
        searchPlaceholder: 'Search by TraderID',
        tableHeader: `Market Data Requests: `,
        singleHeader: `Market Data Requests: `,
        defaultHeader: tabsListData.marketDataSet.defaultHeader,
        supportSort: true,
        sortValues: cloneDeep(constants.marketdataDataSetSortingList)
      },
    });

    this.createDatasetView({
      name: 'requestsEnable',
      api: RequestDataset,
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'type', value: 'TRADING_ACCESS' },
          { field: 'action', value: ENABLE },
          { field: 'statusCode', value: UNRESOLVED },
          { field: 'conditions', value: [IMMEDIATELY, TODAY] },
        ]
      },
      params: {
        searchPlaceholder: 'Search by TraderID',
        tableHeader: `Requests To Enable Accounts: `,
        singleHeader: `Requests To Enable Accounts: `,
        defaultHeader: tabsListData.accountsToEnableSet.defaultHeader,
        supportSort: true,
        sortValues: cloneDeep(constants.accountEnableDataSetSortingList)
      },
    });

    this.createDatasetView({
      name: 'requestsDisable',
      api: RequestDataset,
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'type', value: 'TRADING_ACCESS' },
          { field: 'action', value: DISABLE },
          { field: 'statusCode', value: UNRESOLVED },
          { field: 'conditions', value: [IMMEDIATELY, TODAY] },
        ]
      },
      params: {
        searchPlaceholder: 'Search by TraderID',
        tableHeader: `Requests To Disable Accounts: `,
        singleHeader: `Requests To Disable Accounts: `,
        defaultHeader: tabsListData.accountsToDisableSet.defaultHeader,
        supportSort: true,
        sortValues: cloneDeep(constants.accountEnableDataSetSortingList)
      },
    });

    this.createDatasetView({
      name: 'requestsRisk',
      api: RequestCustomDataset,
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'type', value: 'RISKSETTING' },
          { field: 'conditions', value: [INCOMING] },
          { field: 'gettype', value: 1 },
        ]
      },
      params: {
        searchPlaceholder: 'Search by TraderID',
        tableHeader: `RiskSettings Requests: `,
        singleHeader: `RiskSettings Requests: `,
        defaultHeader: tabsListData.requestRisk.defaultHeader,
        supportSort: true,
        sortValues: cloneDeep(constants.riskSettingsDataSetSortingList)
      },
    });

    this.createDatasetView({
      name: 'requestsCustom',
      api: RequestCustomDataset,
      filter: {
        searchPattern: '',
        searchRecords: 0,
        filters: [
          { field: 'type', value: 'INQUIRY' },
          { field: 'conditions', value: [INCOMING] },
          { field: 'gettype', value: 1 },
        ]
      },
      params: {
        searchPlaceholder: 'Search by TraderID',
        tableHeader: `Custom Requests: `,
        singleHeader: `Custom Requests: `,
        defaultHeader: tabsListData.requestCustom.defaultHeader,
        supportSort: true,
        sortValues: cloneDeep(constants.customRequestsDataSetSortingList)
      },
    });

  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case constants.Events.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      case constants.Events.TOGGLE_IS_LOADING:
        this.toggleIsLoading(payload);
        break;
      case constants.Events.TOGGLE_POPUP:
        this.togglePopup(payload);
        break;
      case constants.Events.SET_REDIRECTED_URL:
        this.setRedirectedURL(payload);
        break;
      case constants.Events.TOGGLE_DIALOG_WINDOW:
        this.toggleDialogWindow(payload);
        break;
      case constants.Events.SET_FIRST_REDIRECTED_URL:
        this.setFirstRedirectedURL(payload);
        break;
      case constants.Events.SET_MARKET_DATA_PROVIDERS:
        this.setMarketDataProviders(payload);
        break;
      case constants.Events.SET_TRADING_PLATFORMS:
        this.setTradingPlatforms(payload);
        break;
      case constants.Events.SET_RS_METADATA:
        this.setRsMetadata(payload);
        break;
      case constants.Events.SET_HEADER:
        this.setDatasetHeaders(payload);
        break;
      case constants.Events.NEWSEARCH:
        this.setSearchPattern(payload);
        break;
      case constants.Events.SETACTIVEDATASETTAB:
        this.setActiveDataSetTab(payload);
        break;
      case constants.Events.SETACTIVEREQUESTID:
        this.setActiveRequestID(payload);
        break;
      case constants.Events.SETACTIVEDATASET:
        this.setActiveDataSet(payload);
        break;
      case constants.Events.SEARCHDONE:
        this.setSearchRecords(payload);
        break;
      case constants.Events.DATASETUPDATED:
        this.datasetUpdated();
        break;
      case constants.Events.USERLOGIN:
        this.userLogin(payload);
        break;
      case constants.Events.USERDATA:
        this.userData(payload);
        break;
      case constants.Events.RELOADDATA:
        this.loadDataset(payload);
        break;
      case constants.Events.RELOADTABLEDATA:
        this.loadDatasetWithParams(payload);
        break;
      case constants.Events.DOWNLOAD:
        this.download(payload);
        break;
      case constants.Events.USERLOGOUT:
        this.clearDataset();
        break;
      case constants.Events.SETWORKINGDATASET:
        this.setSearchDataset(payload);
        this.refreshDataset(payload);
        break;
      case constants.Events.SETPAGINATIONVALUES:
        this.setPaginationValues(payload);
        break;
      case constants.Events.SETSORT:
        this.clearPreviousSortValues(payload);
        this.setSortValues(payload);
        break;
      case constants.Events.SETFILTER:
        this.setFilter(payload);
        break;
      case constants.Events.SETTABLEFILTERS:
        this.setActiveTableFilters(payload);
        break;
      case constants.Events.SAVEDATASET:
        this.saveDataset(payload);
        break;
      case constants.Events.OPENREFERENCE:
        this.refreshDataset(payload.dataset, payload.id);
        this.emit(actionType, payload);
        break;
      case constants.Events.CREATE_DATASET_VIEW:
        this.createDatasetView(payload);
        break;
      case constants.Events.DELETE_DATASET_VIEW:
        this.deleteDatasetView(payload);
        break;
      case constants.Events.CREATE_DATASET_REPORT:
        this.createDatasetView(payload);
        break;
      case constants.Events.GET_DATASET_CLAIMED:
        this.createDatasetView(payload);
        break;
      case constants.Events.SET_BADGES_DATA:
        this.setBadgesData(payload);
        break;
      case constants.Events.GET_BADGES_DATA:
        this.getBadgesData();
        break;
      case constants.Events.PUSH_TOAST:
        this.pushToast(payload);
        break;
      case constants.Events.REMOVE_TOAST:
        this.removeToast(payload);
        break;
      case constants.Events.ATTACH_APPLICATION:
        this.attachApplication(payload);
        break;
      case constants.Events.DETACH_APPLICATION:
        this.detachApplication(payload);
        break;
      case constants.Events.ATTACH_REQUEST:
        this.attachRequest(payload);
        break;
      case constants.Events.DETACH_REQUEST:
        this.detachRequest(payload);
        break;
      case constants.Events.ATTACH_CHAT:
        this.attachChat(payload);
        break;
      case constants.Events.DETACH_CHAT:
        this.detachChat(payload);
        break;
      case constants.Events.REFRESH_STATS:
        this.refreshStats();
        break;
      default:
    }
  }

  connectServer() {
    console.log("CONNECT TO SOCKET SERVER");
    _store.socketManager = new Manager(process.env.REACT_APP_ZIMTRAAPI, {
      path: process.env.REACT_APP_SOCKETPATH,
      extraHeaders: {
        Authorization: `Bearer ${traderapi.getToken()}`
      }
    });
    _store.applicationSocket = _store.socketManager.socket("/applications");
    _store.statsSocket = _store.socketManager.socket("/stats");
    _store.requestSocket = _store.socketManager.socket("/requests");
    _store.chatSocket = _store.socketManager.socket("/chat");

    _store.applicationSocket.on("connect", (socket) => {
      console.log("Connected to application server");
    });
    _store.statsSocket.on("connect", (socket) => {
      console.log("Connected to stats server");
      _store.statsSocket.emit("stats:attach", "all");
      this.refreshStats();
    });
    _store.requestSocket.on("connect", (socket) => {
      console.log("Connected to request server");
    });

    _store.chatSocket.on("connect", (socket) => {
      console.log("Connected to chat server");
    });

    // application socket
    _store.applicationSocket.on("applications:update", (id, payload) => {
      // console.log(`update`, id, payload, _store.searchDataset);
      id.forEach((i) => this.refreshDataset(_store.searchDataset, i));
    });
    _store.applicationSocket.on("applications:watchers", (id, cnt) => {
      // console.log(`watchers`, id, cnt);
    });
    _store.applicationSocket.onAny((event, ...args) => {
      // console.log(`app socket got ${event}`, args);
    });

    // stats socket
    _store.statsSocket.onAny((event, ...args) => {
      // console.log(`stats socket got ${event}`, args);

      const checkAndUpdateTableData = async () => {
        const isRelevantTab = Object.values(tabsListData).some(tab => tab.datasetName === _store.searchDataset);
        if (!isRelevantTab) return;
      
        const params = this.getActiveTableFilters();
        const activeRequestID = this.getActiveRequestID();
        const data = {
          datasetName: _store.searchDataset,
          params,
        };
      
        const dispatchReloadTableData = (payloadData) => {
          Dispatcher.dispatch({
            actionType: constants.Events.RELOADTABLEDATA,
            payload: payloadData,
          })
        };
      
        if (!activeRequestID) {
          dispatchReloadTableData(data);
          return;
        }
      
        const response = await traderapi.getRequestByID(activeRequestID);
        const foundCurrentRequestStatus = this.getDataset(_store.searchDataset)?.items.find(i => i.id === activeRequestID)?.status;
        const responseRequestStatus = response.items[0]?.status;
      
        if (foundCurrentRequestStatus && responseRequestStatus && foundCurrentRequestStatus !== responseRequestStatus) {
          dispatchReloadTableData({ ...data, allData: true });
        }
        if (!foundCurrentRequestStatus) {
          dispatchReloadTableData({ ...data, allData: true });
        }
      };
      
      checkAndUpdateTableData().catch(console.error);
      
    });
    _store.statsSocket.on("stats:update", (id, payload) => {
      // console.log(`update`, id, payload);
      Dispatcher.dispatch({
        actionType: constants.Events.SET_BADGES_DATA,
        payload,
      });
    });

    // request socket
    _store.requestSocket.on("requests:update", (id, payload) => { // NOTE: this is not yet implemented on backend
      // console.log(`requests:update`, id, payload, _store.searchDataset);
      this.refreshDataset(_store.searchDataset, payload.requestID);
    });

    // chat socket

    // We don't need chat:request anymore, because we are using rest for that, to get data further we use chat:update

    // _store.chatSocket.on("chat:refresh", (payload) => {
    //   console.log(`chat:refresh INIT`, payload);
    //   Dispatcher.dispatch({
    //     actionType: constants.Events.NEW_CHAT_MESSAGE,
    //     payload,
    //   });
    // });

    _store.chatSocket.on("chat:update", (id, data) => {
      // console.log(`chat:update`, id, data);
      Dispatcher.dispatch({
        actionType: constants.Events.NEW_CHAT_MESSAGE,
        payload: {
          chatID: id,
          data
        },
      });
    });
  }

  disconnectServer() {
    _store.statsSocket = null;
    _store.applicationSocket = null;
    _store.chatSocket = null;
    _store.socketManager = null;
  }

  refreshStats() {
    // console.log("REFRESH STATS")
    if (_store.statsSocket?.connected) {
      _store.statsSocket.emit("stats:refresh", "all");
    }
  }

  attachApplication(payload) {
    // console.log("ATTACH", payload)
    if (_store.applicationSocket?.connected) {
      _store.applicationSocket.emit("applications:attach", payload.applicationID, { id: payload.applicationID });
    }
  }

  detachApplication(payload) {
    // console.log("DETACH", payload)

    if (_store.applicationSocket?.connected) {
      _store.applicationSocket.emit("applications:detach", payload.applicationID, { id: payload.applicationID });
    }
  }

  attachRequest(payload) {
    // console.log("ATTACH REQUEST", payload)
    if (_store.requestSocket?.connected) {
      _store.requestSocket.emit("requests:attach", payload.requestID, { id: payload.requestID });
    }
  }

  detachRequest(payload) {
    // console.log("DETACH REQUEST", payload)

    if (_store.requestSocket?.connected) {
      _store.requestSocket.emit("requests:detach", payload.requestID, { id: payload.requestID });
    }
  }

  attachChat(payload) {
    // console.log("ATTACH CHAT", payload)
    if (_store.chatSocket?.connected) {
      _store.chatSocket.emit("chat:attach", payload.requestID, { id: payload.requestID });
    }
  }

  detachChat(payload) {
    // console.log("DETACH CHAT", payload)

    if (_store.chatSocket?.connected) {
      _store.chatSocket.emit("chat:detach", payload.requestID, { id: payload.requestID });
    }
  }

  userLogin(user) {
    this.emit(constants.Events.USERLOGIN);
  }
  async userData(user) {
    _store.user = user;
    await this.createTradingplatformsDatasets();
    this.emit(constants.Events.USERDATA);
  }

  async createTradingplatformsDatasets() {
    await this.datasetList('tradingplatforms')
    const tradingPlatsorms = this.getDataset('tradingplatforms').items

    tradingPlatsorms.forEach((platform) => {
      const dsname = `report/${platform.software}/create`;
      if (!this.getDataset(dsname)) {
        const SP = 'Search by TraderID, first or last name';
        this.createDatasetView({
          name: dsname,
          api: NewTraderReportTradingPlatform,
          params: {
            tableHeader: `${platform.software}: Accounts to create`,
            singleHeader: dsname,
            searchPlaceholder: platform.software === "DAS" ? SP : `${SP}, email`,
          },
          filter: {
            searchPattern: '',
            searchRecords: 0,
            filters: [
              { field: 'status', value: 'notsent' },
              { field: 'trading platform', value: platform.software },
            ]
          }
        });
      }

      const dsname2 = `report/${platform.software}/requested`;
      if (!this.getDataset(dsname2)) {
        const SP = 'Search by TraderID, first or last name';
        this.createDatasetView({
          name: dsname2,
          api: NewTraderReportTradingPlatform,
          params: {
            tableHeader: `${platform.software}: Accounts requested`,
            singleHeader: dsname2,
            searchPlaceholder: platform.software === "DAS" ? SP : `${SP}, email`,
          },
          filter: {
            searchPattern: '',
            searchRecords: 0,
            filters: [
              { field: 'status', value: 'creationRequested' },
              { field: 'trading platform', value: platform.software },
            ]
          }
        });
      }

    })
  }

  userLogout() {
    _store.user = {};
    this.emit(constants.Events.USERDATA);
    this.emit(constants.Events.USERLOGOUT);
  }

  datasetUpdated() {
    this.emit(constants.Events.DATASETUPDATED);
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(constants.Events.CHANGE);
  }

  setMarketDataProviders(payload) {
    _store.marketdataProviders = payload;
    this.emit(constants.Events.SET_MARKET_DATA_PROVIDERS);
  }

  setTradingPlatforms(payload) {
    _store.tradingPlatforms = payload;
    this.emit(constants.Events.SET_TRADING_PLATFORMS);
  }

  setRsMetadata(payload) {
    _store.rsMetadata = payload;
    this.emit(constants.Events.SET_RS_METADATA);
  }

  togglePopup(currentValue) {
    _store.formIsEditting = currentValue;
    this.emit(constants.Events.CHANGE);
  }

  toggleIsLoading(currentValue) {
    _store.isLoading = currentValue;
    this.emit(constants.Events.TOGGLE_IS_LOADING);
  }

  toggleDialogWindow(currentValue) {
    _store.openedDialogWindow = currentValue;
    this.emit(constants.Events.TOGGLE_DIALOG_WINDOW);
  }

  setSearchPattern(pattern) {
    if (_store.searchDataset) {
      _store.filter[_store.searchDataset].searchPattern = pattern;
      this.emit(constants.Events.NEWSEARCH);
    }
  }

  setActiveDataSetTab(payload) {
    this.emit(constants.Events.SETACTIVEDATASETTAB, payload);
  }

  setActiveRequestID(payload) {
    _store.activeRequestID = payload
    this.emit(constants.Events.SETACTIVEREQUESTID, payload);
  }

  setDatasetExternalFilter(f) {
    if (_store.searchDataset) {
      _store.filter[_store.searchDataset].filters.push(f);
      this.emit(constants.Events.SETFILTER);
    }
  }

  setActiveTableFilters(data) {
    _store.activeTableFilters = data
    this.emit(constants.Events.SETTABLEFILTERS, data);
  }

  setSearchDataset(dataset) {
    _store.searchDataset = dataset;
    this.emit(constants.Events.SETWORKINGDATASET, dataset);
  }
  setRedirectedURL(payload) {
    _store.redirectedURL = payload;
    this.emit(constants.Events.SET_REDIRECTED_URL);
  }
  setFirstRedirectedURL(payload) {
    _store.firstRedirectedURL = payload;
    this.emit(constants.Events.SET_FIRST_REDIRECTED_URL);
  }

  setDatasetHeaders(payload) {
    _store.dataset[payload.datasetName].header = payload.values;
    this.emit(constants.Events.CHANGE);
  }

  setSearchRecords(n) {
    _store.searchRecords = n;
    this.emit(constants.Events.SEARCHDONE);
  }

  setBadgesData(payload) {
    _store.badgesData = merge(_store.badgesData, payload);
    this.emit(constants.Events.CHANGE_BADGES);
  }

  getBadgesData() {
    return _store.badgesData;
  }

  getRedirectedURL() {
    return _store.redirectedURL;
  }
  getActiveRequestID() {
    return _store.activeRequestID;
  }
  getDialogWindowState() {
    return _store.openedDialogWindow;
  }
  getFirstRedirectedURL() {
    return _store.firstRedirectedURL;
  }
  getLoggedUser() {
    return _store.user;
  }
  getMenuState() {
    return _store.menuVisible;
  }
  getPopupState() {
    return _store.formIsEditting;
  }
  getIsLoadingState() {
    return _store.isLoading;
  }
  getSearchPattern() {
    if (_store.searchDataset) {

      return _store.filter[_store.searchDataset]?.searchPattern;
    }
    return '';
  }

  getFilters(datesetName) {
    return _store.filter[datesetName].filters;
  }
  getActiveTableFilters() {
    return _store.activeTableFilters;
  }

  getSearchRecordsNumber() {
    return _store.searchRecords;
  }

  clearDataset() {
    Object.keys(_store.dataset).forEach((k) => {
      Object.assign(_store.dataset[k], {
        items: [],
        singles: [],
        requested: [],
        header: [],
        raw: {},
        page: 1,
        total: 0,
        hasData: false,
      });
    });
  }

  saveDataset(t) {
    _store.dataset[t.dataset].api.save(t.n, t.o, (d, n, o) => {
      if (d.success) {
        // console.log(`SAVED: ${n.id.id}`);
      } else {
        console.log(`FAILED to save`);
      }
      this.load(t.dataset, n.id.id);

    });
  }

  load(datasetName, id) {
    // console.log(`LOAD: ${id} from ${datasetName}`)
    const storage = _store.dataset[datasetName];
    if (storage.requested.indexOf(id) >= 0) {
      return;
    }
    storage.requested.push(id);
    storage.api.get(id,
      (e) => {
        // console.log(`LOADED: ${id} from ${datasetName}`, e)
        storage.requested = storage.requested.filter(x => x !== id);
        const item = e.items.find((i) => i.id?.id === id || i.id === id);
        if (!!item) {
          const index = storage.items.findIndex((i) => i.id.id === id);
          if (index >= 0) {
            storage.items[index] = item;
          } else {
            const sindex = storage.singles.findIndex((i) => i.id.id === id);
            if (sindex >= 0) {
              storage.singles[sindex] = item;
            } else {
              storage.singles.push(item);
            }
          }
          storage.hasData = true;
          this.datasetUpdated();
        } else {
          alert(`Bad reply for list element ${datasetName}.${id}`);
        }
      }
    );
  }

  datasetList(datasetName, params = {}, paginationValues, allData) {
    return new Promise(resolve => {
      const pv = paginationValues ?? getPageValues(datasetName);
      const sr = getSortValues(datasetName);
      let activeObject = Object.entries(sr).find(([k, v]) => v.active);
      let activeSR = activeObject ? sr[activeObject[0]] : sr[Object.keys(sr)[0]];
      const { order, name } = activeSR;

      const storage = _store.dataset[datasetName];
      const setOrder = () => {
        return order === 'desc' ? `-${name}` : name
      } 
      const s = (name === '#') ? undefined : setOrder();
      let cond = {
        pageLen: pv.quantity,
        page: pv.startValue,
        sort: s,
        search: this.getSearchPattern()
      };
      const filters = this.getFilters(datasetName);

      filters.forEach((f) => {
        return cond[f.field] = f.value;
      });

      cond = { ...cond, ...params };

      const formattedConditions = cond?.conditions?.map(c => REQUEST_FORMATED_KEYS[c] || c) || [];

      if (allData) {
        cond = {...cond, conditions: []}
      } else {
        cond = {...cond, conditions: formattedConditions}
      }


      storage.api.list(cond,
        (e) => {
          Object.assign(storage, {
            items: e.items,
            singles: [],
            raw: { ...e.data },
            page: e.data.page || 1,
            total: e.data.total,
            paginationValues: pv,
            sortValues: sr,
            hasData: true
          });

          if (e.header) {
            storage.header = e.header;
          }

          this.datasetUpdated();
          resolve();
        }
      );
    })
  }

  loadDataset(dataset) {
    if (!traderapi.getToken()) {
      return;
    }
    this.datasetList(dataset);
  }

  loadDatasetWithParams(data) {
    if (!traderapi.getToken()) {
      return;
    }

    const { datasetName, params, pv, allData } = data;

    this.setActiveTableFilters(params);

    this.datasetList(datasetName, params, pv, allData);
  }

  download(payload) {
    const datasetName = payload.dataset;
    const storage = _store.dataset[datasetName];

    const cond = {
      c: 99999,
      i: 0,
    };
    const filters = this.getFilters(datasetName);

    filters.forEach((f) => {
      return cond[f.field] = f.value;
    });

    storage.api.list(cond,
      (e) => {
        if (!e || !e.items || !e.items.length) {
          alert('No data returned, nothing to export?');
          return;
        }
        const filteredNoDownloadData = e.items.map(f => {
          const found = Object.entries(f).find(som => som[1]?.noDownload);
          found && delete f[found[0]];
          return f
        });
        const data = ([
          Object.keys(filteredNoDownloadData[0]).join(','),
          ...filteredNoDownloadData.map((i) => {
            return Object.keys(i).map((k) => {
              const v = i[k];
              if (typeof v != 'object') {
                return typeof v === 'undefined' ? '' : v;
              } else if (typeof v === 'object') {
                if (v) {
                  if (v.isDate) {
                    return v.value || '';
                  } else if (v.isDateTime) {
                    return v.value || 'N/A';
                  } else if (v.isBoolean) {
                    const select = v.select || ['false', 'true'];
                    return v.value ? select[1] : select[0];
                  } else if (v.isSeparator) {
                    return v.label.toUpperCase();
                  } else if (v.isReference) {
                    if (typeof v.reference === 'function') {
                      return `${v.reference()}/${v.id}`;
                    } else {
                      return `${v.reference}/${v.id}`;
                    }
                  } else if (v.isEnum) {
                    return v.value || '';
                  } else if (v.isList) {
                    return '(list)';
                  } else if (v.isImages) {
                    return '(images)';
                  } else if (v.isValue) {
                    return v.value ? v.value : '';
                  } else if (v.isImage) {
                    return '(image)';
                  } else {
                    return typeof v.value === 'undefined' ? '' : v.value;
                  }
                } else {
                  return '';
                }
              }
              return '';
            }).map((s, i) => {
              return s.toString().replace(/,/g, ';').replace(/(\n|\r)+/g, ' ')
            }).join(",")
          })].join("\n"));

        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(data);
        let tempLink = document.createElement("a");
        const name = `${payload.dataset}_${(new Date()).toISOString().slice(0, 19)}.csv`;
        tempLink.href = dataStr;
        tempLink.setAttribute("download", name);
        tempLink.click();
      }
    );
  }

  refreshDataset(datasetName, id) {
    const storage = _store.dataset[datasetName];
    if (id) {
      this.load(datasetName, id);
    } else if (storage.items.length === 0) {
      this.loadDataset(datasetName)
    } else {
      this.datasetUpdated();
    }
  }

  createDatasetView(payload) {
    _store.dataset[payload.name] = createDataset(payload.api, payload.params);
    _store.filter[payload.name] = payload.filter;
  }

  deleteDatasetView(dataset) {
    delete _store.dataset[dataset];
    delete _store.filter[dataset];
  }

  setPaginationValues(cb) {
    _store.dataset[cb.dataSetName].paginationValues = {
      quantity: Number(cb.quantity),
      startValue: Number(cb.startValue)
    }
    if (cb.loadWithActiveFilters) {
      const activeParams = this.getActiveTableFilters();
      this.loadDatasetWithParams({ datasetName: cb.dataSetName, params: activeParams })
    } else {
      this.loadDataset(cb.dataSetName);
    }
  }

  clearPreviousSortValues({ dataSetName, field }) {
    Object.keys(_store.dataset[dataSetName].sortValues).forEach((k) => {
      if (k !== field) {
        _store.dataset[dataSetName].sortValues[k].active = false;
        _store.dataset[dataSetName].sortValues[k].order = 'desc';
      }
    });
  }

  setSortValues({ dataSetName, field, ...rest }) {
    Object.assign(_store.dataset[dataSetName].sortValues[field], rest);
    this.loadDataset(dataSetName);
  }

  setFilter({ datasetName, params }) {
    _store.filter[datasetName].filters?.forEach((singleFilter) => {
      if (Object.keys(params).includes(singleFilter.field)) {
        singleFilter.value = params[singleFilter.field];
      }
    });
  }

  setTableFilter(cb) {
    Object.assign(_store.dataset[cb.dataSetName].sortValues, cb);
    this.loadDataset(cb.dataSetName);
  }

  setActiveDataset(datasetName) {
    _store.activeDataSet = _store.dataset[datasetName]
  }

  getActiveDataset() {
   return _store.activeDataSet
  }

  getDataset(name) {
    if (name) {
      return _store.dataset[name];
    }
    return _store.dataset;
  }

  getSidebarItems() {
    return _store.navItems;
  }

  addChangeListener(callback) {
    this.on(constants.Events.CHANGE, callback);
  }
  addChangeBadgesListener(callback) {
    this.on(constants.Events.CHANGE_BADGES, callback);
  }
  addWinPopListener(callback) {
    this.on(constants.Events.TOGGLE_DIALOG_WINDOW, callback);
  }
  addSearchListener(cb) {
    this.on(constants.Events.NEWSEARCH, cb);
  }
  addSetActiveTabListener(cb) {
    this.on(constants.Events.SETACTIVEDATASETTAB, cb);
  }
  addSearchDoneListener(cb) {
    this.on(constants.Events.SEARCHDONE, cb);
  }
  addIsLoadingListener(cb) {
    this.on(constants.Events.TOGGLE_IS_LOADING, cb);
  }

  resetDatasetListener(cb, add) {
    if (add) {
      this.on(constants.Events.DATASETUPDATED, cb);
    } else {
      this.removeListener(constants.Events.DATASETUPDATED, cb);
    }
  }

  resetSetSearchDatasetListener(cb, add) {
    if (add) {
      this.on(constants.Events.SETWORKINGDATASET, cb);
    } else {
      this.removeListener(constants.Events.SETWORKINGDATASET, cb);
    }
  }

  resetEmitter(on, ev, cb) {
    if (on) {
      this.on(ev, cb);
    } else {
      this.off(ev, cb);
    }
  }

  removeChangeListener(callback) {
    this.removeListener(constants.Events.CHANGE, callback);
  }
  removeChangeBadgesListener(callback) {
    this.removeListener(constants.Events.CHANGE_BADGES, callback);
  }
  removeWinPopListener(callback) {
    this.removeListener(constants.Events.TOGGLE_DIALOG_WINDOW, callback);
  }
  removeSearchListener(cb) {
    this.removeListener(constants.Events.NEWSEARCH, cb);
  }
  removeSetActiveTabListener(cb) {
    this.removeListener(constants.Events.SETACTIVEDATASETTAB, cb);
  }
  removeSearchDoneListener(cb) {
    this.removeListener(constants.Events.SEARCHDONE, cb);
  }
  removeIsLoadingListener(cb) {
    this.removeListener(constants.Events.TOGGLE_IS_LOADING, cb);
  }

  pushToast(toast) {
    _store.toasterElements.push(toast);
    this.emit(constants.Events.PUSH_TOAST);
  }

  removeToast(index) {
    delete (_store.toasterElements[index])
    if (_store.toasterElements.filter(Boolean).length === 0) {
      _store.toasterElements = [];
    }
    this.emit(constants.Events.REMOVE_TOAST);
  }

  getToasterElements() {
    return _store.toasterElements
  }

  getMarketdataProviders() {
    return _store.marketdataProviders
  }

  getRsMetadata() {
    return _store.rsMetadata
  }

  getTradingPlatforms() {
    return _store.tradingPlatforms
  }

  addToasterListener(cb) {
    this.on(constants.Events.PUSH_TOAST, cb);
    this.on(constants.Events.REMOVE_TOAST, cb);
  }

  removeToasterListener(callback) {
    this.removeListener(constants.Events.PUSH_TOAST, callback);
    this.removeListener(constants.Events.REMOVE_TOAST, callback);
  }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Store();
