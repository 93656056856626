import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { Dispatcher, Store } from "../../../flux";
import constants from "../../../flux/constants";

import ChatWindow from "../ChatWindow";

import chatAPI from "./chat-api";
import { utilityFunctions } from "../../../utils/utilityFunctions";

import "./chat-window-wrapper.css";

const ChatWindowWrapper = ({
  onBackClick,
  chatWindowChosenItem,
  adminID,
  customHeaderComponent,
}) => {
  const [messagesData, setMessagesData] = useState([]);
  const [tempAttachIDs, setTempAttachIDs] = useState(null);
  const [attachIsDownloading, setAttachIsDownloading] = useState(false);
  const [attachIsUploading, setAttachIsUploading] = useState(false);
  const [chatDataIsDownloading, setChatDataIsDownloading] = useState(false);

  useEffect(() => {
    if (chatWindowChosenItem) {
      getMessageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatWindowChosenItem]);

  const getMessageData = async () => {
    setChatDataIsDownloading(true);
    const response = await chatAPI.getChat(
      chatWindowChosenItem.applicationID._id,
      chatWindowChosenItem.id
    );

    if (response.success) {
      setChatDataIsDownloading(false);
      setMessagesData(response.messages);
    }
  };

  useEffect(() => {
    const handleAction = (action) => {
      switch (action.actionType) {
        case constants.Events.NEW_CHAT_MESSAGE:
          if (chatWindowChosenItem.id === action.payload.chatID) {
            setMessagesData((oldData) => [...oldData, action.payload.data]);
          }
          break;
        default:
          break;
      }
    };

    // Register the callback and save the chat data
    const dispatchChatData = Dispatcher.register(handleAction);

    // Clean up after the component unmounts
    return () => {
      Dispatcher.unregister(dispatchChatData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMessageSend = (messageData) => {
    const body = {
      message: messageData.message,
      messageType: messageData.messageType,
    };
    if (tempAttachIDs) {
      body.attachments = tempAttachIDs.map((a) => a.id);
    }
    chatAPI.sendMessage(
      chatWindowChosenItem.id,
      body,
      messageData.messageType === "PRIVATE"
        ? "internal-message"
        : "support-send"
    );
    if (tempAttachIDs) {
      body.attachments = tempAttachIDs.map((a) => ({
        ...a,
        _id: a.id,
        attachmentType: messageData.messageType,
      }));
    }
    body.author = {};
    body.author.name = adminID;
    body.author._id = uuidv4();
    if (!messagesData) {
      setMessagesData([body]);
    }
    setTempAttachIDs(null);
  };

  const headerItems = () => {
    const items = {};
    items["Account Type"] = chatWindowChosenItem["Account Type"];
    if (chatWindowChosenItem["Status"])
      items["Status"] = chatWindowChosenItem["Status"];
    items["CreatedAt"] = utilityFunctions.tableTimeFormat(
      chatWindowChosenItem["createdAt"]
    );
    items["Client ID"] =
      chatWindowChosenItem.applicationID.reference?.client ||
      chatWindowChosenItem.applicationID._id;
    items["Request ID"] = chatWindowChosenItem["Sequence Number"];
    items["Software"] = chatWindowChosenItem.applicationID.software;
    return items;
  };

  const setHeaderButtonType = () => {
    // if ((pickedItem?.type === "RISKSETTING" || pickedItem?.type === "TRADING_ACCESS") && (pickedItem?.status === "BLOCKED" || pickedItem?.status === "OPEN"))
    //   return "cancel";
    // if (pickedItem?.type === "INQUIRY" && pickedItem?.status === "OPEN")
    //   return "resolved";
    return "nobutton";
  };
  const onHeaderButtonClick = (e, type) => {};

  const onHistoryAttachClick = async (id, cb) => {
    setAttachIsDownloading(true);
    const response = await chatAPI.getAttachPreview(
      chatWindowChosenItem.applicationID._id,
      id
    );
    if (response) {
      cb(response);
      setAttachIsDownloading(false);
    }
  };

  const sendAttachments = async (appID, body) => {
    setAttachIsUploading(true);
    const response = await chatAPI.sendAttachment(appID, body);
    if (response.success) {
      setAttachIsUploading(false);
      tempAttachIDs?.length > 1
        ? setTempAttachIDs(tempAttachIDs.concat(response.attachments))
        : setTempAttachIDs(response.attachments);
    }
  };

  const onUploadAttachment = (attachment, isRemove, removeAll) => {
    const body = {};
    if (removeAll) {
      setTempAttachIDs(null);
      return;
    }
    if (isRemove) {
      let newAttachments = tempAttachIDs.map((i) => i);
      newAttachments = newAttachments.filter((a) => {
        return a.name !== attachment.name;
      });
      setTempAttachIDs(newAttachments);
      return;
    }
    if (Array.isArray(attachment)) {
      const reader = new FileReader();
      const readFile = (index, results = []) => {
        if (index >= attachment.length) {
          body.attachments = results;
          sendAttachments(chatWindowChosenItem.applicationID._id, body);
          return;
        }
        const file = attachment[index];
        reader.onload = (e) => {
          const result = { ...file };
          result.file = file;
          result.attachment = e.target.result;
          results.push(result);
          readFile(index + 1, results);
        };
        reader.readAsDataURL(file);
      };
      readFile(0);
    } else {
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = { ...attachment };
        result.file = attachment;
        result.attachment = event.target.result;
        body.attachments = [result];
        sendAttachments(chatWindowChosenItem.applicationID._id, body);
      };
      reader.readAsDataURL(attachment);
    }
  };

  return (
    <ChatWindow
      messageData={messagesData}
      onMessageSend={onMessageSend}
      headerItems={headerItems()}
      onBackClick={onBackClick}
      adminID={adminID}
      chatDataIsDownloading={chatDataIsDownloading}
      senderType="Support"
      customHeaderComponent={customHeaderComponent}
      attachIsUploading={attachIsUploading}
      attachIsDownloading={attachIsDownloading}
      onHistoryAttachClick={onHistoryAttachClick}
      headerButtonType={setHeaderButtonType()}
      onHeaderButtonClick={onHeaderButtonClick}
      onUploadAttachment={onUploadAttachment}
    />
  );
};

export default ChatWindowWrapper;
