import traderapi from "../../traderapi/api";
import F from '../fields.js';

class DocumentTemplateDataset {

    makeRecord(a) {
        return {
            id: F.createFieldReference( "documenttemplates", a._id),
            name: a.name,
            version: a.version,
            author: a.author,
            status: a.status,
            type: a.documentType,
            subject: a.documentSubject,
            body: F.createFieldList(a.documentBody.split(/\n/), {lines: 20})

        }
    }
    //
    list(params, cb) {
        traderapi.getDocumentTemplate((data) => {
            const items = data.data.map(this.makeRecord);
            cb({
                items,
                data
            });
            
        }, params);
    }
    //
    get(id, cb) {
        this.list({id}, cb)
    }
    //
    save(item, cb) {
        traderapi.saveApplication((data) => {
        cb(data);
    }, item);
    }
}

export default DocumentTemplateDataset;