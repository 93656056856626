import React from "react";
import traderapi from "../../traderapi/api";
import TableButton from "../../components/common/TableButton";
import F from '../fields.js';

class NewTraderReportTradingPlatform {
    makeRecord(a, headers, isPlatformTakion) {
        const res = {
            select: F.createFieldBoolean(false,{select: ['',' ADD '], inplaceEditor: true, selectCounter: true})
        };
        headers.forEach((h) => {
            res[h] = a[h];
        });
        if(res["Client: Date of Birth"]) {
            res["Client: Date of Birth"] = F.createFieldDate(res["Client: Date of Birth"]);
        }

        if(isPlatformTakion) {
            if (res['Actions']) {
                res['Actions'] = {
                    label: 'Actions',
                    render: (rowItem, context) => <TableButton data={a} context={context} />
                };
            }
        }

        return res;
    }

    list(params, cb) {
        traderapi.getReportTradingPlatform((data) => {
            const platform = params['trading platform'];
            const isPlatformTakion = platform && platform === 'Takion';
            const items = data?.report?.data.map((a) => {
                return this.makeRecord(a, data?.report?.headers, isPlatformTakion)
            });

            let header = [{id: 'select', colname: 'select', checkbox: true}, ...data?.report?.headers.map((h)=>{
                let data = {
                    id: h,
                    colname: h,
                    hidden: h === 'id'
                };

                if  (isPlatformTakion && h === 'Actions') {
                    data = {
                        ...data,
                        align: 'center',
                    }
                }

                return data;
            })];

            if (isPlatformTakion) {
                header = header.filter((h) => h.id !== 'Status');
            }

            cb({
                items,
                data,
                header
            });
        }, params);
    }
}

export default NewTraderReportTradingPlatform;
