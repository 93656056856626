import { useState, useEffect } from "react";
import { FormSelect } from "shards-react";
import cn from "classnames";

import { Dispatcher, Events } from "../../flux";
import Preloader from "../../utils/Preloader";

import "./PageNavigation.css";
import constants from "flux/constants";

const minCount = 25;

const pagesCountArray = [minCount, 50, 100, 150];

const PageNavigation = ({ pages, dataset, isLoading }) => {
  const quantity = pages.count || minCount;

  const [option, setOption] = useState(quantity);

  useEffect(() => {
    if (pages.quantity) {
      setOption(pages.quantity)
    }
  }, [pages.quantity])

  const dispatchPageAction = (quantity, startValue) => {
    Dispatcher.dispatch({
      actionType: Events.SETPAGINATIONVALUES,
      payload: {
        quantity,
        startValue,
        dataSetName: dataset,
        loadWithActiveFilters: true
      },
    });
  };

  const generatePages = (pages) => {
    if (!pages.total) {
      return null;
    }

    const onLeftPageArrowClick = () => {
      if (pages.page > 1) {
        dispatchPageAction(option, pages.page - 1);
      }
    };
  
    const onRightPageArrowClick = () => {
      if (pages.page * option < pages.total) {
        dispatchPageAction(option, pages.page + 1);
      }
    };

    const onRowsQuantityChange = ({ target }) => {
      setOption(Number(target.value));
      dispatchPageAction(Number(target.value), 1);
    };

    const currentStartValue = pages.quantity * (pages.page - 1) + 1;
    const currentEndValue = currentStartValue + pages.count - 1;

    const setDisabled = (type) => {
      switch (type) {
        case "left":
          return pages.page === 1;
        case "right":
          return (
            pages.quantity < option ||
            (pages.page - 1) * pages.quantity + pages.quantity >= pages.total
          );
        case "dropdown":
          return pages.quantity < option;
        default:
          return false;
      }
    };

    const selectPageQuantity = () => {
      return (
        <FormSelect
          className="pagination-select-dropdown"
          disabled={setDisabled("dropdown")}
          value={option}
          onChange={onRowsQuantityChange}
        >
          {pagesCountArray.map((item, index) => {
            return (
              <option value={item} key={index + item}>
                {item}&nbsp;rows per page
              </option>
            );
          })}
        </FormSelect>
      );
    };

    return (
      <div className="pages-navigation">
        {selectPageQuantity()}
        <h4>&nbsp;{currentStartValue}</h4>
        <h4>-</h4>
        <h4>{currentEndValue}</h4>
        <h4>&nbsp;of&nbsp;</h4>
        <h4>{pages.total}</h4>
        <h4
          className={cn("pag-left-arrow", "line-height-arrows", {
            disabled: setDisabled("left"),
          })}
          onClick={onLeftPageArrowClick}
        >
          <i className="material-icons">navigate_before</i>
        </h4>
        <h4
          className={cn("pag-right-arrow", "line-height-arrows", {
            disabled: setDisabled("right"),
          })}
          onClick={onRightPageArrowClick}
        >
          <i className="material-icons">navigate_next</i>
        </h4>
        {isLoading && <Preloader />}
      </div>
    );
  };

  if (dataset && constants.allowedPaginationedDatasets.includes(dataset))
    return generatePages(pages);
  return null;
};

export default PageNavigation;
