import React, { useContext } from "react";

import MarketDataPage from "./marketdata-page/MarketDataPage";
import { NewRequestContext } from "./NewRequestContext";

const EntitlementsBlock = () => {
  const {
    traderId,
    setEntitlementsData,
    requestedMarketData,
    extensions,
    setRequestedMarketData,
    providers, isNewApplication,
    requestedItems,
  } = useContext(NewRequestContext);

  return (
    <MarketDataPage
      currentAccount={traderId}
      setEntitlementsData={setEntitlementsData}
      extensions={extensions}
      marketBundles={providers?.MDProviders?.bundles}
      requestedMarketsData={requestedMarketData}
      setRequestedMarketData={setRequestedMarketData}
      requestsItems={requestedItems}
      marketdataProviders={providers?.MDProviders?.markets}
      tradingPlatforms={providers?.TPProviders}
      isNewApplication={isNewApplication}
    />
  );
};

export default EntitlementsBlock;
