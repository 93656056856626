import traderapi from "../../traderapi/api";
import F from '../fields.js';

class GroupDataset {

    makeRecord(a) {
        return {
            GENERAL: F.createSeparator('General'),
            id: F.createFieldReference("groups", a.agencyID, {readonly: true}),
            name: a.name,
            description: a.description,
            active: F.createFieldBoolean(a.active, {select: ["Inactive", "Active"]}),
    
            PARAMETERS: F.createSeparator('Parameters'),
            mailTemplates: F.createFieldList(a.mails?.templates.map((t)=>(`${t}`))),
            mailProperties: F.createFieldList(a.mails?.properties.map((t)=>(`${t.key} : ${t.value}`))),
            preset: F.createFieldReference("presets", a.presetID),
            
            WEBHOOK: F.createSeparator('Webhook'),
            Url: a.webhook?.url,
            ErrorUrl: a.webhook?.error,
            AttemptDelay:  a.webhook?.attemptDelay,
            AttemptCount: a.webhook?.attemptCount,
    
            SYSTEM: F.createSeparator('System'),
            aclAccessAll: a.acl?.access?.all,
            aclAccessGroup: a.acl?.access?.agency,
            aclAccessOwner: a.acl?.access?.owner,
            created: F.createFieldDateTime(a.created, {readonly: true}),
            createdBy: F.createFieldReference("users", a.createdBy,{readonly: true}),
        }
    }
    //
    list(params, cb) {
        traderapi.getGroups((data) => {
            const items = data.agencies.map(this.makeRecord);
            cb({
                items,
                data
            });
            
        }, params);
    }
    //
    get(id, cb) {
        this.list({clientID: id}, cb)
    }
    //
    save(item, cb) {
    traderapi.saveApplication((data) => {
        cb(data);
    }, item);
    }
}

export default GroupDataset;