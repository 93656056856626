const TOAST_TYPE_INFO = 'INFO';
const TOAST_TYPE_WARNING = 'WARNING';
const TOAST_TYPE_ALERT = 'ALERT';


export {
  TOAST_TYPE_WARNING,
  TOAST_TYPE_INFO,
  TOAST_TYPE_ALERT,
}
