import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'shards-react';
import './DropdownMenu.css';


const DropdownMenuCom = ({toggleItem, menuItems, itemClickedCB}) => {
    const [dropdownOpened, setDropdownOpened] = useState(false);

    const onItemClickCallBack = (itemClicked) => {
        itemClickedCB(itemClicked)
    }

    const renderDropdownItems = () => {
        if (typeof menuItems !== 'object') {
            return <DropdownItem onClick={() => onItemClickCallBack(menuItems)}>{menuItems}</DropdownItem>
        }
        if (menuItems.length > 0) {
            return menuItems.map(
                (item,index) => <DropdownItem key={index} onClick={() => onItemClickCallBack(item)}>{item}</DropdownItem>
            );
        }
    };

    return (
        <Dropdown
            open={dropdownOpened}
            toggle={() => setDropdownOpened((state) => !dropdownOpened)}
            className="dropdown-block d-table"
        >
            <DropdownToggle className="dropdown-toggle">
                {toggleItem}
            </DropdownToggle>
            <DropdownMenu>
                {renderDropdownItems()}
            </DropdownMenu>
        </Dropdown>
    )
}

export default DropdownMenuCom;