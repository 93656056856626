import React from 'react'
import { FormCheckbox } from "shards-react";

const Checkbox = ({
    item,
    checked,
    data,
    setTableData,
    tableItems,
    activeCheckboxId,
    counter,
    allChecked,
    version,
    type
  }) => {
  const { id, select } = item;

  const getNewList = (selector) => {
    let isFirstElement = false;

    return data.items.map((i) => {
      if ((i.id === activeCheckboxId || i.id === id) && activeCheckboxId !== id) {
        isFirstElement = !isFirstElement
        i.select.value = selector
      }

      if (isFirstElement) {
        i.select.value = selector
      }

      return i;
    });
  }

  const onSingleCheckbox = () => {
    const v = select;

    if(typeof v === 'object') {
      v.value = !v.value;
      counter[type] = (counter[type] || 0) + (v.value ? 1 : -1);

      allChecked[type] = counter[type] > 0 && counter[type] === data.items.length;

      setTableData({
        activeCheckboxId: id,
        version: version + 1,
        counter: {...counter},
        allChecked: {...allChecked}
      });
    }
  }

  const onMultiCheckboxes = (e) => {
    let newItems = tableItems;

    if (e.shiftKey) {
      e.stopPropagation();
      e.preventDefault();

      if (select?.value) {
        newItems = getNewList(false);

        setTableData({
          filteredItems: newItems,
          activeCheckboxId: id,
          data: {
            ...data,
            items: newItems
          },
        });

        return;
      }

      if (!counter?.select) {
        let isLastElement = false;

        newItems = data.items.map((i) => {
          if (i.id === id) {
            isLastElement = true
            i.select.value = true
          }

          if (!isLastElement) {
            i.select.value = true
          }

          return i;
        });
      } else {
        newItems = getNewList(true);
      }

      setTableData({
        filteredItems: newItems,
        activeCheckboxId: id,
        data: {
          ...data,
          items: newItems
        },
      });

      return;
    }

    onSingleCheckbox();
  }

  return (
    <span onClick={onMultiCheckboxes}><FormCheckbox checked={checked}/></span>
  );
}

export default Checkbox;
