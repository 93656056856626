import traderapi from "../../traderapi/api";

class StatisticsDataset {

    makeRecord(a) {
        return a;
    }
    //
    list(params, cb) {
        traderapi.getStats((data) => {
            data.length = 1;
            cb({
                items: data,
                data,
            });
            
        }, params);
    }
}

export default StatisticsDataset;