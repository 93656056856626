import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import axios from "axios";

import {layouts, PrivateRoute} from "./routes";
//import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import traderapi from "./traderapi/api";

import "./App.css";
import Toaster from "./components/toaster/Toaster";
import {ModalContextProvider} from "./components/modal/ModalContext";

axios.interceptors.request.use(
  (request) => {
    request.headers["Authorization"] = `Bearer ${traderapi.getToken()}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  if (!axios.isCancel(error) && error.response && error.response.status === 401) {
    const redirect = !error.response.config.url.endsWith("login");
    traderapi.logout(redirect);
  }
  return Promise.reject(error);
});

//Dispatcher.dispatch({actionType: Events.RELOADDATA});

const buildRoutes = routes => {
  return routes
    .filter(route => !(route.show === false))
    .map((route, key) => {
      return (
        <Route
          key={key}
          path={route.path}
          component={props => (
            <route.component {...props} {...route.options}>
              {route.childRoutes && (
                <Switch>
                  {buildRoutes(route.childRoutes)}
                </Switch>
              )}
            </route.component>
          )}/>
      )
    })
}

const buildlayoutPaths = routes => {
  return routes.reduce((paths, route)=>{
    if(route.childRoutes){
      return [...paths, route.path, ...buildlayoutPaths(route.childRoutes)]
    }
    return [...paths, route.path]
  }, [])
}

const router = () => {
  return (
    <>
      <ModalContextProvider>
      <Toaster/>
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <Switch>
          {layouts.map((layout, key) => {
            const RouteComponent = layout.unPrivate ? Route : PrivateRoute
            return (
                    <RouteComponent
                    key={key}
                    path={buildlayoutPaths(layout.routes)}
                    exact={layout.exact}
                    component={(props) => (
                      <layout.component {...props}>
                        {buildRoutes(layout.routes)}
                      </layout.component>
                    )}
                  />
            )
          })}
        </Switch>
      </Router>
      </ModalContextProvider>
    </>
  );
};

export default router;
