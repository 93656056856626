import traderapi from "../../traderapi/api";

class ReportAnalyticsAll {
   
    makeRecord(a) {
        const res = {
            "RegId": null,
            "CurrentRegState": null,
            "FirstName": null,
            "LastName": null,
            "Email": null,
            "RegCreatedAt":null,
            "RegUpdatedAt": null,
            "TradingProfile":null,
            "Country": null,
            "Citizenship": null,
            "AccountType": null,
            "TradingPlatform": null,
            "AppId":null,
            "AppState": null,
            "AppStateUpdatedAt": null,
            "AppCreatedAt":null,
            "AppUpdatedAt": null,
            "MarketingParams": null,
            ...a
        };

        res["MarketingParams"] = JSON.stringify(a.MarketingParams);

        return res;
    }
    //
    list(params, cb) {
        traderapi.getReportAnalyticsAll( (data) => {
            const items = data.map(this.makeRecord);
            cb({
                items,
                data,
            });
        }, params);
    }
}

export default ReportAnalyticsAll;