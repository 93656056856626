import React from "react";
import Login from "../components/auth/Login";
import './Auth.css';


const AuthLayout = () => (
  <div className="auth-wrapper">
    <div className="auth-inner">
      <Login></Login>
    </div>
  </div>
);

export default AuthLayout;
