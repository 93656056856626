import React from "react";
import { utilityFunctions } from "../../../../utils/utilityFunctions";

const MarketDataItem = ({ title, applicationIsBlocked, onMarketStatusChange, primaryEntitlements, entitlements, marketItems }) => {

  const renderMarkets = () => {
    const onCheckboxChange = (e, reference, depRef) => {
        const {name, checked} = e.target;
        onMarketStatusChange(name, checked, reference, depRef)
    }
    const setColorStatus = (name) => {
        if (!primaryEntitlements?.includes(name) && entitlements?.includes(name))
            return "added-item";
        if (primaryEntitlements?.includes(name) && !entitlements?.includes(name))
            return "removed-item";
    }
  
    return marketItems
      ?.filter((m) => m.group === title)
      .map((item, inx) => {
        return (
          <div className={`market-item ${setColorStatus(item.name)}`} key={inx}>
            <input
              checked={entitlements?.includes(item.name)}
              type="checkbox"
              name={item.name}
              disabled={applicationIsBlocked}
              onChange={(e) => onCheckboxChange(e, item.reference, item.dependencyRefs)}
            />
            <span className="market-checkmark"></span>
            <span>{item.name} - </span>
            <span>&nbsp;{utilityFunctions.currencyFormater().format(item.price)}</span>
          </div>
        );
      });
  };

  if (marketItems?.some(m=> m.group === title)) return (
      <div className="marketdata-item-block">
        <div className="market-title">{title}</div>
        <div className="market-body">{renderMarkets()}</div>
      </div>
  );
  return null
};

export default MarketDataItem;
