import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, FormCheckbox, Button } from "shards-react";
import momentTZ from "moment-timezone";
import cn from "classnames";

import { Store, Dispatcher, Events } from "../flux";
import EditApplication from "./EditApplication";
import DatasetToolbox from "../components/dataset-toolbox/DatasetToolbox";
import TableModal from "../components/table-modal/TableModal";
import ColumnEditor from "./ColumnEditor";
import { utilityFunctions } from "../utils/utilityFunctions";
import constants from "../flux/constants";
import traderapi from "../traderapi/api";
import TableImageModal from "../components/table-modal/TableImageModal";
import Checkbox from "../components/common/Checkbox";
import { REQUEST_STATUSES } from "../constants/request";
import CreateRequestButton from "../components/request/CreateRequestButton";
import ChatWindowWrapper from "../components/chat-window/chat-window-wrapper/ChatWindowWrapper";
import RequestHeader from "../components/request/support-requests/requestHeader";
import PageNavigation from "components/page-navigation/PageNavigation";

import "../assets/table.css";
import "./Tables.css"
import "../assets/searchbar.css";

var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();

class Tables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
      data: null,
      disabledStatus: true,
      searches: '',
      search: '',
      header: [],
      visibleColumns: [],
      openedColumnEditor: false,
      defaultHeaderWasSet: false,
      openModal: false,
      isImageModalVisible: false,
      modalImage: null,
      dataModal: {},
      version: 0,
      counter: {},
      allChecked: {},
      activeCheckboxId: '',
      chatWindowChosenItem: null,
    };

    this.onNewSearch = this.onNewSearch.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.setHeaderDefaultState = this.setHeaderDefaultState.bind(this);
    this.onDataChanged = this.onDataChanged.bind(this);
    this.fieldToString = this.fieldToString.bind(this);
    this.onLoadElement = this.onLoadElement.bind(this);
    this.toggleColumnEditor = this.toggleColumnEditor.bind(this);
    this.setDisabledStatus = this.setDisabledStatus.bind(this);
    this.updateHeaderInStore = this.updateHeaderInStore.bind(this);
    this.refreshDataset = this.refreshDataset.bind(this);
    this.handleAllCheckboxes = this.handleAllCheckboxes.bind(this);
    this.submitReportAndDownload = this.submitReportAndDownload.bind(this);
    this.downloadSelectedReports = this.downloadSelectedReports.bind(this);
    this.getReportsByDate = this.getReportsByDate.bind(this);
  }

  componentDidMount() {
    Store.addSearchListener(this.onNewSearch);
    Store.addIsLoadingListener(this.isLoading);
    Store.resetDatasetListener(this.onDataChanged, true);
    Store.resetEmitter(true, Events.OPENREFERENCE, this.onLoadElement);
    Store.setActiveDataset(this.props.dataset);
    const sl = document.getElementById(`sidelink-${this.props.dataset}`);
    sl && sl.focus();
    Dispatcher.dispatch({ actionType: Events.SETWORKINGDATASET, payload: this.props.dataset });
  }

  componentDidUpdate() {
    const { header, defaultHeaderWasSet } = this.state;
    const { dataset } = this.props;

    if (Array.isArray(header) && !!header.length && !defaultHeaderWasSet) {
      const data = Store.getDataset(dataset);
      this.props.applyRefOnFirstRenderActiveTable?.();
      const defaultHeaderItems = data.defaultHeader;

      if (!!defaultHeaderItems) {
        const defaultHeader = header.map(h => {
          if (defaultHeaderItems.includes(h.colname)) return { ...h, hidden: false };
          return { ...h, hidden: true };
        })

        this.updateHeaderInStore(defaultHeader)
        this.setHeaderDefaultState(true)
      }
    }
    if (!Store.getMarketdataProviders()) {
      this.getMDProviders()
      this.getTradingPlatforms()
    }
  }

  componentWillUnmount() {
    Store.removeSearchListener(this.onNewSearch);
    Store.removeIsLoadingListener(this.isLoading);
    Store.resetDatasetListener(this.onDataChanged, false);
    Store.resetEmitter(false, Events.OPENREFERENCE, this.onLoadElement);
  }

  updateCounter() {
    if (this.state.data?.header) {
      const { header, items } = this.state.data;

      const cheader = header.filter((h) => h.checkbox);

      const counter = {};
      const allChecked = {};
      cheader.forEach((h) => {
        counter[h.id] = 0;
        items.forEach((i) => {
          if (i[h.id]?.value) {
            counter[h.id]++;
          }
        })

        allChecked[h.id] = counter[h.id] > 0 && counter[h.id] === items.length;
      });

      this.setState({ counter, allChecked });
    }
  }

  onLoadElement(payload) {
    const elurl = `/${payload.dataset}/${payload.id}`;
    if (this.props.history?.location.pathname !== elurl) {
      this.props.history?.push(elurl);
    }
  }

  isLoading() {
    this.setState({ loading: Store.getIsLoadingState() });
  }

  setHeaderDefaultState(currentState) {
    this.setState({ defaultHeaderWasSet: currentState })
  }

  formatter(value) {
    return value ? utilityFunctions.currencyFormater().format(value) : "0";
  };

  getMDProviders() {
    try {
      traderapi.getMarketDataProviders((response) => {
        Dispatcher.dispatch({
          actionType: Events.SET_MARKET_DATA_PROVIDERS,
          payload: { markets: response.marketdataproviders, bundles: response.bundle }
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  getTradingPlatforms() {
    try {
      traderapi.getTradingPlatform((response) => {
        Dispatcher.dispatch({
          actionType: Events.SET_TRADING_PLATFORMS,
          payload: response.tradingplatforms
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  onNewSearch() {
    const { dataset } = this.props;
    const { data } = this.state;
    const searches = this.splitSearch(Store.getSearchPattern(dataset));
    const newSearchValue = searches && searches.length && searches[0];
    const rawItems = data?.items || [];

    if (newSearchValue) {
      this.setState({ searches, filteredItems: rawItems });

    } else {
      const filteredItems = (!!searches) ? rawItems.filter((x) => {
        return this.filterRow(x, searches)
      }) : rawItems;

      if (filteredItems.length > 0) {
        Store.setSearchRecords(filteredItems.length);
      }

      this.setState({ searches, filteredItems });
    }
  }

  updateHeaderInStore(values) {
    Dispatcher.dispatch({
      actionType: Events.SET_HEADER,
      payload: { datasetName: this.props.dataset, values: values }
    });

    this.setState({ header: values })
  }

  handleAllCheckboxes(column) {
    const { data, allChecked, counter } = this.state;

    data.items.forEach((e) => {
      e[column].value = !allChecked[column];
    })
    allChecked[column] = !allChecked[column];
    counter[column] = allChecked[column] ? data.items.length : 0;

    this.setState({ allChecked: { ...allChecked } });
  }

  downloadSelectedReports(reports) {
    const keys = ['id', 'AML status', 'Updated At', 'Status', 'Actions'];
    const newReportList = reports.map((item) => utilityFunctions.removeUnnecessaryProps(item, keys));

    utilityFunctions.downloadReport(this.props.dataset, newReportList);
  }

  getReportsByDate(date, reportApi) {
    if (!reportApi) {
      return;
    }

    const params = {
      date
    };

    traderapi[reportApi]((res) => {
      const { success, report } = res;

      if (success) {
        this.downloadSelectedReports(report.data);
      }
    }, params);
  };

  submitReportAndDownload() {
    const { dataset } = this.props;
    const { data } = this.state;
    const selected = data.items.filter(e => e.select.value);
    const selectedIdList = selected.map(i => i.id);
    let reportUrl = '';
    let reportApi = '';

    const params = {
      applications: selectedIdList,
      options: {},
    };

    switch (dataset) {
      case 'reportTradeProAML':
        reportUrl = 'tradeProAML';
        reportApi = 'getReportTradeProAML';
        break;
      case 'report/Sterling/create':
        reportUrl = 'newAccountsSterling';
        reportApi = 'getNewAccountsSterling';
        break;
      case 'report/Sterling/requested':
        reportUrl = 'newAccountsSterling';
        reportApi = 'getNewAccountsSterling';
        params.options.status = 'created';
        break;
      case 'report/TradeZero/create':
        reportUrl = 'newAccountsTradeZero';
        reportApi = 'getNewAccountsTradeZero';
        break;
      case 'report/TradeZero/requested':
        reportUrl = 'newAccountsTradeZero';
        reportApi = 'getNewAccountsTradeZero';
        params.options.status = 'created';
        break;
      case 'report/DAS/create':
        reportUrl = 'newAccountsDAS';
        reportApi = 'getNewAccountsDAS';
        break;
      case 'report/DAS/requested':
        reportUrl = 'newAccountsDAS';
        reportApi = 'getNewAccountsDAS';
        params.options.status = 'created';
        break;
      case 'report/Takion/create':
      default:
        reportUrl = 'newAccountsTakion';
        reportApi = 'getNewAccountsTakion';
        break;
    }

    traderapi.sendReport((res) => {
      const { success, date } = res;

      if (success) {
        const newItems = data.items.filter((i) => !selectedIdList.includes(i.id));

        this.setState({
          filteredItems: newItems,
          counter: {},
          data: {
            ...data,
            items: newItems
          },
        })
        this.getReportsByDate(date, reportApi);
      } else {
        console.error(`Updating status failed`, data);
        alert(data.message);
      }
    }, params, reportUrl)
  };

  getHeader(data) {
    const { dataset } = this.props;
    let hm = [{ id: "#", colname: '#', align: 'center', mandatory: true }];

    if (constants.RequestsDatasetList.includes(dataset)) {
      hm = [];
    }

    if (data) {
      if (data.header?.length < 1) {
        hm = hm.concat(data.header);
      } else if (data.header?.length > 1) {
        if (data.header[0]?.id === "#") {
          return data.header;
        } else {
          return [...hm, ...data.header];
        }
      } else if (data.items && data.items.length > 0) {
        hm = hm.concat(Object.entries(data.items[0]).map(([key, value]) => {
          return ((value?.isSeparator ? null : { id: key, hidden: false, align: value?.align || '' }))
        }).filter((h) => !!h));
      }
    }
    // add colname
    const newHeader = hm.map(h => Object.assign(h, { colname: h.title || h.id }));

    return newHeader;
  }

  setDisabledStatus(value) {
    this.setState({
      disabledStatus: value
    });
    Dispatcher.dispatch({
      actionType: Events.TOGGLE_POPUP,
      payload: !value
    });
  }

  toggleColumnEditor(value) {
    this.setState({
      openedColumnEditor: value
    });
  }

  onDataChanged() {
    const { dataset } = this.props;
    const data = Store.getDataset(dataset);
    if (data && typeof data.page === 'undefined') {
      data.page = 1;
      data.total = data && !!data.items ? data.items.length : 0;
    }

    const header = this.getHeader(data);

    this.setState({ data, header }, () => {
      this.onNewSearch();
      this.updateCounter();

      if (!data.header) {
        Dispatcher.dispatch({
          actionType: Events.SET_HEADER,
          payload: { datasetName: dataset, values: header }
        });
      }
    });
  }

  generateHeaderRow() {
    const { data, header, searches, allChecked } = this.state;

    if (!header) {
      return null;
    }

    const searchColumns = {};
    (searches || []).forEach((sl) => {
      const si = sl.indexOf(':');
      if (si > 0) {
        searchColumns[sl.substring((sl.charAt(0) === '!') ? 1 : 0, si).toLowerCase()] = true;
      }
    });

    //
    return (
      <tr>
        {header.map((h, i) => {
          return (!h.hidden) ? (
            <th scope="col" onClick={() => this.onHeaderClick(h)} key={i}
              className={cn('border-0', {
                'search_pattern': searchColumns[h.colname.toLowerCase()],
                'chosen-header': data.sortValues[h.colname]?.active,
                [h.align]: h.align
              })}
            >
              {h.checkbox && data.items?.length ? (
                <FormCheckbox checked={!!allChecked[h.id]} onChange={() => this.handleAllCheckboxes(h.id)} />
              ) :
                this.generateHeaderCell(h, data.sortValues[h.colname])
              }
            </th>
          ) : null;
        })}
      </tr>
    );
  }

  onHeaderClick({ colname }) {
    const { dataset } = this.props;
    const { data } = this.state;
    const datasetData = Store.getDataset(dataset);
    const sortValue = data.sortValues[colname];

    if (!datasetData.supportSort || !sortValue) {
      return
    }

    let orderState;
    switch (sortValue?.order) {
      case 'asc':
        orderState = 'desc';
        break;
      case 'desc':
        orderState = 'asc';
        break;
      default:
        orderState = 'desc';
        break;
    }

    Dispatcher.dispatch({
      actionType: Events.SETSORT,
      payload: {
        field: colname,
        dataSetName: dataset,
        order: orderState,
        active: true
      }
    })
  }

  generateHeaderCell({ colname = {} }, { order, active = false } = {}) {
    if (!colname) {
      return '';
    }

    if (!active && order) {
      return <span className="sorting-title">{colname}<span className="hover-sorting-icon">&#8645;</span></span>
    }

    switch (order) {
      case 'asc':
        return <>{colname}&nbsp;&#8593;</>
      case 'desc':
        return <>{colname}&nbsp;&#8595;</>
      default:
        return colname;
    }
  }

  onClickRow(data) {
    const { dataset } = this.props;

    if (constants.NegateDatasetList.includes(dataset) || dataset === 'requests') {
      return;
    }
    if (dataset === 'requestsRisk' || dataset === 'requestsCustom') {
      this.setChatWindowItem(data);
      return;
    }
    if (constants.PBDatasetList.includes(dataset)) {
      this.setState({
        dataModal: data,
        openModal: true,
      })
    } else {
      Dispatcher.dispatch({
        actionType: Events.OPENREFERENCE,
        payload: { dataset, id: data.id.id }
      });
    }
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  getElipses(i, id) {
    return (
      <div className="table-index">
        <i className="show-editor material-icons" onClick={() => this.onClickRow(id)}>edit</i>
        <div className="show-index">{i}</div>
      </div>
    )
  }

  fieldToString(v) {
    const o = typeof v === 'object' && v !== null && !Array.isArray(v);

    if (o) {
      if (v.isReference) {
        return v.id || '';
      } else if (v.isEnum) {
        return v.value || '';
      } else if (v.isValue) {
        if (v.changeFormat)
          return this.formatter(v.value)
        return v.value || '';
      } else if (v.isDate) {
        return !!v.value ? momentTZ(v.value).tz("America/New_York").format('YYYY.MM.DD') : '';
      } else if (v.isDateTime) {
        return v.value ? utilityFunctions.tableTimeFormat(v.value) : '';
      } else if (v.isBoolean) {
        const select = v.select || ['false', 'true'];
        return v.value ? select[1] : select[0];
      } else if (v.isList) {
        return v.list?.length > 0 ? '✅' : '';
      } else if (v.isImages) {
        return v.images?.length > 0 ? '🖼' : '';
      } else if (v.isSeparator) {
        return '';
      }
    } else if (typeof v === 'undefined' || v === null) {
      return '';
    } else {
      return v.toString();
    }
  }

  splitSearch(s) {
    return (s.toLowerCase().match(/([^\s"]+)|"([^"]*)"/gi) || []).map(x => x.replace(/^"(.+(?="$))"$/, '$1'));
  }

  filterRow(row, searches) {
    return searches.every((sl) => {
      const si = sl.indexOf(':');

      // ":pattern" - search in all fields
      // "pattern"  - the same
      if (si <= 0) {
        sl = sl.slice(si + 1);
        return Object.values(row).some((x) => {
          const v1 = this.fieldToString(x) || '';
          return v1.toLowerCase().includes(sl);
        })
      } else {
        // "field:pattern" - search in exact field
        const keynameLC = sl.substring(0, si);

        if (keynameLC.charAt(0) === '!') {
          const value = row[Object.keys(row).find(k => k.toLowerCase() === keynameLC.substring(1))];
          const v1 = this.fieldToString(value) || '';
          const ss = sl.substring(si + 1);
          return (!ss && !!v1) || (ss && v1.toLowerCase().includes(ss));
        } else {
          const value = row[Object.keys(row).find(k => k.toLowerCase() === keynameLC)];
          const v1 = this.fieldToString(value) || '';
          return v1.toLowerCase().includes(sl.substring(si + 1));
        }
      }
    });
  }

  openInNewTab(url) {
    const anchor = document.createElement('a');

    Object.assign(anchor, {
      target: '_blank',
      href: url,
      rel: 'noopener noreferrer',
    })
      .click();
  }

  generateDataRows() {
    const {
      data,
      searches,
      header,
      filteredItems,
      activeCheckboxId,
      counter,
      allChecked,
      version
    } = this.state;
    const filteredHeaderEls = header.map(he => he.hidden ? null : he).filter(n => n);
    if (!data || !filteredItems?.some(i => i !== undefined && i !== null)) {
      return null;
    }

    const SR = (data, v, h) => {
      if (!!searches && searches.length > 0 && !!v) {
        const regex = new RegExp(searches.map((s) => {
          const si = s.indexOf(':');
          if (si > 0) {
            if (s.substring(s.charAt(0) === '!' ? 1 : 0, si) === h.toLowerCase()) {
              return s.substring(si + 1);
            }
            return undefined;

          } else if (si === 0) {
            return s.substring(1);
          } else {
            return s;
          }
        }).filter(x => !!x).join('|'), 'ig');
        const s = `<div>${(v.toString().replaceAll(regex, (r) => `<span class='search_pattern'>${r}</span>`))}</div>`;

        return htmlToReactParser.parse(s);
      }
      return utilityFunctions.addColumnsException(data, v, h);
    }

    const hasInProgress = filteredItems.some((item) => item?.status === REQUEST_STATUSES.INPROGRESS && item.assignee?.username === Store.getLoggedUser().username);
    const rid = this.getRandomInt(100000);
    const startingPageCountValue = data.page > 1 ? (data.paginationValues.quantity * (data.page - 1) + 1) : 1;
    const e = filteredItems.map((u, i) => {
      const activeRow = u?.status === REQUEST_STATUSES.INPROGRESS && u.assignee?.username === Store.getLoggedUser().username;
      const a = (
        <tr
          id={`row-${i}`}
          key={`row-${i}-${rid}`}
          className={`
            ${activeRow ? 'highlight' : ''}
            ${hasInProgress ? 'hasInProgress' : ''}
          `}
        >
          {filteredHeaderEls?.map((h, j) => {
            const v = u?.[h?.id];

            if (h.id === '#') {
              return (
                <td key={`index-${i}-${'-'}-${rid}`}>
                  {this.getElipses((i + startingPageCountValue), u)}
                </td>
              )
            }

            const fieldOptions = v;
            const isOptionTypeObject = fieldOptions && typeof fieldOptions === 'object';
            const isRef = isOptionTypeObject && fieldOptions.isReference;
            const isInplaceEditor = isOptionTypeObject && fieldOptions.inplaceEditor;
            const isBoolean = isOptionTypeObject && fieldOptions.isBoolean;
            let s = this.fieldToString(fieldOptions);

            const className = `table-row${isRef ? " object-reference" : ""}` + ((isInplaceEditor && isBoolean) ? " checkbox-cell" : "");
            const onClick = (isRef) ? (() => {
              if (fieldOptions.external) {
                const pathh = (typeof fieldOptions.reference === 'function') ? fieldOptions.reference() : fieldOptions.reference;
                const fulllink = `${pathh}/${fieldOptions.id}`;
                this.openInNewTab(fulllink);
              } else {
                Dispatcher.dispatch({
                  actionType: Events.OPENREFERENCE,
                  payload: { dataset: fieldOptions.reference, id: fieldOptions.id }
                })
              }
            }) : (isInplaceEditor ? null : () => this.onClickRow(u));

            //const h = header[j+1];

            return (
              <td key={`t-${i}-${j}-${rid}`} className={className} onClick={onClick}>
                {(() => {
                  if (fieldOptions?.render) {
                    return fieldOptions.render(filteredItems[i], this)
                  } else {
                    return (<>
                      {isInplaceEditor && isBoolean ? (
                        <Checkbox
                          item={u}
                          type={h.id}
                          activeItem={v}
                          checked={fieldOptions.value}
                          data={data}
                          counter={counter}
                          version={version}
                          allChecked={allChecked}
                          tableItems={filteredItems}
                          activeCheckboxId={activeCheckboxId}
                          setTableData={this.setTableData}
                        />
                      ) : (
                        SR(u, s, h.colname)
                      )}
                    </>)
                  }
                })()}
              </td>
            )
          })
          }
        </tr>
      );
      return a;

    });
    return e;
  }

  setTableData = (data) => {
    this.setState({
      ...data,
    }, () => this.updateCounter())
  }

  generateFilters() {
    // return this.state.data?.filters?.map((f)=>{
    //   return (
    //     <FilterBox value={f.value} onChange={f.onChange} isMulti options={f.values.map((e) => ({value: e, label: e}))}/>
    //   )
    // });
  };

  refreshDataset(softRefresh, skipCallback = false) {
    const { dataset, callback } = this.props;

    if (!softRefresh) {
      const data = null;

      this.setState({ data });
    }

    if (constants.TabsHeaderDatasetList.includes(dataset) && callback) {
      if (!skipCallback) {
        callback();
      }
    } else {
      Dispatcher.dispatch({ actionType: Events.RELOADDATA, payload: dataset });
    }
  }

  download() {
    Dispatcher.dispatch({ actionType: Events.DOWNLOAD, payload: { dataset: this.props.dataset } });
  }

  modalToggle = () => {
    this.setState({ openModal: !this.state.openModal });
  }

  getDatasetToolbox = (name) => {
    const { dataset } = this.props;
    let options;
    let className = '';

    if (constants.SubmittableHeaderDatasetList.includes(name)) {
      className = "report-datasetToolbox";
      options = {
        refresh: this.refreshDataset,
      }
    } else if (constants.TabsHeaderDatasetList.includes(name)) {
      options = {
        refresh: () => this.refreshDataset(true),
      }
    } else {
      options = {
        refresh: this.refreshDataset,
        // settings: () => {
        //   this.toggleColumnEditor(true)
        // },
        download: () => {
          this.download()
        }
      }
    }

    return (
      <DatasetToolbox
        className={className}
        dataset={dataset}
        options={options}
      />
    )
  }

  getSubmittedHeader = (c, name, reportStatus) => {
    const { counter } = this.state;
    const currentCounter = counter[c];
    let buttonText;

    switch (name) {
      case 'reportTradeProAML':
        buttonText = 'Download AML Report';
        break;
      case 'Sterling':
      case 'TradeZero':
      case 'DAS':
      case 'Takion':
        buttonText = `Account ${reportStatus === constants.reportStatuses.CREATE ? constants.reportStatuses.REQUESTED : 'created'}`;
        break;
      default:
        buttonText = 'Download';
        break;
    }

    return (
      <div key={c} className="new-accounts-box">
        <span className="new-accounts-info">{`Selected ${currentCounter || 0} ${currentCounter === 1 ? 'Application' : 'Applications'}:`}</span>
        <div>
          <Button
            theme="white"
            disabled={!currentCounter > 0}
            onClick={this.submitReportAndDownload}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    )
  }

  generateCreateRequestButton() {
    const { dataset } = this.props;
    if (constants.TabsHeaderDatasetList.includes(dataset) && dataset === "requests") { // TODO:make request windows for every type of requests. Now it works only for marketdata
      return <CreateRequestButton className="btn btn-white mx-2" type="button" />
    }
  }

  setChatWindowItem = (item) => {
    const { callback } = this.props;

    if (callback) {
      const action = item ? 'hide' : 'show';

      callback(action);
    }
    if (!!item) {
      if (item.id !== this.state.chatWindowChosenItem?.id && !!this.state.chatWindowChosenItem?.id) {
        Dispatcher.dispatch({ actionType: constants.Events.DETACH_REQUEST, payload: { requestID: this.state.chatWindowChosenItem?.id } });
        Dispatcher.dispatch({ actionType: constants.Events.DETACH_CHAT, payload: { requestID: this.state.chatWindowChosenItem?.id } });
      }
      if (this.state.chatWindowChosenItem?.id !== item.id) {
        Dispatcher.dispatch({ actionType: constants.Events.ATTACH_REQUEST, payload: { requestID: item.id } });
        Dispatcher.dispatch({ actionType: constants.Events.ATTACH_CHAT, payload: { requestID: item.id } });
      }
    } else {
      if (!!this.state.chatWindowChosenItem) {
        Dispatcher.dispatch({ actionType: constants.Events.DETACH_REQUEST, payload: { requestID: this.state.chatWindowChosenItem.id } });
        Dispatcher.dispatch({ actionType: constants.Events.DETACH_CHAT, payload: { requestID: this.state.chatWindowChosenItem.id } });
      }
    }
    this.setState({ chatWindowChosenItem: item })
  }

  handleBackClickChat() {
    this.setChatWindowItem(null)
    this.refreshDataset(true);
  }

  render() {
    const { dataset, location, headerTitle } = this.props;
    const {
      data,
      openedColumnEditor,
      disabledStatus,
      openModal,
      dataModal,
      countries,
      modalImage,
      isImageModalVisible,
      chatWindowChosenItem,
    } = this.state;
    const [reportName, reportStatus] = dataset && dataset.split('/');
    const requestsDataset = constants.RequestsDatasetList.includes(dataset) ? 'request-table' : '';
    const appsDataset = constants.ApplicationsDatasetList.includes(dataset) ? 'apps-table' : '';
    const currentName = reportName || dataset;
    let header = '';
    let pages = {};
    let singleHeader = '';
    if (data) {
      header = `${data.tableHeader} ${headerTitle ? headerTitle : ''}`;
      singleHeader = `${data.singleHeader || data.tableHeader}`;
      pages = { page: data.page || 0, count: data.items.length, total: data.total, quantity: data.paginationValues.quantity };
    }

    let currentItemToEdit;
    let waitingID = false;
    //
    const pathName = location?.pathname.replace(/\/+$/, "").split('/');
    if (pathName?.length > 1 && pathName.slice(0, -1).join('/').endsWith(dataset)) {
      // get the id from last part of path without leading slash
      const id = pathName[pathName.length - 1].trim();

      if (data && id) {
        currentItemToEdit = data.items.find(item => item.id.id === id) || data.singles.find(item => item.id.id === id);
        if (!currentItemToEdit) {
          waitingID = true;
          Dispatcher.dispatch({
            actionType: Events.OPENREFERENCE,
            payload: { dataset, id }
          });
        }
      }
    }

    if (!data || !data.hasData || waitingID) {
      return (
        <Container fluid className="main-content-container search_processor">
          <Row>
            <Col className="px-0">
              <Card small className="small-card">
                <CardHeader className="table-title">
                  <h3 className="m-0">{header || 'Loading'} . . .</h3>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      );
    }

    if (chatWindowChosenItem) {
      const isRequestTabsList = constants.TabsHeaderDatasetList.includes(dataset);
      const customHeaderComponent = isRequestTabsList 
        ? <RequestHeader
            cancelTokenSource={this.props.cancelTokenSource}
            data={chatWindowChosenItem}
            context={this}
          />
        : null;

      return (
        <ChatWindowWrapper
          onBackClick={() => { this.handleBackClickChat(); }}
          chatWindowChosenItem={chatWindowChosenItem}
          adminID={Store.getLoggedUser()?.username}
          customHeaderComponent={customHeaderComponent}
        />
      )
    }

    if (openedColumnEditor)
      return (
        <ColumnEditor
          toggleColumnEditor={this.toggleColumnEditor}
          updateHeaderInStore={this.updateHeaderInStore}
          header={this.state.header}
          pages={pages}
          dataset={dataset}
        />
      )

    if (!currentItemToEdit)
      return (
        <Container fluid className="main-content-container search_processor">
          <Row>
            <Col className="px-0">
              <Card small className="small-card">
                <CardHeader className={`border-bottom table-title ${requestsDataset}`}>
                  <h3 className="m-0">{header}</h3>
                  <div className="table-title-right">
                    {constants.SubmittableHeaderDatasetList.includes(currentName) && ['select'].map((c) => {
                      return this.getSubmittedHeader(c, currentName, reportStatus);
                    })}
                    {this.generateCreateRequestButton()}
                    {this.getDatasetToolbox(currentName)}
                    <PageNavigation
                      pages={pages}
                      dataset={this.props.dataset}
                      isLoading={this.state.loading ?? false}
                    />
                  </div>
                </CardHeader>
                <CardBody className={`tables-card-body p-0 pb-3 ${requestsDataset} ${appsDataset}`} id="card-body">
                  <table className="table mb-0">
                    <thead className="bg-light table-header-row">
                      {this.generateHeaderRow()}
                    </thead>
                    <tbody>
                      {this.generateDataRows()}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <TableModal
            openModal={openModal}
            dataModal={dataModal}
            modalToggle={this.modalToggle}
            refreshCallback={this.refreshDataset}
          />
          <TableImageModal
            close={() => { this.setState({ isImageModalVisible: false }) }}
            image={modalImage}
            isVisible={isImageModalVisible}
          />

        </Container>
      );
    return (
      <EditApplication
        disabledStatus={disabledStatus}
        setDisabledStatus={this.setDisabledStatus}
        header={singleHeader}
        country={countries}
        dataset={dataset}
        currentItemToEdit={currentItemToEdit}
      />
    )
  }
}

export default Tables;
