import completeImg from "../../images/marketdata/complete.svg"
import traderapi from "../../traderapi/api";
import {REQUEST_STATUSES} from "../../constants/request";
import {pushToast} from "../toaster/helper";

const Complete = ({context, request}) => {

  const completeProcess = async (e) => {
    e.stopPropagation();

    const success = await traderapi.setRequestStatus(request.id, REQUEST_STATUSES.RESOLVE)
    if(success) {
      context.refreshDataset('softRefresh');
      // completeProcessCb(type)
      pushToast(`Request for ${request.applicationID.reference.client} was resolved`)
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <img
        onClick={completeProcess}
        src={completeImg}
        alt="Start"
      />
    </div>
  )
}

export default Complete;
