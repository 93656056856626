import traderapi from "../../traderapi/api";
import { utilityFunctions } from "../../utils/utilityFunctions";
import F from '../fields.js';

class MarketDataProviderDataset {

    makeRecord(a) {
        return {
            id: F.createFieldReference("marketdataproviders", a._id),
            name: F.createField(a.name, { readonly: true}),
            reference: a.reference,
            group: a.group,
            bundle: F.createFieldList(a.bundle.map((b)=>(`${b}`))),
            price: utilityFunctions.currencyFormater().format(a.price),
            dependencyRefs: F.createFieldList(a.dependencyRefs.map((b)=>(`${b}`))),
            tradingPlatformRefs: F.createFieldList(a.tradingPlatformRefs.map((b)=>(`${b}`))),
        }
    }
    //
    list(params, cb) {
        traderapi.getMarketDataProviders((data) => {
            const items = data.marketdataproviders.map(this.makeRecord);
            cb({
                items,
                data
            });
            
        }, params);
    }
    //
    get(id, cb) {
        this.list({id}, cb)
    }
    //
    save(n, o, cb) {
        const mdp = {
            name: n.name.value,
            reference: n.reference,
            group: n.group,
            bundle: n.bundle.list,
            price: n.price,
            dependencyRefs: n.dependencyRefs.list,
            tradingPlatformRefs: n.tradingPlatformRefs.list,
        };
        traderapi.saveEntity('static/marketdataprovider',(data) => {
            if(data.success) {
                cb(data, n, o);
            } else {
                console.error(`Updating marketdataprovider failed`, data);
                alert(data.message);
            }
        }, mdp);
    }
}

export default MarketDataProviderDataset;