import { useEffect, useState } from 'react';
import { REQUEST_STATUS_ACTION, REQUEST_STATUSES, REQUEST_ACTION } from "../../../constants/request";
import traderapi from "../../../traderapi/api";
import AppliedValue from '../applied-value/applied-value';
import { utilityFunctions } from "../../../utils/utilityFunctions";
import { Store, Dispatcher, Events } from "../../../flux";
import { REQUEST_BUTTON_TABS_SYNC } from "../../datasetTabs/tabsListData";
import constants from "../../../flux/constants";

import './requestHeader.css'

const RequestHeader = ({ data, context, cancelTokenSource }) => {
  const [activeItem, setActiveItem] = useState(data);
  const [isChangedAppliedValue, setIsChangedAppliedValue] = useState(null);
  const headerRightList = [
    "Request ID",
    "Requested On",
    "Updated On",
    "Time to resolution",
    "Who's Doing",
  ];
  const headerLeftList = [
    'Category',
    'Requested',
    'Current',
    'Applied',
    'Type',
    'Status',
  ];
  const headerLeftList2 = [
    'Full name',
    'Trader ID',
    'Sender name',
    'Account Type',
    'Platform',
    'DB',
    'Updated On',
    'Balance',
  ];

  useEffect(() => {
    setActiveItem(data);

    if (data) {
      Store.setActiveRequestID(data.id);
    }

    return () => {
      Store.setActiveRequestID(null);
    };
  }, [data]);


  useEffect(() => {
    const updateActiveItem = () => {
      setActiveItem((oldVal) => {
        const newVal = Store.getDataset(context.props.dataset).items?.find(item => item.id === oldVal.id)
        return newVal || oldVal;
      });
    };

    Store.on(constants.Events.DATASETUPDATED, updateActiveItem);

    return () => {
      Store.off(constants.Events.DATASETUPDATED, updateActiveItem);
    };
  }, [context.props.dataset]);

  const getRequestHeader = (headerItemList) => {
    const resultItems = {};

    if (headerItemList && activeItem) {
      headerItemList.forEach((item) => {
        const currentData = activeItem[item];

        if (currentData) {
          if (currentData.hasOwnProperty('render')) {
            resultItems[item] = currentData.render(activeItem, context);
          } else if (currentData.hasOwnProperty('value')) {
            resultItems[item] = currentData?.value;
          } else {
            resultItems[item] = currentData;
          }
        }
      });
    }

    return (
      <ul className="request-header-box">
        {Object.keys(resultItems)?.map((item) => (
          <li key={item} className="request-header-item">
            {<span className="request-header-key-column">{item}:</span>}
            {<span className="request-header-value-column">{utilityFunctions.addColumnsException(activeItem, resultItems[item], item)}</span>}
            {item === 'Applied' && activeItem.status === REQUEST_STATUSES.INPROGRESS &&
              <AppliedValue setIsChangedAppliedValue={setIsChangedAppliedValue} data={activeItem} />}
          </li>
        ))}
      </ul>
    );
  };

  const clickHandler = async (e, item) => {
    //e.stopPropagation();
    if (item === REQUEST_ACTION.RESOLVE.command || item === REQUEST_ACTION.PENDING_EOD.command) {
      if (isChangedAppliedValue && isChangedAppliedValue !== null) {
        await traderapi.setRequestAppliedValue({ id: activeItem.id, value: isChangedAppliedValue });
      }
    }
    const success = await traderapi.setRequestStatus(activeItem.id, item, true);
    
    if (success) {
      const conditionValue = REQUEST_BUTTON_TABS_SYNC[item];
      Dispatcher.dispatch({
        actionType: Events.RELOADTABLEDATA,
        payload: {
          datasetName: context.props.dataset,
          params: {
            conditions: conditionValue ? [conditionValue] : [],
            type: context.props.tabSetName.type || "RISKSETTING",
            cancelTokenSource: cancelTokenSource,
          }
        }
      });
    }
  }

  const getRequestActions = () => {
    const { status } = activeItem;
    const actionItems = REQUEST_STATUS_ACTION[status];
    return (
      <ul className="request-action-box">
        {actionItems.filter((item) => !item.noaction).map((item) => (
          <li
            key={item.command}
            className="request-action-item"
            onClick={(e) => clickHandler(e, item.command)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="request-header-wrapper">
      <div className="request-header-info">
        <div className="request-header-info-left">
          {getRequestHeader(headerLeftList)}
          {getRequestHeader(headerLeftList2)}
        </div>
        <div className="request-header-info-right">
          {getRequestHeader(headerRightList)}
        </div>
      </div>
      {getRequestActions()}
    </div>
  )
};

export default RequestHeader;

