import React, {useEffect, useRef} from 'react'
import {Dispatcher, Events} from "../../flux";
import {TOAST_TYPE_ALERT, TOAST_TYPE_WARNING} from "./types";

const Toast = ({index, data}) => {
  const {message, type, timeout} = data

  const closeSelf = () => {
    Dispatcher.dispatch({
      actionType: Events.REMOVE_TOAST,
      payload: index
    });
  }

  let toastClass = 'info'
  // let header = 'Info'
  let icon = 'info'
  if(type === TOAST_TYPE_WARNING){
    toastClass = 'warning'
    // header = 'Warning'
    icon = 'warning'
  }
  if(type === TOAST_TYPE_ALERT){
    toastClass = 'report'
    // header = 'Error'
    icon = 'error'
  }

  const timeOut = useRef(null)
  useEffect(() => {
    clearTimeout(timeOut.current)
    timeOut.current = setTimeout(() => {
      closeSelf()
    }, timeout ? timeout : 5000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className={`toast ${toastClass}`}>
      <div className="toast-header">
        <i className="material-icons">{icon}</i>
        <strong className="mr-auto">{message}</strong>
        <button type="button"
                className="ml-2 mb-1 close"
                data-dismiss="toast"
                aria-label="Close"
                onClick={() => {
                  closeSelf()
                }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {/* <div className="toast-body">
        {message}
      </div> */}
    </div>
  )
}

export default Toast;
