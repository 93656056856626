import {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";
import { Dispatcher, Events, Store } from "../../../flux";

const SidebarNavItem = ({ item, formIsEditting, onToggle }) => {
  const [badgesData, setBadgesData] = useState(Store.getBadgesData());

  useEffect(() => {
    const badgeDataOnChange = () => {
      setBadgesData(Store.getBadgesData());
    };
    Store.addChangeBadgesListener(badgeDataOnChange);

    return Store.removeChangeBadgesListener(badgeDataOnChange);
  }, []);

  return (
    <NavItem>
      <NavLink
        tag={(props) => <RouteNavLink {...props} />}
        onClick={(e) => {
          if(onToggle) {
            e.preventDefault();
            onToggle();
          }
          if (formIsEditting) {
            e.preventDefault();
            Dispatcher.dispatch({actionType: Events.SET_REDIRECTED_URL, payload: item.to});
            Dispatcher.dispatch({actionType: Events.TOGGLE_DIALOG_WINDOW, payload: true});
          }
        }}
        to={item.to}
        id={`sidelink-${item.to.substring(1)}`}
        active={item.active}
        disabled={item.disabled}
      >
          {item.htmlBefore && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
            />
          )}
          {item.title && <span>{item.title}</span>}
          {item.htmlAfter && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
            />
          )}

        {item.badges && (
          <div className={"d-flex ml-auto"}>
            <item.badges badgesData={badgesData}/>
          </div>
        )}
      </NavLink>
    </NavItem>
  );
}

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
