import "./sidebar-nav-items.css";
import SidebarBadge from "../components/common/SidebarBadge";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
      title: "Applications",
      to: " ",
      htmlBefore: '<i class="material-icons">timeline</i>',
      htmlAfter: "",
      active: false,
      toggle: true,
      toggleable: true,
      disabled: true,
      items: [
        {
          title: "Need to review",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">vertical_split</i>',
          to: "/v/ops",
        },
        {
          title: "Waiting response",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">vertical_split</i>',
          to: "/v/trader",
        },
        {
          title: "All",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">vertical_split</i>',
          to: "/a",
        },
        {
          title: "Incomplete",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">vertical_split</i>',
          to: "/r",
        },
      ]
    },

    {
      title: "Performance bond",
      htmlBefore: '<i class="material-icons">request_page</i>',
      htmlAfter: '<i class="material-icons sidebar-icons">list</i>',
      to: " ",
      active: false,
      show: true,
      toggleable: true,
      toggle: true,
      items: [
        {
          title: "Pending",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">pause_circle_outline</i>',
          to: "/pb/pending",
          badges: ({badgesData}) => {
            return <SidebarBadge>{badgesData?.wires?.pending}</SidebarBadge>
          },
        },
        {
          title: "Claimed",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">flag</i>',
          to: "/pb/claimed",
          name: 'claimed',
          badges: ({badgesData}) => {
            return <SidebarBadge>{badgesData?.wires?.claimed}</SidebarBadge>
          },
        },
        {
          title: "Received",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">paid</i>',
          to: "/pb/received",
          // badges: ({badgesData}) => {
          //   return <SidebarBadge>{badgesData?.wires?.received}</SidebarBadge>
          // },
        },
      ]
    },

    {
      title: "TradePro AML",
      htmlBefore: '<i class="material-icons">summarize</i>',
      to: "/report/tradeProAML",
      badges: ({badgesData}) => {
        return <SidebarBadge>{badgesData?.aml?.tradepro}</SidebarBadge>
      },
    },

    {
      title: "Sterling",
      htmlBefore: '<i class="material-icons">summarize</i>',
      htmlAfter: '<i class="material-icons sidebar-icons">list</i>',
      to: " ",
      active: false,
      toggleable: true,
      toggle: true,
      items: [
        {
          title: "Accounts To Create",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/na/sterlingCreate",
          badges: ({badgesData}) => {
            return <SidebarBadge>{badgesData?.newtrader?.sterling?.notsent}</SidebarBadge>
          },
        },
        {
          title: "Accounts To Requested",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/na/sterlingRequested",
          badges: ({badgesData}) => {
            return <SidebarBadge>{badgesData?.newtrader?.sterling?.creationRequested}</SidebarBadge>
          },
        }
      ]
    },

    {
      title: "DAS",
      htmlBefore: '<i class="material-icons">summarize</i>',
      htmlAfter: '<i class="material-icons sidebar-icons">list</i>',
      to: " ",
      active: false,
      toggleable: true,
      toggle: true,
      items: [
        {
          title: "Accounts To Create",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/na/DASCreate",
          badges: ({badgesData}) => {
            return <SidebarBadge>{badgesData?.newtrader?.das?.notsent}</SidebarBadge>
          },
        },
        {
          title: "Accounts Requested",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/na/DASRequested",
          badges: ({badgesData}) => {
            return <SidebarBadge>{badgesData?.newtrader?.das?.creationRequested}</SidebarBadge>
          },
        }
      ]
    },

    // {
    //   title: "Takion",
    //   htmlBefore: '<i class="material-icons">summarize</i>',
    //   htmlAfter: '<i class="material-icons sidebar-icons">list</i>',
    //   to: " ",
    //   active: false,
    //   toggleable: true,
    //   toggle: true,
    //   items: [
    //     {
    //       title: "Accounts To Create",
    //       htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
    //       to: "/na/takionCreate",
    //       badges: ({badgesData}) => {
    //         return <SidebarBadge>{badgesData?.newtrader?.takion?.notsent}</SidebarBadge>
    //       },
    //     }
    //   ]
    // },

    {
      title: "TradeZero",
      htmlBefore: '<i class="material-icons">summarize</i>',
      to: " ",
      active: false,
      toggleable: true,
      toggle: true,
      items: [
        {
          title: "Accounts To Create",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/na/tradeZeroCreate",
          badges: ({badgesData}) => {
            return <SidebarBadge>{badgesData?.newtrader?.tradezero?.notsent}</SidebarBadge>
          },
        },
        {
          title: "Accounts Requested",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/na/tradeZeroRequested",
          badges: ({badgesData}) => {
            return <SidebarBadge>{badgesData?.newtrader?.tradezero?.creationRequested}</SidebarBadge>
          },
        },
      ]
    },

    {
      title: "Market data",
      htmlBefore: '<i class="material-icons">summarize</i>',
      to: "/request/today",
      badges: ({badgesData}) => {
        return <SidebarBadge>{badgesData?.requests?.MARKETDATA?.today}</SidebarBadge>
      },
    },
    {
      title: "Accounts to enable",
      htmlBefore: '<i class="material-icons">waterfall_chart</i>',
      to: "/request/enable-today",
      badges: ({ badgesData }) => {
        const source = "/request/enable-today";
        return <>
          <SidebarBadge to={source} datasetStates={{datasetName: 'requestsEnable', params:{conditions:['immediately'], statusCode:['unresolved']}}} color="red">{badgesData?.requests?.TRADING_ACCESS?.ENABLE?.immediately}</SidebarBadge>
          <SidebarBadge to={source} datasetStates={{datasetName: 'requestsEnable', params:{conditions:['Today'], statusCode:['unresolved']}}} color="green">{badgesData?.requests?.TRADING_ACCESS?.ENABLE?.today}</SidebarBadge>
        </>
      },
    },
    {
      title: "Accounts to disable",
      htmlBefore: '<i class="material-icons">waterfall_chart</i>',
      to: "/request/disable",
      badges: ({ badgesData }) => {
        const source = "/request/disable";
        return <>
          <SidebarBadge to={source} datasetStates={{datasetName: 'requestsDisable', params:{conditions:['immediately'], statusCode:['unresolved']}}} color="red">{badgesData?.requests?.TRADING_ACCESS?.DISABLE.immediately}</SidebarBadge>
          <SidebarBadge to={source} datasetStates={{datasetName: 'requestsDisable', params:{conditions:['Today'], statusCode:['unresolved']}}} color="green">{badgesData?.requests?.TRADING_ACCESS?.DISABLE.today}</SidebarBadge>
          <SidebarBadge to={source} datasetStates={{datasetName: 'requestsDisable', params:{conditions:['eod'], statusCode:['unresolved']}}} color="blue">{badgesData?.requests?.TRADING_ACCESS?.DISABLE.pendingEod}</SidebarBadge>
        </>
      },
    },

    {
      title: "Support Requests",
      htmlBefore: '<i class="material-icons">summarize</i>',
      htmlAfter: '<i class="material-icons sidebar-icons">list</i>',
      to: " ",
      active: false,
      toggleable: true,
      toggle: true,
      items: [
        {
          title: "Risk Settings",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/request/risk",
          badges: ({badgesData}) => {
            const source = "/request/risk";
            let greenBadgeValue = (badgesData?.requests?.RISKSETTING?.incoming || 0)
              + (badgesData?.requests?.RISKSETTING?.inprogress || 0)
              + (badgesData?.requests?.RISKSETTING?.onhold || 0)
            return <>
            <SidebarBadge to={source} datasetStates={{datasetName: 'requestsRisk', params:{conditions:['onhold', 'inprogress', 'incoming']}}} color="green">{greenBadgeValue}</SidebarBadge>
            <SidebarBadge to={source} datasetStates={{datasetName: 'requestsRisk', params:{conditions:['pendingEod']}}} color="blue">{badgesData?.requests?.RISKSETTING?.pendingEod}</SidebarBadge>
            <SidebarBadge to={source} datasetStates={{datasetName: 'requestsRisk', params:{conditions:['followUp']}}}>{badgesData?.requests?.RISKSETTING?.followUp}</SidebarBadge>
          </>
          },
        },
        {
          title: "Custom",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/request/custom",
          badges: ({ badgesData }) => {
            const source = "/request/custom";
            let greenBadgeValue = (badgesData?.requests?.INQUIRY?.incoming || 0)
              + (badgesData?.requests?.INQUIRY?.inprogress || 0)
              + (badgesData?.requests?.INQUIRY?.onhold || 0)
            return <>
              <SidebarBadge to={source} datasetStates={{datasetName: 'requestsCustom', params:{conditions:['onhold', 'inprogress', 'incoming']}}} color="green">{greenBadgeValue}</SidebarBadge>
              <SidebarBadge to={source} datasetStates={{datasetName: 'requestsCustom', params:{conditions:['followUp']}}} >{badgesData?.requests?.INQUIRY?.followUp}</SidebarBadge>
            </>
          },
        }
      ]
    },

    {
      title: "Configuration",
      htmlBefore: '<i class="material-icons">settings</i>',
      htmlAfter: '<i class="material-icons sidebar-icons">list</i>',
      to: " ",
      active: false,
      toggleable: true,
      toggle: false,
      items: [
        {
          title: "Presets",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">tune</i>',
          to: "/presets",
        },
        {
          title: "CalculationRates",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">speed</i>',
          to: "/calculationrates",
        },
        {
          title: "CountryRates",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">equalizer</i>',
          to: "/countryrates",
        },
        {
          title: "DocumentTemplates",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/documenttemplates",
        },

        {
          title: "TradingPlatforms",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">account_balance</i>',
          to: "/tradingplatforms",
        },
        {
          title: "MarketDataBundles",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">source</i>',
          to: "/marketdatabundles",
        },
        {
          title: "MarketDataProviders",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">show_chart</i>',
          to: "/marketdataproviders",
        },
        {
          title: "Countries",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">public</i>',
          to: "/countries",
        },
      ]
    },

    {
      title: "User management",
      htmlBefore: '<i class="material-icons">manage_accounts</i>',
      htmlAfter: '<i class="material-icons sidebar-icons">list</i>',
      to: " ",
      active: false,
      toggleable: true,
      toggle: false,
      items: [
        {
          title: "Groups",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">groups</i>',
          to: "/groups",
        },
        {
          title: "Users",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">people</i>',
          to: "/users",
        },
      ]
    },

    {
      title: "Postchecks",
      htmlBefore: '<i class="material-icons">summarize</i>',
      htmlAfter: '<i class="material-icons sidebar-icons">list</i>',
      to: " ",
      active: false,
      toggleable: true,
      toggle: false,
      items: [
        {
          title: "Duplicate name",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/report/dn",
        },
        {
          title: "Duplicate GovID",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/report/dg",
        },
        {
          title: "Duplicate email",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/report/de",
        },
        {
          title: "Expiring ID *30d",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/report/ed",
        },

      ]
    },

    //
    {
      title: "Exports",
      htmlBefore: '<i class="material-icons">summarize</i>',
      htmlAfter: '<i class="material-icons sidebar-icons">list</i>',
      to: " ",
      active: false,
      toggleable: true,
      toggle: false,
      items: [
        {
          title: "Onboarding analytics",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/report/aa",
        },
        {
          title: "Usage statistics",
          htmlBefore: '<i class="material-icons sidebar-sub-icons">reorder</i>',
          to: "/report/stats",
        },
      ]
    },

    {
      title: "User profile",
      htmlBefore: '<i class="material-icons sidebar-sub-icons">person</i>',
      to: "/user-profile-lite",
      show: false
    },
    {
      title: "Applications",
      htmlBefore: '<i class="material-icons sidebar-sub-icons">vertical_split</i>',
      to: "/blog-posts",
      show: false
    },
    {
      title: "component-overview",
      htmlBefore: '<i class="material-icons sidebar-sub-icons">sync_alt</i>',
      to: "/components-overview",
      show: false
    },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // }
  ]

