import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import './Auth.css';
import { Dispatcher, Events, Store } from "../flux";
import traderapi from "../traderapi/api";


const DefaultLayout = ({ children, noNavbar, history }) => {
  const [userInitialised, setUserInitialised] = useState(!!Store.getLoggedUser().username)
  const url = Store.getFirstRedirectedURL();

  const initUser = () => setUserInitialised(true)

  useEffect(() => {
    Store.resetEmitter(true, Events.USERDATA, initUser);

    if (!userInitialised) {
      traderapi.myself(async (data) => {
        Dispatcher.dispatch({actionType: Events.USERDATA, payload: data});
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect( () => () => {
    Store.resetEmitter(false, Events.USERDATA, initUser);
  }, [] );


  useEffect(() => {
    var u = url;
    if(u.length > 0) {
      if(u.startsWith(process.env.REACT_APP_BASENAME)) {
        u = u.substring(process.env.REACT_APP_BASENAME.length);
      }
      if(u.length>0) {
        history.push(u);
      }
      Dispatcher.dispatch({actionType: Events.SET_FIRST_REDIRECTED_URL, payload: ''});

    }
  }, [url, history]);

  if(userInitialised) {
    return <Container fluid className="default-lay-container">
      <Row className="default-lay-row">
        <MainSidebar />
        <Col
          className="main-content p-0"
          lg={{size: 10, offset: 2}}
          md={{size: 9, offset: 3}}
          sm="12"
          tag="main"
        >
          {!noNavbar && <MainNavbar/>}
          {children}
          {/* {!noFooter && <MainFooter />} */}
        </Col>
      </Row>
    </Container>
  }else{
    return <></>
  }
};

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default withRouter(DefaultLayout);
