import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import { Store,Dispatcher,Events } from "../flux";
import RecentApplications from "../components/blog/RecentApplications";
import DatasetToolbox from "../components/dataset-toolbox/DatasetToolbox";

import "./DuplicateReport.css"


class DuplicateReport extends React.Component {
  constructor(props) {
    super(props);
    this.onDataChanged = this.onDataChanged.bind(this);
    this.onRefreshDataset = this.onRefreshDataset.bind(this);
    this.state = { data:null, chartData: []};
  }

  componentDidMount() {
    Store.resetDatasetListener(this.onDataChanged, true);
    Dispatcher.dispatch({actionType: Events.SETWORKINGDATASET, payload: this.props.dataset});

    //this.onDataChanged();
  }

  componentWillUnmount() {
    Store.resetDatasetListener(this.onDataChanged, false);
  }

  onDataChanged() {
    const { dataset } = this.props;
    const data = Store.getDataset(dataset);
    const items = data.items;

    this.setState({data, items });
  }

  onRefreshDataset() {
    const data = undefined;
    const items = undefined;
    this.setState({data, items});
    Dispatcher.dispatch({actionType: Events.RELOADDATA, payload: this.props.dataset});
  }

  render() {
    const { items } = this.state;
    if(!items) {
      return (
        <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle title={`${this.props.title} loading ...`} className="text-sm-left mb-3" />
        </Row>
      </Container>);
    }

    if(items.length < 1) {
      return (
        <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle lg="11" title={`No records for ${this.props.title}`} className="text-sm-left mb-3" />
          <DatasetToolbox options={{refresh:this.onRefreshDataset}}/>
        </Row>
      </Container>);
    }

    return (
      <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle lg="11" title={`${this.props.title}: found ${items.length}`} className="text-sm-left mb-3" />
        <div className="dataset-toolsbox-block">
        <DatasetToolbox options={{refresh:this.onRefreshDataset}}/>
        </div>
      </Row>

      {items.map((i,n)=>{
        return (
          <Row key={`dup-idx-${n}`}> 
            <Col lg="12" md="12" sm="12" className="mb-4">
              <RecentApplications 
                title={`${n+1}. ${this.props.title} '${i.name}' has ${i.items.length} records`} 
                dataget={()=> { return i.items; }}
              />
            </Col>
          </Row>
        );
      })}

  </Container>
    );
  }
}

DuplicateReport.propTypes = {
  /**
   * The small stats dataset.
   */
};

DuplicateReport.defaultProps = {
};

export default DuplicateReport;
