export const ALL = 'all';
export const TODAY = 'Today';
export const YESTERDAY = 'Yesterday';
export const UNRESOLVED = 'unresolved';
export const RESOLVED = 'resolved';
export const CLOSED = 'closed';
export const IMMEDIATELY = 'immediately';
export const PENDINGEOD = 'pendingEod';
const STALE = 'stale';

export const ENABLE = 'ENABLE';
export const DISABLE = 'DISABLE';
export const PENDING_EOD = 'EOD';
export const PENDING_EOD_SECOND = 'eod';

export const INCOMING = 'incoming';
export const INPROGRESS = 'in progress';
export const INPROGRESS_SECOND = 'inprogress';
export const ONHOLD = 'on hold';
export const ONHOLD_SECOND = 'onhold';
export const FOLLOWUP = 'followUp';

export const REQUEST_FORMATED_KEYS = {
  [INPROGRESS]: 'inprogress',
  [ONHOLD]: 'onhold',
  [FOLLOWUP]: 'followUp',
  [TODAY]: 'today',
  [PENDING_EOD]: 'eod',
  [PENDINGEOD]: 'pendingEod',
  [STALE]: 'closed'
}

export const REQUEST_FORMATED_LABELS_FOR_DISABLE_ACCOUNTS = {
  inprogress: INPROGRESS,
  onhold: ONHOLD,
  followUp: FOLLOWUP,
  pendingEod: PENDING_EOD,
}

export const REQUEST_FORMATED_LABELS = {
  inprogress: INPROGRESS,
  onhold: ONHOLD,
  followUp: FOLLOWUP,
  pendingEod: PENDINGEOD,
}

export const REQUEST_BUTTON_TABS_SYNC = {
  start: INPROGRESS_SECOND,
  resolve: CLOSED,
  decline: CLOSED,
  stop: ONHOLD_SECOND,
  pendingeod: PENDINGEOD,
  waitingforclient: FOLLOWUP,
  waitingfor3rdparty: FOLLOWUP,
  stale: STALE
};

export const tabsListData = {
  marketDataSet: {
    tabsList: {
      dateFilters: [TODAY, ALL],
      statusFilters: [UNRESOLVED, CLOSED],
    },
    defaultDateFilters: [TODAY],
    defaultStatusFilters: [UNRESOLVED],
    type: 'MARKETDATA',
    datasetName: 'requests',
    defaultHeader: [
      'Due Date',
      'Requested On',
      'ID',
      'Trader ID',
      'Platform',
      'Requested Changes',
      'Status',
      'Who\'s Doing',
      'Time to resolution',
    ],
  },
  accountsToEnableSet: {
    tabsList: {
      dateFilters: [IMMEDIATELY, TODAY, ALL],
      statusFilters: [UNRESOLVED, CLOSED],
    },
    defaultDateFilters: [IMMEDIATELY, TODAY],
    defaultStatusFilters: [UNRESOLVED],
    type: 'TRADING_ACCESS',
    datasetName: 'requestsEnable',
    defaultHeader: [
      'Due Date',
      'Requested On',
      'ID',
      'Trader ID',
      'Platform',
      'Account Type',
      'Platform Status',
      'Status',
      'Who\'s Doing',
      'Time to resolution',
    ],
  },
  accountsToDisableSet: {
    tabsList: {
      dateFilters: [IMMEDIATELY, TODAY, PENDINGEOD, ALL],
      statusFilters: [UNRESOLVED, CLOSED],
    },
    defaultDateFilters: [IMMEDIATELY, TODAY],
    defaultStatusFilters: [UNRESOLVED],
    type: 'TRADING_ACCESS',
    datasetName: 'requestsDisable',
    defaultHeader: [
      'Due Date',
      'Requested On',
      'ID',
      'Trader ID',
      'Platform',
      'Account Type',
      'Platform Status',
      'Status',
      'Who\'s Doing',
      'Time to resolution',
    ],
  },
  requestRisk: {
    tabsList: {
      statusFilters: [INCOMING, INPROGRESS, ONHOLD, PENDINGEOD, FOLLOWUP, CLOSED, ALL],
    },
    defaultStatusFilters: [INCOMING],
    type: 'RISKSETTING',
    datasetName: 'requestsRisk',
    defaultHeader: [
      'Status',
      'Requested On',
      'Updated On',
      'ID',
      'Trader ID',
      'Account Type',
      'Platform',
      'Category',
      'Current',
      'Requested',
      'Applied',
      'Type',
      'Balance',
      'Who\'s Doing',
      'Time to resolution',
    ],
  },
  requestCustom: {
    tabsList: {
      statusFilters: [INCOMING, INPROGRESS, ONHOLD, FOLLOWUP, CLOSED, ALL],
    },
    defaultStatusFilters: [INCOMING],
    type: 'INQUIRY',
    datasetName: 'requestsCustom',
    defaultHeader: [
      'Status',
      'Requested On',
      'Updated On',
      'ID',
      'Trader ID',
      'Account Type',
      'Platform',
      'Subject',
      'Who\'s Doing',
      'Time to resolution',
    ],
  }
}
