import {useEffect, useState, useRef} from 'react'
import moment from "moment";
import {REQUEST_STATUSES} from "../../constants/request";

const TimeToResolution = ({request}) => {
  const [time, setTime] = useState('')
  const intervalRef = useRef(null);

  const getDiff = (dateStart, dateEnd) => {
    let duration = moment.duration(dateEnd.diff(dateStart)).add(moment.duration(2, 'seconds'));

        let days = Math.floor(duration.asDays())
        duration.subtract(moment.duration(days,'days'))

        let hours = duration.hours()
        duration.subtract(moment.duration(hours,'hours'))

        let minutes = duration.minutes()
        duration.subtract(moment.duration(minutes,'minutes'))

        let seconds = duration.seconds()
        duration.subtract(moment.duration(seconds,'seconds'))

    return {
      days,
      hours,
      minutes,
      seconds
    }
  }

  const updateTimeToResolution = (dateStart, dateEnd) =>{
    const diff = getDiff(
      moment(dateStart),
      moment(dateEnd)
    )

    if(diff.days > 0){
      setTime(`${diff.days}d`)
      return
    }

    if(diff.hours > 0){
      setTime(`${diff.hours}h ${diff.minutes}m`)
      return
    }

    if(diff.minutes === 0){
      setTime('<1 min')
      return
    }

    setTime(`${diff.minutes}m`)
  }


  useEffect(() => {
    switch (request.status){
      case REQUEST_STATUSES.INPROGRESS:
        intervalRef.current = setInterval(() => {
          updateTimeToResolution(
            moment(request.time?.INPROGRESS),
            moment(new Date())
          )
        }, 1000);
        break

      case REQUEST_STATUSES.COMPLETED:
        updateTimeToResolution(
          moment(request.time?.INPROGRESS),
          moment(request.time?.COMPLETED)
        )
        break

      default:
        setTime('Not started')
        break
    }

    return () => {
      // Clear the interval when the component unmounts or when the effect dependencies change.
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.status])


  return (
    <>
      {time}
    </>
  )
}

export default TimeToResolution;
