import moment from "moment";
import momentTZ from "moment-timezone";

export const utils = {
  setDate: (date) => {
    const today = momentTZ().tz("America/New_York").endOf("day");
    const tomorrow = momentTZ().tz("America/New_York").add(1, "day").endOf("day");
    const yesterday = momentTZ().tz("America/New_York").add(-1, "days");

    if (momentTZ(date).tz("America/New_York").endOf("day").isSame(today)) return `Today at ${momentTZ(date).tz("America/New_York").format("h:mm A")}`;
    if (momentTZ(date).tz("America/New_York").endOf("day").isSame(tomorrow)) return `Tomorrow at ${momentTZ(date).tz("America/New_York").format("h:mm A")}`;
    if (momentTZ(date).tz("America/New_York").endOf("day").isSame(yesterday))
      return `Yesterday at ${momentTZ(date).tz("America/New_York").format("h:mm A")}`;
    else return momentTZ(date).tz("America/New_York").format("YYYY-MM-DD h:mm A");
  },
  openBase64NewTab: (base64Pdf) => {
    const base64ToArrayBuffer = (data) => {
      let binaryString = window.atob(data);
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    };
    let arrBuffer = base64ToArrayBuffer(base64Pdf);
    let blob = new Blob([arrBuffer], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  },
  downloadFile: (data, fileName) => {
    let tempLink = document.createElement("a");
    tempLink.href = data;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
  },
};

export const userTime = (date = new Date(), format = "YYYY-MM-DD h:mm A", timeZone,) => {
  if (timeZone) {
    return momentTZ(date).tz(timeZone).format(format);
  } else {
    return moment(date).format(format);
  }
};
