import React from "react";

import "./preloader.css";

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="preloader-black"/>
      <div className="preloader-loader">Loading...</div>
    </div>
  );
};

export default Preloader;
