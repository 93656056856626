import React, { useMemo } from "react";
import { utils } from "./utils";

const ChatHeader = ({ headerItems, setPopupState, headerButtonType, onHeaderButtonClick }) => {

  const renderHeaderItems = useMemo(() => {
    const firstColumn = Object.entries(headerItems).map((item, index) => {
      return <span key={index}>{item[0]}:</span>;
    });
    const secondColumn = Object.entries(headerItems).map((item, index) => {
      if (typeof item[1] === "object")
        return <span key={index}>{utils.setDate(item[1])}</span>;
      return <span key={index}>{item[1]}</span>;
    });
    return (
      <div className="chat-header-columns">
        <div className="first-column">{firstColumn}</div>
        <div className="second-column">{secondColumn}</div>
      </div>
    );
  }, [headerItems]);

  const setHeaderButton = () => {
    const btnClicked = (e, type) => {
      setPopupState({
        type: type === "cancel" ? "cancel-request" : "resolve-request",
        show: true,
        title: type === "cancel"
        ? "Cancel request?"
        : "Confirm request resolution?",
        message: type === "cancel" 
          ? `Are you sure you want to cancel this request?` 
          : `Do you really want to mark this request as resolved?`,
        cb: () => onHeaderButtonClick(e, headerButtonType),
      })
    }
    if (headerButtonType === "cancel")
      return (
        <button
          className="main-header-button cancel-type"
          onClick={(e) => btnClicked(e, headerButtonType)}
        >
          Cancel Request
        </button>
      );
    if (headerButtonType === "solved")
      return (
        <button
          className="main-header-button resolve-type"
          onClick={(e) => btnClicked(e, headerButtonType)}
        >
          Request Resolved
        </button>
      );
    if (headerButtonType === "nobutton") return null;
  };

  return (
    <div className="chat-header">
      {renderHeaderItems}
      {<div className="chat-header-button">{setHeaderButton()}</div>}
    </div>
  );
};

export default ChatHeader;
