import traderapi from "../../traderapi/api";
import moment from 'moment';


const dateString = (d) => {
    if(d) {
        return moment(d).format('MM/DD/YYYY');
    }
    return '??/??/????';
};

class ReportDuplicateName {

    
    makeRecord(a) {
        return {
            id: a._id,
            name: `${a._id.firstName} ${a._id.lastName} | DOB: ${dateString(a._id.dateofbirth)}`,
            count: a.count,
            items: a.ids
        }
    }
    //
    list(params, cb) {
        traderapi.getReportDuplicateName( (data) => {
            const items = data.map(this.makeRecord);
            cb({
                items,
                data,
            });
        }, params);
    }
}

export default ReportDuplicateName;