import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems(),
      refresh: true
    };
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }

  sidebarnavitem(item, idx, onToggle) {
    const { formIsEditting } = this.props;

    return <SidebarNavItem formIsEditting={formIsEditting} key={idx} item={item} onToggle={onToggle}/>
  }

  render() {
    const { navItems: items} = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => {
            var i = this.sidebarnavitem(item, idx, (item.toggleable) ? ()=>{ item.toggle = !item.toggle; this.setState({refresh: true})} : undefined);

            if(item.toggleable && item.toggle) {
              i = [ i, ...item.items.map((item2, idx2)=>(this.sidebarnavitem(item2, `${idx}-${idx2}`)))];
            }
            return i;
           })
          }
        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;
