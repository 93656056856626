import React, { useState } from 'react'

import './PickMenu.css'

const PickMenu = ({onChangeHandler, menuLabel, selectedValue, optionArray}) => {
    const [localState, setLocalState] = useState(selectedValue)
    
    const renderOptions = optionArray.map((item, index) => <option value={item} key={index}>{item}</option>);
    const handleChange = event => {
      onChangeHandler(event.target.value);
      setLocalState(event.target.value);
    };

    return (
        <div className="picke-menu-wrapper">
            <label>
              {menuLabel}:
              <select className="select" defaultValue={selectedValue} value={localState} onChange={handleChange}>
                {renderOptions}
              </select>
            </label>
        </div>
    )
}

export default PickMenu;
