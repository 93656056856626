import traderapi from "../../traderapi/api";
import F from '../fields.js';
import constants from "../constants";
import { utilityFunctions } from "../../utils/utilityFunctions";

class RegistrationDataset {
    makeRecord(a) {
        try {
            return {
                ABOUTYOU: F.createSeparator('About You'),
                step: a.onboardingState,
                accountType: a.accountType,
    
                // personal
                title: F.createFieldEnum(a.title, constants.TitleList, {readonly:true}),
                firstName: a.firstName,
                middleName: a.middleName,
                lastName: a.lastName,
                dateofbirth: F.createFieldDate(a.dateofbirth, {readonly: true}),
                citizenship: a.citizenship,
                pep: (a.pep&&a.pepcomment)?a.pepcomment:'',
    
                CONTACTINFO: F.createSeparator('Contact Information'),
                phone: a.phone,
                street: a.street,
                street2: a.street2,
                city: a.city,
                postalCode: a.zip,
                country: a.country,
    
                GOVERNAMENT: F.createSeparator('ID Verification'),
                // document
                idtype: F.createFieldEnum(a.idtype, constants.IDTypeList, {readonly:true}),
                idnumber: a.idnumber,
                idexpiration: F.createFieldDate(a.idexpirydate, {readonly:true}),
                hasExpirationDate: F.createFieldBoolean(a.idexpirydate, {select: ['No', 'Yes'], readonly:true}),
                scans: a?.document ? F.createFieldImages(Object.entries(a?.document?.scanFiles)?.map((s)=>(
                    F.createFieldImage( `${s[0]}:${s[1].filename}`, (cb)=>(traderapi.getScan(cb, a._id, s[1].scanID)))
                ))) : [],
                
                // agreement
                AGREEMENT: F.createSeparator('Schedule A'),
                tradingPlatform: a.tradingplatform,
                // traderType: F.createFieldEnum(a.traderType, constants.TraderTypeList, {readonly:true}),
                performanceBond: F.createField(a.performanceBond, {changeFormat: true}),
                initialBuyingPower: F.createField(a.initialBuyingPower, {changeFormat: true}),
                approvedOvernightBuyingPower: F.createField(a.approvedOvernightBuyingPower, {changeFormat: true}),
                maximumDailyLoss: F.createField(a.maximumDailyLoss, {changeFormat: true}),
    
                ACCOUNTINFO: F.createSeparator('Account info'),
                tradingExperience: F.createFieldEnum( a.tradingProfile, constants.TradingExperienceList, {readonly:true}),
                // softwareType: F.createFieldEnum(a.softwareType, constants.SoftwareTypeList, {readonly:true}),
                // software: F.createFieldEnum(a.software, constants.SoftwareList, {readonly:true}),
    
                agreementDocument: F.createField(`${a.agreementdocument?.name} : ${a.agreementdocument?.version}`, {readonly:true}),
                marketdata: a.marketdata,
                markets: F.createFieldList(utilityFunctions.convertMarkets(a.markets), {lines: 7}),
    
                
                MARKETING: F.createSeparator('Marketing'),
                campaign: F.createField(a.marketingData?.Campaign, {readonly: true}),
                adset: F.createField(a.marketingData?.Adset, {readonly: true}),
                ad: F.createField(a.marketingData?.Ad, {readonly: true}),
    
                SYSTEM: F.createSeparator('System'),
                activationCode: a.activationCode,
                id: F.createFieldReference( "r", a.profileID, {readonly: true}),
                email: a.email,
                systemid: F.createField(a._id, {readonly:true}),
    
                createdAt: F.createFieldDateTime(a.createdAt, {readonly: true}),
                updatedAt: F.createFieldDateTime(a.updatedAt, {readonly: true}),
            }
        } catch (error) {
        }
    }
    //
    list(params, cb) {
        traderapi.getRegistrations((data) => {
            const items = data.items.map(this.makeRecord);
            cb({
                items,
                data
            });

        }, params);
    }
    //
    get(id, cb) {
        this.list({profileID: id}, cb)
    }
    //
    save(n, o, cb) {
        const a = {
            profileID: n.id.id
        };
        for (const [key, value] of Object.entries(n)) {
            const oo = o[key];
            var nv;
            var ov;
            if(typeof value === 'object') {
                nv = value?.value;
                ov = oo?.value;
            } else {
                nv = value;
                ov = oo;
            }
            if(nv!==ov) {
                console.log(`CHANGE: ${key} new: ${nv} old: ${ov}`);
                switch(key) {
                    case 'firstName':
                    case 'lastName':
                    case 'middleName':
                    case 'email':
                    case 'title':
                    case 'citizenship':
                        a.personal = Object.assign(a.personal || {}, {[key]: nv});
                        break;
                    //
                    case 'street':
                    case 'city':
                    case 'state':
                    case 'postalCode':
                    case 'country':
                        a.address = Object.assign(a.address || {}, {[key]: nv});
                        break;
                    //
                    case 'dateofbirth':
                    case 'phone':
                    case 'tradingExperience':
                    case 'software':
                    case 'softwareType':
                    case 'traderType':
                        a[key] = nv;
                        break;
                    //
                    case 'pep':
                        a.pep = { pep: !!nv, details: nv }
                        break;
                    //
                    case 'idtype':
                    case 'idnumber':
                    case 'idexpiration':
                        a.document = Object.assign(a.document || {}, {[key]: nv});
                        break;
                    //
                    case 'performanceBond':
                    case 'initialBuyingPower':
                    case 'approvedOvernightBuyingPower':
                    case 'maximumDailyLoss':
                        a.agreement = a.agreement || {};
                        const { performanceBond, initialBuyingPower, approvedOvernightBuyingPower, maximumDailyLoss } = n;
                        a.agreement.rules = { performanceBond, initialBuyingPower, approvedOvernightBuyingPower, maximumDailyLoss };
                        break;
                    //
                    case 'referenceClient':
                        a.reference = { client: nv };
                        break;
                    default:
                }
            }
        }

        traderapi.saveApplication((data) => {
            cb(data, n, o);
       }, a);
    }
}

export default RegistrationDataset;
