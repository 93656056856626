const popupTypes = {
    YES_RESPONSE: "yes-response",
    NO_RESPONSE: "no-response",
    STAY_RESPONSE: "stay-response",
    REMOVE_ATTACH: "remove-attachment",
    INFORMATIONAL: "informational",
    CANCEL_REQUEST: "cancel-request",
    RESOLVE_REQUEST: "resolve-request",
};
const uploadTypes = {
    ATTACHMENT: "attachment",
    IMAGE: "image",
}

const constants = {
    popupTypes, uploadTypes
}
export default constants;