import React, { useState, useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import ChatUploadButton from "./chat-upload-button/ChatUploadButton";
import ChatMessageItem from "./ChatMessageItem";
import constants from "./types";
import { utils } from "./utils";

const ChatMessage = ({
  messageData,
  onUploadAttachment,
  setPopupState,
  adminType,
  adminID,
  attachIsUploading,
  messageTypes,
  chatDataIsDownloading,
  onHistoryAttachClick,
  senderType,
  attachIsDownloading,
  onMessageSend,
  showUploadButton,
}) => {
  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [showPreviewImage, setShowPreviewImage] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [chosenMessageType, setChosenMessageType] = useState(null);
  const messageBlockRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (!!messageData?.length) {
      const found = document.getElementsByClassName("chat-message-history");
      if (found.length > 0) found[0].scrollTop = found[0].scrollHeight;
    }
  }, [messageData?.length]);

  useEffect(() => {
    if (textareaRef?.current) {
      textareaRef.current.style.height = "0px";
      let scrollHeight = textareaRef.current.scrollHeight;
      if (scrollHeight > 100)
        scrollHeight = 100;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [message, textareaRef?.current]);
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (messageBlockRef.current && !messageBlockRef.current.contains(event.target) && showPreviewImage) {
        setShowPreviewImage(false);
        setCurrentImage(null);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [messageBlockRef, showPreviewImage]);

  const { _internal, _public } = messageTypes;
  const { popupTypes } = constants;
  const isPDF = currentImage?.image.split(";")[0] === "data:application/pdf";
  

  const renderMessageHistory = () => {
    if (chatDataIsDownloading) return (
      <div className="chat-message-history-dowloading">
        <Spinner  className="spinner" animation="grow"/>
      </div>
    )
    if (!!messageData?.length)
      return (
        <div
          className={`chat-message-history ${
            attachIsDownloading ? "downloading-attach" : undefined
          }`}
        >
          {messageData.map((item, index) => {
            const returnValue = (isAdmin, isPrivate) => {
              const returnAttachment = (file, findex, isPrivateAttach) => {
                return (
                  <>
                    <span
                      onClick={() => onAttachmentItemClick(file, false)}
                      className={`chat-message-attach ${
                        isPrivateAttach ? "greyed" : undefined
                      }`}
                      key={findex}
                    >
                      {file.name}
                    </span>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                  </>
                );
              };
              
              const setMessageDisplayType = () => {
                return isAdmin
                ? item.author.username === adminID
                  ? "right-align"
                  : "left-align"
                : !!item.author._id
                  ? "left-align"
                  : "right-align"
              }
              
              return (
                  <ChatMessageItem
                    key={index}
                    isPrivate={isPrivate}
                    adminID={adminID}
                    item={item}
                    setMessageDisplayType={setMessageDisplayType}
                    isAdmin={isAdmin}
                    _internal={_internal}
                    _public={_public}
                    returnAttachment={returnAttachment}
                  />
              );
            };
              return returnValue(senderType === adminType, item.messageType === _internal);
          })}
          {attachIsDownloading && (
            <Spinner className="spinner" animation="grow" />
          )}
        </div>
      );
    return (
      <div className="chat-message-history">
        {senderType === adminType
          ? "Communication with the Sender (and your colleagues) about this request will be displayed here"
          : "Communication with Support Team about this request will be displayed here"}
      </div>
    );
  };
  const onClickSend = () => {
    const body = {};
    if (!!attachments?.length) body.attachments = attachments;
    body.message = message.trim();
    body.senderType = senderType;
    body.messageType = chosenMessageType || _public;
    onMessageSend(body);
    setMessage("");
    setAttachments([]);
    setCurrentImage(null);
    textareaRef.current.focus()
  };
  const onChatMessageClick = () => {
    if (showPreviewImage) {
      setShowPreviewImage(false);
      setCurrentImage(null);
    }
  }
  const renderImagePreview = () => {
    if (showPreviewImage && !isPDF)
      return (
        <div
          className="chat-message-preview"
          onClick={() => {
            setShowPreviewImage(false);
            setCurrentImage(null);
          }}
        >
          <img src={currentImage?.image} alt="preview" />
        </div>
      );
  };

  const renderInputMessage = () => {
    const onMessageChange = (e) => {
      setMessage(e.target.value);
    };
    const onAttachmentChange = (attachment, add) => {
      let newAttachments = attachments.map((a) => a);
      if (add) {
        if (Array.isArray(attachment)) {
          newAttachments = newAttachments.concat(attachment);
          setAttachments(newAttachments);
          onUploadAttachment(attachment, false);
        } else {
          newAttachments.push(attachment);
          setAttachments(newAttachments);
          onUploadAttachment(attachment, false);
        }
      } else {
        setPopupState({
          show: true,
          title: "Remove attachment?",
          message: "Do you really want to remove this attachment?",
          type: popupTypes.REMOVE_ATTACH,
          cb: () => {
            newAttachments = newAttachments.filter((a) => {
              return a.name !== attachment.name;
            });
            setAttachments(newAttachments);
            onUploadAttachment(attachment, true, true);
          },
        });
      }
    };
    const onCancelClick = () => {
      setAttachments([]);
      setMessage("")
      onUploadAttachment(null, true, true);
      setChosenMessageType(null);
    };

    if (senderType === adminType && !chosenMessageType)
      return (
        <div className="chat-message-button-types">
          <button
            className="chat-message-button"
            onClick={() => setChosenMessageType(_public)}
          >
            Client message
          </button>
          <button
            className="chat-message-button internal-style"
            onClick={() => setChosenMessageType(_internal)}
          >
            Internal message
          </button>
        </div>
      );
    return (
      <div className="chat-message-input">
        {senderType === adminType && (
          <span className="message-type-info">
            {chosenMessageType === _public
              ? "message to client"
              : "internal message"}
          </span>
        )}
        <textarea
          placeholder="Write your message here"
          className={`chat-message-textarea ${senderType === adminType ? "w-100" : undefined}`}
          name="message"
          maxLength={4096}
          ref={textareaRef}
          value={message}
          onChange={onMessageChange}
        />
        <div className="chat-message-upload-attachments">
          <ChatUploadButton
            onAttachmentChange={onAttachmentChange}
            setPopupState={setPopupState}
            show={showUploadButton}
            attachments={attachments}
          />
          {!!attachments?.length && !attachIsUploading && (
            <div className="chat-message-attachments">
              {attachments.map((a, index) => {
                return (
                  <div key={index} className="d-flex">
                    <span
                      className="attachment-item"
                      onClick={() => onAttachmentItemClick(a, true)}
                    >
                      {a.name}
                    </span>
                    <span
                      className="remove-attach-icon"
                      onClick={() => onAttachmentChange(a, false)}
                    >
                      X
                    </span>
                  </div>
                );
              })}
            </div>
          )}
          {!!attachments?.length && attachIsUploading && (
            <div className="chat-message-attachments-uploading">
              <Spinner className="spinner" animation="grow" />
              <span>Uploading attachment(s)...</span>
            </div>
          )}
        </div>
        {senderType === adminType ? (
          <div className="chat-message-button-types">
            <button
              className="chat-message-button"
              disabled={!!!message.replaceAll(" ", "").replaceAll("\n", "").split("").length || attachIsUploading}
              onClick={onClickSend}
            >
              Send
            </button>
            <button className="chat-message-button cancel-type" onClick={onCancelClick}>
              Cancel
            </button>
          </div>
        ) : (
          <button
            onClick={onClickSend}
            disabled={!!!message.replaceAll(" ", "").replaceAll("\n", "").split("").length || attachIsUploading}
            className="chat-message-button chat-message-send-button"
          >
            Send
          </button>
        )}
      </div>
    );
  };

  const onAttachmentItemClick = (item, inMessage) => {
    const isPDF = item.type === "application/pdf" || item.name.split(".").at(-1) === "pdf";
    const isCSV = item.type === "text/csv" || item.name.split(".").at(-1) === "csv";
    let reader = new FileReader();
    if (inMessage) {
      const imageData = {
        file: item,
      };
      reader.onload = (event) => {
        imageData.image = event.target.result;
        if (isCSV) {
          utils.downloadFile(imageData.image, item.name)
          return
        }
        if (isPDF) {
          utils.openBase64NewTab(imageData.image.split(",")[1]);
        } else {
          setCurrentImage(imageData);
          setShowPreviewImage(true);
        }
      };
      reader.readAsDataURL(item);
    } else {
      const openPreview = (response) => {
        reader.onload = (event) => {
          utils.downloadFile(event.target.result, item.name)
        };
        reader.readAsDataURL(response.data);
      };
      onHistoryAttachClick(item._id, openPreview);
    }
  };

  return (
    <div className="chat-message" ref={messageBlockRef} onClick={onChatMessageClick}>
      {renderMessageHistory()}
      {renderInputMessage()}
      {renderImagePreview()}
    </div>
  );
};

export default ChatMessage;
