import moment from "moment";
import React from "react";

const MarketConfirmWindow = ({
  buttonItem,
  getTotalPrice,
  primaryEntitlements,
  localState,
  onDateChange,
  dataWasChanged,
  requestedMarketsData,
  isNewApplication,
}) => {
  const renderConfiramtionPart = (alreadyHadRequest = false) => {
    return (
      <div className="confirmation-block">
        <p className="bold-text">
          Please review and confirm the changes you are
          requesting:
        </p>
        <p>
          <span className="bold-text">1. Entitlement:</span> {buttonItem} bundle
          will be applied with the entitlements shown above (marked with green
          will be added, marked with red will be removed)
        </p>
        <div>
          <span className="bold-text">2. Total monthly cost:</span>
          <ul>
            <li>
              <div className="monthly-cost-item">
                <span>current:</span>{" "}
                <span>{getTotalPrice(primaryEntitlements)}</span>
              </div>
            </li>
            <li>
              <div className="monthly-cost-item">
                <span>after the change:</span>{" "}
                <span>{getTotalPrice(localState?.markets)}</span>
              </div>
            </li>
          </ul>
        </div>
        <div className="effective-date-block">
          <span className="bold-text">3. Effective date: </span>
          <input
            type="date"
            style={{position: "relative"}} // this fixes bug in chrome browser with opening and close datepicker
            min={moment(new Date()).format("YYYY-MM-DD")}
            max={moment().add(1, "year").format("YYYY-MM-DD")}
            value={localState.effectiveDate
              ? moment(localState.effectiveDate).format("YYYY-MM-DD")
              : moment().add(1, "M").startOf("month").format("YYYY-MM-DD")
            }
            open={false}
            onChange={onDateChange}
          />
          {alreadyHadRequest &&
            moment(requestedMarketsData.schedule.duedate).format(
              "YYYY-MM-DD"
            ) !== moment(localState.effectiveDate).format("YYYY-MM-DD") && (
              <span className="previously-text">
                Previously selected:{" "}
                {moment(requestedMarketsData.schedule.duedate).format(
                  "YYYY-MM-DD"
                )}
              </span>
            )}
          <div className="monthly-fee-text">
            Please note that the monthly fee is not prorated.
          </div>
        </div>
      </div>
    );
  };
  if (dataWasChanged || !isNewApplication) return renderConfiramtionPart(!isNewApplication);
  
  return null;
};

export default MarketConfirmWindow;
