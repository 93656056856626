import traderapi from "../../traderapi/api";
import F from '../fields.js';

class MarketDataBundleDataset {

    makeRecord(a) {
        return {
            id: F.createFieldReference("marketdatabundles", a._id),
            name: a.name,
            description: a.description,
            order: a.order
        }
    }
    //
    list(params, cb) {
        traderapi.getMarketDataProviders((data) => {
            const items = data.bundle.map(this.makeRecord);
            cb({
                items,
                data
            });
            
        }, params);
    }
    //
    get(id, cb) {
        this.list({id}, cb)
    }
    //
    save(item, cb) {
        traderapi.saveApplication((data) => {
        cb(data);
    }, item);
    }
}

export default MarketDataBundleDataset;