import traderapi from "../../traderapi/api";
import { utilityFunctions } from "../../utils/utilityFunctions";
import F from '../fields.js';

class CalculationRateDataset {
    makeRecord(a) {
      const minMaxField = (a) => {
        return `${utilityFunctions.currencyFormater().format(a.min)} - ${utilityFunctions.currencyFormater().format(a.max)}`;
    }
      return {
            id: F.createFieldReference("calculationrates", a._id),
            name: a.name,
            accountTypeRef: a.accountTypeRef,
            // private: F.createFieldBoolean(a.private, {select: ['General', 'Private']}),
            overnight: F.createFieldBoolean(a.overnight, {select: ['Day only', 'Overnight']}),
            performanceBond: `${minMaxField(a.performanceBond)}, step - ${utilityFunctions.currencyFormater().format(a.performanceBond.step)}`,
            buyingPower: minMaxField(a.buyingPower),
            overnightBuyingPower: a.overnightBuyingPower ? minMaxField(a.overnightBuyingPower) : 0,
            stopLoss: minMaxField(a.stopLoss),
            // specialOffer: F.createFieldBoolean(a.specialOffer, { select: ['No', 'Yes']})
        }
    }
    //
    list(params, cb) {
        traderapi.getCalculationRates(({data}) => {
            const items = data.map(this.makeRecord);
            cb({
                items,
                data
            });

        }, params);
    }
    //
    get(id, cb) {
        this.list({id}, cb)
    }
    //
    save(item, cb) {
    traderapi.saveApplication((data) => {
        cb(data);
    }, item);
    }
}

export default CalculationRateDataset;
