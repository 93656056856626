// @ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment/moment";

import MarketDataItem from "./MarketDataItem";
import Bundles from "./Bundles";
import MarketConfirmWindow from "./MarketConfirmationWindow";
import { utilityFunctions } from "../../../../utils/utilityFunctions";
import { REQUEST_STATUSES } from "../../../../constants/request";

import './marketdata.css'


const MarketDataPage = ({ 
  currentAccount, isNewApplication, tradingPlatforms, 
  marketBundles, setEntitlementsData, marketdataProviders, 
  extensions, requestsItems, setRequestedMarketData, 
  requestedMarketsData
}) => {
  const [buttonItem, setButtonItem] = useState(null);
  const [primaryBundle, setPrimaryBundle] = useState(null);
  const [primaryEntitlements, setPrimaryEntitlements] = useState([]);
  const [localState, setLocalState] = useState({});
  const [dataWasChanged, setDataWasChanged] = useState(false);
  const [applicationIsBlocked, setApplicationIsBlocked] = useState(false);
  const [showNoMarketsPopup, setShowNoMarketsPopup] = useState(false);
  const [lastEntitlmentData, setLastEntitlmentData] = useState(null);

  const getTPRefByName = (tp, tpt) => {
    return tradingPlatforms.find(t => t.software === tp && t.softwareType === tpt)?.reference
  };

  useEffect(() => {
    const data = {
      duedate: localState.effectiveDate 
        ? localState.effectiveDate 
        : moment().add(1, "M").startOf("month").format("YYYY-MM-DD"),
      type: "MARKETDATA",
      ext: {
        addMarkets: convertToSendData(true),
        removeMarkets: convertToSendData(false)
      },
      markets: localState.markets,
    }
    setEntitlementsData(data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState])
  // find and set main market request
  useEffect(() => {
    if (requestsItems !== null && !requestedMarketsData) {
      if (requestsItems?.length === 0) {
        const fakeRequest = {
          status: REQUEST_STATUSES.COMPLETED,
          schedule: {
            duedate: "2023-01-01T02:55:17.042Z",
          },
        };
        setRequestedMarketData(fakeRequest);
      }
      let result = null;
      const notCanceledRequests = requestsItems.map(i => {
        if (i.type === "MARKETDATA" && i.status !== "CANCELED")
          return i
        return null;
      }).filter(n=>n);
      result = notCanceledRequests.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];
      if (result?.status === "BLOCKED") setApplicationIsBlocked(true)
      setRequestedMarketData(result);
    };
  }, [requestsItems, requestedMarketsData, setRequestedMarketData]);

  // set acc TP
  useEffect(() => {
      if (currentAccount && !!Object.keys(currentAccount).length) {
        setLocalState({ ...localState, tradingPlatform: currentAccount.software });
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  // after we set requested market request, we filter and sort markets
  useEffect(() => {
      if (requestedMarketsData && extensions) {
        setToPrimaryData();
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedMarketsData, extensions]);

  //set primary bundle and requested bundle if it exists
  useEffect(() => {
    if (marketBundles && primaryEntitlements && marketdataProviders && localState.markets && !primaryBundle) {
      const bundle = setChosenButton(localState.markets ? localState.markets : []);
      const primBundle = setChosenButton(primaryEntitlements);
      setPrimaryBundle(primBundle);
      setButtonItem(bundle);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketBundles, primaryEntitlements, marketdataProviders, localState.markets, primaryBundle]);

  const setToPrimaryData = () => {
    const primeMarkets = getMarketsFromExt("ext");
    let markets = getMarketsFromExt("ext");
    if (!markets) markets = [];
    const addMarkets = [];
    const removeMarkets = getMarketsFromExt("remove");
    // set add markets
    if (requestedMarketsData.status !== "COMPLETED") {
      getMarketsFromExt("add").forEach(m => {
        if (!getMarketsFromExt("ext").includes(m)) {
          addMarkets.push(m)
        }
      })
    }
    // set localState markets
    if (!!removeMarkets.length) {
      markets = markets.filter(m => !removeMarkets.includes(m))
    }
    if (!!addMarkets.length) {
      addMarkets.forEach(m => !markets.includes(m) && markets.push(m))
    }
    const getEffectiveDate = () => {
      if (moment(requestedMarketsData.schedule.duedate) < moment(new Date()))
        return moment().add(1, "M").startOf("month").format("YYYY-MM-DD");
      return requestedMarketsData.schedule.duedate;
    }
    setLocalState({...localState, markets: markets, addMarkets, effectiveDate: getEffectiveDate(), removeMarkets: removeMarkets});
    setPrimaryEntitlements(primeMarkets);
  };

  const renderNoMarketsPopup = () => {
    const onBtnClick = (onYesClicked) => {
      if (!onYesClicked) {
        const {name, reference, depRef} = lastEntitlmentData;
        onMarketStatusChange(name, true, reference, depRef);
        setLastEntitlmentData(null)
      }
      setShowNoMarketsPopup(false)
    }
    if (showNoMarketsPopup) return (
      <div className="no-markets-popup">
        <div className="no-markets-black"/>
        <div className="no-markets-header">Remove all Market Data subscriptions?</div>
        <div className="no-markets-body">Do you really want to unsubscribe from all Market Data Entitlements?</div>
        <div  className="no-markets-buttons">
          <button className="btn btn-danger w-25 mx-1" onClick={() => onBtnClick(true)}>Yes</button>
          <button className="btn btn-outline-secondary w-25 mx-1" onClick={() => onBtnClick(false)}>No</button>
        </div>
      </div>
    )
  }

  const getMarketsFromExt = (type) => {
    let markets = [];
    if (type === "remove" && requestedMarketsData.ext.removeMarkets.map(m=> m.market)) 
      markets = requestedMarketsData.ext.removeMarkets.map(m=> m.market);
    if (type === "add" && requestedMarketsData.ext.addMarkets?.map(m=> m.market))
      markets = requestedMarketsData.ext.addMarkets?.map(m=> m.market);
    if (type === "ext" && extensions.find(e => e.code === "trading/marketdata")?.ext.markets.map(m=> m.market))
      markets = extensions.find(e => e.code === "trading/marketdata")?.ext.markets.map(m=> m.market);
    return markets;
  };

  const convertToSendData = (isForAdd) => {
    if (isForAdd) {
      return filterMarketsToTP()?.map(f => {
        if (localState.addMarkets?.includes(f.name))
          return {group: f.group, market: f.name}
        return null;
      }).filter(f=>f);
    } else {
      return filterMarketsToTP()?.map(f => {
        if (localState.removeMarkets?.includes(f.name))
          return {group: f.group, market: f.name}
        return null;
      }).filter(f=>f);
    }
  }

  const setChosenButton = (markets, silenced = false) => {
    let newMarketArray = []
    const filteredMarkets = marketdataProviders.map(m=> {
      if (m.tradingPlatformRefs.includes(getTPRefByName(currentAccount.software, currentAccount.softwareType)))
        return m
      return null
    }).filter(m=>m)
    for (let i = 0; i < filteredMarkets.length; i++) {
      const el = filteredMarkets[i];
      if (markets.some(m => m === el.name)) {
        newMarketArray.push(el)
      }
    }
    let result = null;
    for (let i = 0; i < marketBundles.length; i++) {
      const b = marketBundles[i];
      if (newMarketArray.every(m => m.bundle.includes(b.name))) {
        const allMarketsLength = filteredMarkets.map(m => m.bundle.includes(b.name)).filter(m=>m);
        const newMarketLength = newMarketArray;
        if (allMarketsLength.length === newMarketLength.length) {
          result = b.name
          break;
        } else  {
          result = "Custom"
        }
      } else  {
        result = "Custom"
      }
    }
    !silenced && setButtonItem(result);
    return result;
  }

  const onMarketStatusChange = (name, value, reference, depRef) => {
    let newMarkets = localState.markets ? [...localState.markets] : [];
    const newRemoveMarkets = [];
    const newAddMarkets = [];
    if (value) {
      if (depRef.length){
        depRef.forEach(ref=> {
          newMarkets.push(filterMarketsToTP().find(m => m.reference === ref).name)
        })
      }
      newMarkets.push(name);
    } else {
      if (reference) {
        let marketsToFilter = []
        for (let i = 0; i < filterMarketsToTP().length; i++) {
          const el = filterMarketsToTP()[i];
          if (el.dependencyRefs.includes(reference)) {
            marketsToFilter.push(el.name)
          }
        }
        marketsToFilter.forEach(f => {
          newMarkets = newMarkets.filter((m) => {
            if (m !== f) return m
            return null;
          });
        })
      }
      newMarkets = newMarkets.filter((m) => {
        if (m !== name) return m
        return null;
      });
    }
    
    // set addMarkets and removeMarkets
    filterMarketsToTP().forEach(m => {
      if (primaryEntitlements.includes(m.name) && !newMarkets?.includes(m.name))
        newRemoveMarkets.push(m.name);
      if (!primaryEntitlements?.includes(m.name) && newMarkets?.includes(m.name))
        newAddMarkets.push(m.name)
    })
    if (newMarkets.length === 0) {
      setShowNoMarketsPopup(true);
      setLastEntitlmentData({name, value, reference, depRef})
    }
    setLocalState({ ...localState, markets: newMarkets, addMarkets: newAddMarkets, removeMarkets: newRemoveMarkets});
    setDataWasChanged(true);
  };

    const renderBundleBlock = useMemo(() => {
    if (marketBundles) {
      const bundleButtons = marketBundles.map((m, inx) => {
        return ({name: m.name, value: inx})
      })
      bundleButtons.push({name: "Custom", value: bundleButtons.length, disabled: true})

      const onBundleButtonClick = (e, name) => {
        setButtonItem(name);
        const newMarkets = filterMarketsToTP()?.map(m => {
          if (m.bundle.includes(name))
            return m.name
            return null;
          }).filter(m=>m);
        const removeMarkets = []
        const addMarkets = [];
        filterMarketsToTP().forEach(m => {
          if (primaryEntitlements.includes(m.name) && !newMarkets?.includes(m.name))
            removeMarkets.push(m.name);
          if (!primaryEntitlements?.includes(m.name) && newMarkets?.includes(m.name))
            addMarkets.push(m.name)
        })
        setDataWasChanged(true);
        setLocalState({ ...localState, addMarkets, markets: newMarkets, removeMarkets})
      };

      return (
        <Bundles
         primaryBundle={primaryBundle}
         dataWasChanged={dataWasChanged}
         buttonItem={buttonItem}
         bundle={bundleButtons}
         onBundleButtonClick={onBundleButtonClick}
         applicationIsBlocked={applicationIsBlocked}
        />
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState, dataWasChanged, applicationIsBlocked, buttonItem, primaryBundle]);

  const filterMarketsToTP = () => {
    if (currentAccount && marketdataProviders ) {
      const result = marketdataProviders.map(m=> {
        if (m.tradingPlatformRefs.includes(getTPRefByName(currentAccount.software, currentAccount.softwareType)))
          return m;
        return null;
      }).filter(m=>m)
      return result
    }
  }

  const renderMarketsBlock = useMemo(() => {
    if (marketdataProviders) {
      let groups = [];
      for (let i = 0; i < marketdataProviders.length; i++) {
        const el = marketdataProviders[i];
        if (!groups.includes(el.group)) groups.push(el.group);
      }

      return (
      <div className="marketdata-main-block">
        {groups.map((g, inx) => (
          <MarketDataItem
            title={g}
            key={inx}
            primaryEntitlements={primaryEntitlements}
            onMarketStatusChange={onMarketStatusChange}
            entitlements={localState?.markets}
            marketItems={filterMarketsToTP()}
            requestedMarketsData={requestedMarketsData}
            applicationIsBlocked={applicationIsBlocked}
          />
        ))}
      </div>
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestedMarketsData,
    localState,
    currentAccount,
    marketBundles,
    applicationIsBlocked,
    primaryEntitlements,
  ]);

  const getTotalPrice = (markets) => {
    if (!markets) return;
    const marketsToTP = filterMarketsToTP();
  
    let total = 0;
    markets.forEach((market) => {
      const match = marketsToTP.find((m) => m.name === market);
      if (match) total += match.price;
    });
  
    return utilityFunctions.currencyFormater().format(total);
  };

  const renderChangeConfirmationWindow = useMemo(() => {
    const onDateChange = (e) => {
      if (moment(e.target.value).isBefore(moment().add(1, "year")) && moment(e.target.value).isAfter(moment())) {
        setDataWasChanged(true);
        setLocalState({ ...localState, effectiveDate: e.target.value})
      }
    }

    return (
        <MarketConfirmWindow
          buttonItem={buttonItem}
          getTotalPrice={getTotalPrice}
          primaryEntitlements={primaryEntitlements}
          localState={localState}
          onDateChange={onDateChange}
          requestedMarketsData={requestedMarketsData}
          dataWasChanged={dataWasChanged}
          isNewApplication={isNewApplication}
        />
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestsItems, dataWasChanged, primaryBundle, currentAccount, requestedMarketsData, localState, buttonItem]);

  return (
    <div className="marketdata-wrapper">
      <div className="d-flex justify-content-center">
        <div className="d-inline-block mb-3">
          {renderBundleBlock}
          {renderMarketsBlock}
        </div>
      </div>
       <div className="d-flex justify-content-center">
        {renderChangeConfirmationWindow}
      </div>
      {renderNoMarketsPopup()}
    </div>
  );
};

export default MarketDataPage;
