import React, {useContext, useEffect} from "react"
import {ModalContext} from "../../modal/ModalContext";
import { NewRequestContext } from "./NewRequestContext";
import {useHistory} from "react-router-dom";
import traderapi from "../../../traderapi/api";

const Confirmation = () => {
  const {hideModal, setModalSize} = useContext(ModalContext);
  const {entitlementsData, isNewApplication, requestedMarketData,
    confirmationType
  } = useContext(NewRequestContext);
  const history = useHistory()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setModalSize('sm'), []);

  const cancelRequest = () => {
    const body = {};
    body.date = entitlementsData.duedate;
    body.status = "CANCELED"
    traderapi.createUpdateMDRequest(isNewApplication, body, requestedMarketData._id);
    hideModal();
  }

  const setBodyText = () => {
    switch (confirmationType) {
      case "cancel-request":
        return "Do you really want to cancel this market data request?";
      case "unsubscribe":
        return "Do you really want to unsubscribe from all market Data Entitlements?";
      default: return "";
    }
  }

  return (
    <>
      <div>
        {setBodyText()}
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button
          type="button"
          className="btn btn-danger w-25 mx-1"
          onClick={cancelRequest}
        >YES
        </button>
        <button
          type="button"
          className="btn btn-outline-secondary w-25 mx-1"
          onClick={() => {
            history.push({
              pathname: '/details-mnu',
              state: {header: 'New Request on behalf of Client: Market Data'}
            })
          }}
        >
          No
        </button>
      </div>
    </>
  )
}

export default Confirmation
