import React from "react";
import DropdownMenu from "../dropdown-menu/DropdownMenu";

import './DatasetToolbox.css'

const DatasetToolbox = ({dataset, toggleColumnEditor,  options, className}) => {
    const toggleMoreItem = (
        <div className="more"><i className="material-icons">more_vert</i></div>
    )

    const ifshow = (f) => {
        if((options[f] || options['all']) && !(options[`!${f}`])) {
            return true;
        }
        return false;
    }

    return (
        <div className={`dataset-toolbox ${className}`}>
            {ifshow('filter') && (<div className="filter" onClick={options.filter}><i className="material-icons">filter_alt</i></div>)}
            {ifshow('bookmark') && (<div className="bookmark" onClick={options.bookmark}><i className="material-icons">bookmark_border</i></div>)}
            {ifshow('refresh') && (<div className="refresh" onClick={options.refresh}><i className="material-icons">refresh</i></div>)}
            {ifshow('download') && (<div className="download" onClick={options.download}><i className="material-icons">download</i></div>)}
            {ifshow('settings') && (<div className="settings" onClick={options.settings}><i className="material-icons">settings</i></div>)}
            {ifshow('print') && (<div className="print" onClick={options.print}><i className="material-icons">print</i></div>)}
            {ifshow('extra') && (
            <DropdownMenu
                menuItems={[
                    (<div className="export"><i className="material-icons">unarchive</i>export</div>),
                    (<div className="import"><i className="material-icons">archive</i>import</div>)
                ]}
                toggleItem={toggleMoreItem}

            />)}
        </div>
    )
}

export default DatasetToolbox;
