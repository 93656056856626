import React from "react";
import {Store} from "../../flux";
import Toast from "./Toast";
import './Toaster.css'

class Toaster extends React.Component {
  constructor(props) {
    super(props);

    this.onToastsChanged = this.onToastsChanged.bind(this);
    this.state = {toasts: Store.getToasterElements()};
  }

  onToastsChanged(){
    const toasts = Store.getToasterElements()
    this.setState({toasts})
  }

  componentDidMount() {
    Store.addToasterListener(this.onToastsChanged)
  }

  componentWillUnmount() {
    Store.removeToasterListener(this.onToastsChanged)
  }

  render() {
    return (<div className="toaster">
      {this.state.toasts.map((toastData, index) => {
        if(toastData){
          return (<Toast key={index} data={toastData} index={index}/>)
        }
        return null
      })}
    </div>)
  }
}

export default Toaster;
