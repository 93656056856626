import React, {useState} from "react";
import ReactDOM from 'react-dom';
import Modal from "./Modal";

export const ModalContext = React.createContext({});
export const ModalContextProvider = ({children}) => {
  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  const [modalSize, setModalSize] = useState('md');

  const showInModal = (content) => {
    if (content) {
      setModalContent(content)
    }
    setVisible(true)
  }

  const hideModal = () => {
    setVisible(false)
  }

  return (
    <>
      <ModalContext.Provider value={{showInModal, hideModal, modalSize, setModalSize}}>
        {children}
        {visible && ReactDOM.createPortal(
          <Modal>
            <>
              {modalContent}
            </>
          </Modal>, document.body
        )}
      </ModalContext.Provider>
    </>
  );
};
