import React, { useEffect, useState } from "react";
import { Container, Row, CardHeader, Col, Card, CardBody, Form  } from "shards-react";

import { Dispatcher,Events, Store } from "../flux";
import DialogPopup from "../components/dialog-popup/DialogPopup";
import { withRouter } from "react-router";
import DatasetToolbox from "../components/dataset-toolbox/DatasetToolbox";
import traderapi from "../traderapi/api";
import F from "../flux/fields";
import constants from "../flux/constants";
import { utilityFunctions } from "../utils/utilityFunctions";
import EditFormItem from "./EditFormItem";

import './EditApplication.css';

const { reviewStatuses } = constants;
const { OK, DEFICIENT, UPDATED } = reviewStatuses;


const EditApplication = ({header, country, currentItemToEdit, dataset, disabledStatus, setDisabledStatus, history}) => {
  const [currentUserData, setCurrentUserData] = useState(null);
  const [localValues, setLocalValues] = useState({});
  const [countries, setCountries] = useState(country);
  const [popupData, setPopupData] = useState({});
  const [review, setReview] = useState([]);
  const [dataWasChanged, setDataWasChanged] = useState(false);
  const [reviewDataWasChanged, setReviewDataWasChanged] = useState(false);
  const [popupWindowState, setPopupWindowState] = useState();
  const [formIsEditing, setFormIsEditing] = useState(false);
  const [clickedRefreshForm, setClickedRefreshForm] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState(null);

  const dialogWindowSetter = () => {
    setPopupWindowState(Store.getDialogWindowState())
  };
  const setOpenedDialog = (value) => {
    Dispatcher.dispatch({actionType: Events.TOGGLE_DIALOG_WINDOW, payload: value});
  };
  const downHandler = (e) => {
  };
  //set changelog
  useEffect(() => {
    if (currentUserData?.["changelog"]?.list === null || !currentUserData?.["changelog"]?.list.length) {
      getChangeLog();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserData]);
  //get countries
  useEffect(() => {
    if (!countries) {
      getCountries()
    }
  }, [countries])

  useEffect(() => {
    //get request
    if (currentUserData?.entitlements?.old) {
      getMarketDataProviders()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserData?.id?.id]);

  const getChangeLog = async() => {
    if (currentUserData && (dataset === 'a' || dataset === 'v/ops' || dataset === 'v/trader')) {
      const result = await traderapi.getChangeLog(currentUserData.id.id);
      const newChangeLog = {...currentUserData.changelog, list: (result.items.map((c)=>(`- ${utilityFunctions.tableTimeFormat(c.date, true)}.: ${c.message};`)) || [])}
      setCurrentUserData({...currentUserData, changelog: newChangeLog})
    }
  };
  const getCountries = async() => {
    traderapi.getCountries((data) => {
      setCountries(data.countries)
    })
  }
  const getMarketDataProviders = async() => {
    if (currentUserData && dataset === 'a') {
      const result = await traderapi.getExt(currentUserData.id.id);
      let newCurrentUserData = {...currentUserData, entitlements: {...currentUserData.entitlements, old:false}};
      if (result.success && result.list.find(i=>i.code === "trading/marketdata")) {
        const newMarketsList = result.list.find(i=>i.code === "trading/marketdata").ext.markets.map(m=>`${m.group}-${m.market}`);
        newCurrentUserData.entitlements.list = newMarketsList;
        setCurrentUserData(newCurrentUserData)
      }
    }
  };

  useEffect(() => {
    if (currentItemToEdit) {
      setCurrentUserData(currentItemToEdit)
    }
  }, [currentItemToEdit]);


  useEffect(() => {
    Store.addWinPopListener(dialogWindowSetter);
    window.addEventListener("keydown", downHandler);
    return () => {
      Store.removeWinPopListener(dialogWindowSetter);
      window.removeEventListener("keydown", downHandler);
    }
  }, []);

  useEffect(() => {
    if (currentUserData) {
      setLocalValues(utilityFunctions.deepCopyFunction(currentUserData));
      currentUserData.review?.value ? setReview(utilityFunctions.deepCopyFunction(currentUserData).review.value) : setReview([]);
      setApplicationStatus(currentUserData?.status?.value)
    }
  }, [currentUserData]);

  useEffect(() => {
    const unblock = history.block((loc, action) => {
      if(action === 'POP'  && Store.getPopupState()) {
      setOpenedDialog(true);
        Dispatcher.dispatch({actionType: Events.SET_REDIRECTED_URL, payload: `/${history.location.pathname.split("/")[1]}`});
        return false;
      }
      return true;
    })
    return () => {
      unblock();
    }
  }, [history, localValues]);

  const disableAllSupportiveFn = () => {
    setDataWasChanged(false);
    setFormIsEditing(false)
    setDisabledStatus(true);
    setOpenedDialog(false);
    setPopupData({})
  }

  const onCancelButtonClick = (e, clickedFromDialogPopup) => {
    const url = Store.getRedirectedURL();
    e.preventDefault();
    Dispatcher.dispatch({
      actionType: Events.TOGGLE_POPUP,
      payload: false
    });
    disableAllSupportiveFn();
    setReviewDataWasChanged(false);
    setLocalValues(() => utilityFunctions.deepCopyFunction(currentUserData));
    setReview(() => utilityFunctions.deepCopyFunction(currentUserData).review.value)
    if (clickedFromDialogPopup) {
      if (url === '/trigger-logout') return traderapi.logout(true);
      url.length > 0 && history.push(url);
      Dispatcher.dispatch({actionType: Events.SET_REDIRECTED_URL, payload: ''});
    }
  };

  const onStayButtonClick = () => {
    setOpenedDialog(false);
    setClickedRefreshForm(false);
    setPopupData({})
  }

  const onSaveButtonClick = (e, clickedFromDialogPopup) => {
    const url = Store.getRedirectedURL();
    e.preventDefault();
    Dispatcher.dispatch({
      actionType: Events.TOGGLE_POPUP,
      payload: false
    });
    if (localValues.status.value === "RequireInformation" && review.every(r => r.status === OK)) {
      localValues["status"].value = "Updated";
        Dispatcher.dispatch({
          actionType: Events.SAVEDATASET,
          payload: { dataset, n: localValues, o: currentUserData },
        });
    } else {
      if (dataWasChanged) {
        Dispatcher.dispatch({
          actionType: Events.SAVEDATASET,
          payload: { dataset, n: localValues, o: currentUserData },
        });
      }
    }
    if (reviewDataWasChanged) {
      traderapi.saveApplicationReview((cb) => {console.log(cb)}, review, currentUserData.id.id)
    }
    disableAllSupportiveFn();
    if (clickedFromDialogPopup) {
      if (url === '/trigger-logout') return traderapi.logout(true);
      url.length > 0 && history.push(url);
      Dispatcher.dispatch({actionType: Events.SET_REDIRECTED_URL, payload: ''});
    }
  }
  const onApproveButtonClick = (fromDialog) => {
    if (fromDialog) {
      const newValues = localValues;
      newValues["status"].value = "Approved";
      Dispatcher.dispatch({
        actionType: Events.SAVEDATASET,
        payload: { dataset, n: newValues, o: currentUserData },
      });
      disableAllSupportiveFn();
    } else {
      setOpenedDialog(true);
      setPopupData({cb: onApproveButtonClick,type:"approve", text: `Approve the Application of ${localValues.title.value} ${localValues.firstName} ${localValues.lastName}?`})
    }
  }

  const onRejectButtonClick = (fromDialog) => {
    if (fromDialog) {
      const newValues = localValues;
      newValues["status"].value = "Rejected";
      Dispatcher.dispatch({
        actionType: Events.SAVEDATASET,
        payload: { dataset, n: newValues, o: currentUserData },
      });
      disableAllSupportiveFn();
    } else {
      setOpenedDialog(true);
      setPopupData({cb: onRejectButtonClick,type:"reject", text: `Reject the Application of ${localValues.title.value} ${localValues.firstName} ${localValues.lastName}?`})
    }
  };

  // allow comment field only in "v" and "a" datasets
  const checkDataSet = () => {
    const href = window.location.href.split("/");
    return href.includes("v") || href.includes("a");
  };

  const onChangeValueHandler = (value, valueName) => {
    const newValues = localValues;
    if (localValues[valueName] && typeof localValues[valueName] === 'object') {
      newValues[valueName].value = value;
    } else {
      if (["country", "citizenship"].includes(valueName)) {
        const found = countries.find(c => c.name === value);
        newValues[valueName] = found.name;
      } else {
        newValues[valueName] = value;
      }
    }
    if (utilityFunctions.compareTwoObjectValues(newValues, currentUserData)) {
      setDisabledStatus(true);
      setDataWasChanged(false);
    } else {
      setDataWasChanged(true);
      setDisabledStatus(false);
    }
    setFormIsEditing(true)
    setLocalValues({...newValues});
  };

  const onReviewChangeHandler = (field, comment, isImage, onTrashClicked) => {
    const newReview = [...review];
    const newObj = {
      field: utilityFunctions.transformToNestedField(field, isImage),
      status: !!comment.length ? DEFICIENT : OK,
      comment
    }
      if (newReview.find(item => utilityFunctions.transfromFromNestedField(item.field, isImage) === field)) {
        const foundIndex = newReview.findIndex(item => utilityFunctions.transfromFromNestedField(item.field, isImage) === field)
        newReview.splice(foundIndex, 1, newObj);
        setReview(newReview)
      } else {
        newReview.push(newObj)
        setReview(newReview)
      }
    if (utilityFunctions.compareTwoObjectValues(newReview, currentUserData.review, true) && !onTrashClicked) {
      setReviewDataWasChanged(false);
      setDisabledStatus(true);
    } else {
      setDisabledStatus(false);
      setReviewDataWasChanged(true);
    }
  };
  const renderEditFormItems = React.useMemo(() => Object.entries(localValues ?? {}).map((item, index) => {
    if (item[0] === 'scans' && item[1] !== undefined) {
      return item[1].images?.map((image, index2) => {
        const reviewForImageField = review.find(
          (r) => utilityFunctions.transfromFromNestedField(r.field) === image?.name?.split(":")[0]
        );
        return (
          <EditFormItem
            onChangeValueHandler={onChangeValueHandler}
            setFormIsEditing={setFormIsEditing}
            onReviewChangeHandler={onReviewChangeHandler}
            review={reviewForImageField}
            formIsEditing={formIsEditing}
            userID={currentUserData.id.id}
            checkDataSet={checkDataSet}
            isImage={true}
            value={image}
            label={image.name?.split(':')[0]}
            key={index + index2}
          />
        )
      })
    }
    if(item[1]?.isSeparator) {
      return (
        <div className="separator-block" key={index}>{utilityFunctions.changeLabelView(item[1].label)}</div>
      )
    }
    const reviewForField = review.find(
      (r) => utilityFunctions.transfromFromNestedField(r.field) === item[0]
    );
    if (item[0] !== "review") {
      if ((["country", "citizenship"].includes(item[0])) && countries) {
        if (dataset !== "r") {
          const allowedCountries = countries.map((c) => c.allowtrade ? c.name : null).filter(n=>n);
          const changeValueView = countries.find(c => c.name === item[1])?.name || countries.find(c => c.code === item[1])?.name;
          let newItem;
          if(!!changeValueView) {
            newItem = {isEnum: true, value: changeValueView, values: allowedCountries}
          } else {
            newItem = { isValue: true, value: `${item[1]}`, badvalue: true};
          }

          item[1] = newItem;
        } else {
          const countryName = countries.find(c => c.code === item[1])?.name;
          item[1] = { isValue: true, value: countryName, readonly: true};
        }
      };
      return (
        <EditFormItem
          key={index}
          onChangeValueHandler={onChangeValueHandler}
          formIsEditing={formIsEditing}
          checkDataSet={checkDataSet}
          review={reviewForField}
          userID={currentUserData.id.id}
          setFormIsEditing={setFormIsEditing}
          onReviewChangeHandler={onReviewChangeHandler}
          label={item[0]}
          value={item[1]}
        />
      )
    }
    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [currentUserData, disabledStatus, localValues, onChangeValueHandler]);

  const onBackClickHandler = () => {
    let newPathName = window.location.pathname.split("/");
    newPathName.pop();
    const result = newPathName.map(item => `${item}/`);
    if (!disabledStatus && dataWasChanged && reviewDataWasChanged) {
      Dispatcher.dispatch({actionType: Events.SET_REDIRECTED_URL, payload: `/${dataset}`});
      setOpenedDialog(true);
    } else {
      if (document.referrer.split("/")[document.referrer.split("/").length - 1].length < 20)
        return window.location.href = result.join("");
      setDisabledStatus(true);
      return window.history.back();
    }
  }
  const onFormRefreshClickHandler = () => {
    Dispatcher.dispatch({
      actionType: Events.OPENREFERENCE,
      payload: {
        dataset,
        id: currentUserData.id.id
      }
    })
    Dispatcher.dispatch({
      actionType: Events.TOGGLE_POPUP,
      payload: false
    });
    setOpenedDialog(false);
    setDataWasChanged(false);
    setDisabledStatus(true);
    setFormIsEditing(false);
    setClickedRefreshForm(false);
  };
  const onRequireInfoClick = (fromDialog) => {
    if (typeof fromDialog === "boolean" && fromDialog) {
      const newValues = localValues;
      newValues["status"].value = "RequireInformation";
      Dispatcher.dispatch({
        actionType: Events.SAVEDATASET,
        payload: { dataset, n: newValues, o: currentUserData },
      });
      traderapi.saveApplicationReview((cb) => {console.log(cb)}, review, currentUserData.id.id)
      disableAllSupportiveFn();
    } else {
      setOpenedDialog(true);
      setPopupData({cb: onRequireInfoClick,type:"require", text: `Send the comments to require more information for the Application of ${localValues.title.value} ${localValues.firstName} ${localValues.lastName}?`})
    }
  };

  const renderEditSaveButtons = () => {
    const showApproveButtonsOnStatus = ["Received", "Updated"]
    if (!!applicationStatus && applicationStatus !== "RequireInformation" && !!review.length && review.some(i => i.status === DEFICIENT)) {
        return (
          <>
          <button onClick={onRequireInfoClick}>Require info</button>
          <button onClick={onCancelButtonClick}>Cancel</button>
          </>
        )
      }
      if (!disabledStatus && (reviewDataWasChanged || dataWasChanged)) {
        return (
          <>
          <button onClick={onSaveButtonClick}>Save</button>
          <button onClick={onCancelButtonClick}>Cancel</button>
          </>
        )
      }
      if (
          (!!review.length && review.every(i => i.status === OK) && showApproveButtonsOnStatus.includes(applicationStatus))
          || (review.length === 0 && applicationStatus === "Received")
        ){
        return (
          <>
          <button onClick={(e) => onApproveButtonClick()}>Approve</button>
          <button onClick={(e) => onRejectButtonClick()}>Reject</button>
          </>
        )
      }
  };
  const onClearClick = (fromDialog) => {
    if (fromDialog) {
      const newReview = review.map(r => {
        if (r.status === UPDATED || r.status === DEFICIENT) {
          return ({...r, status: OK, comment: "" })
        }
        return r
      });
      Dispatcher.dispatch({
        actionType: Events.TOGGLE_POPUP,
        payload: false
      });
      if (localValues["status"].value !== "Updated") {
        const newValues = {...localValues};
        newValues["status"].value = "Updated";
        Dispatcher.dispatch({
          actionType: Events.SAVEDATASET,
          payload: { dataset, n: newValues, o: currentUserData },
        });
      }
      if (!!currentUserData.review.value.length && currentUserData.review.value.some(r => (r.status === UPDATED || r.status === DEFICIENT))) {
        setCurrentUserData({...currentUserData, review: F.createColumnInCSV(newReview, {noDownload: true})})
        traderapi.saveApplicationReview((cb) => {console.log(cb)}, newReview, currentUserData.id.id)
      }
      setReview(newReview);
      disableAllSupportiveFn();
    } else {
      setOpenedDialog(true);
      setPopupData({cb: onClearClick,type:"clear", text: `Are you sure you want to clear all comments in this application ?`})
    }
  }
  const renderClearCommentsButton = () => {
    if (!!review.length && review.some(i => (i.status === UPDATED || i.status === DEFICIENT)))
    return (
      <button className="clear-comments" onClick={(e) => onClearClick(false)}>Clear comments</button>
    )
  }
  const renderBriefUserData = () => {
    const reviewNumber = () => {
      if (review.length && review.some(i => (i.status === UPDATED || i.status === DEFICIENT))) {
        const number = review.map(r=> {
          if(r.status === UPDATED || r.status === DEFICIENT) return r
          return null;
        }).filter(r=>r).length;
        return <span className="header-user-data-reviews"><span>Issues: {number}</span></span>
      }
    }
    if (currentUserData) return (
      <div className="brief-header-user-data">
        <div className="divider"/>
        <span>{currentUserData.firstName} {currentUserData.lastName}</span>
        <div className="divider"/>
        <span>{checkDataSet() ? currentUserData.status.value : currentUserData.step}</span>
        <div className="divider"/>
        <span>{checkDataSet() ? currentUserData.referenceClient.value : currentUserData.email}</span>
        <div className="divider"/>
        {reviewNumber()}
      </div>
    )
  }

  return(
    <DialogPopup
      openedDialog={popupWindowState}
      onCancelButtonClick={onCancelButtonClick}
      onSaveButtonClick={onSaveButtonClick}
      onStayButtonClick={onStayButtonClick}
      onFormRefreshClickHandler={onFormRefreshClickHandler}
      clickedRefreshForm={clickedRefreshForm}
      popupData={popupData}
    >
      <Container fluid className="main-content-container search_processor">
        <Row className="edit-row-block">
          <Col className="px-0">
            <Card className="edit-form-card-block">
              <CardHeader className="border-bottom table-title">
                <i className="material-icons keyboard_return pointer normal" onClick={onBackClickHandler}>keyboard_return</i>
                <h3 className="m-0 pointer" onClick={onBackClickHandler}>{header}:</h3>
                {renderBriefUserData()}
                <div className={`edit-save-block`}>
                  {renderEditSaveButtons()}
                  {renderClearCommentsButton()}
                </div>
                <DatasetToolbox
                  options={
                    {
                      refresh: ()=> {
                        if (Store.getPopupState()) {
                          setClickedRefreshForm(true);
                          setOpenedDialog(true);
                        } else {
                          onFormRefreshClickHandler();
                        }
                      }
                    }
                  }
                >

                </DatasetToolbox>
              </CardHeader>
              <CardBody className="edit-form-card-body">
                <Form className="edit-post">
                  {renderEditFormItems}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </DialogPopup>
  )
};

export default withRouter(EditApplication);



