import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardBody } from "shards-react";

import Chart from "../../utils/chart";
import {Store} from "../../flux";
import moment from 'moment';


class ApplicationsOverview extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    this.onDataChanged = this.onDataChanged.bind(this);
    this.state = {};
  }

  getDays(created, Ndays) {
    const days = new Array(Ndays).fill(0);

    Object.entries(created).forEach((e)=>{
      var dd = Math.round(moment().diff(moment(e[0], "YYYY-MM-DD"), 'days'));
      if(dd<days.length && dd>=0) {
        days[dd] += e[1];
      }
    });
    
    return days;
  }

  componentDidMount() {
    Store.resetDatasetListener(this.onDataChanged, true);
    this.onDataChanged();
  }

  componentWillUnmount() {
    Store.resetDatasetListener(this.onDataChanged, false);
  }

  accumulateIter(srcA) {
    const a = srcA.map((_)=>(_));
    a.forEach((e,i)=>{ a[i] = (i===0)?e:e+a[i-1]; });
    return a;
  }

  onDataChanged() {
    const { dataget } = this.props;
    const data = dataget();
    if(!data || data.length===0) {
      return;
    }

    const Ndays = 4*7; // 2 * 4weeks
    const days = this.getDays(data, 2*Ndays); 
    const curMonth = this.accumulateIter(days.slice(0, Ndays).reverse());
    const prevMonth = this.accumulateIter(days.slice(Ndays, Ndays*2).reverse());

    const overviewData = {
      labels: curMonth.map((_, i) =>((i===Ndays-1)? 'today' : -(Ndays - i- 1))),
      datasets: [
        {
          label: "4 weeks",
          fill: "start",
          data: curMonth,
          backgroundColor: "rgba(0,123,255,0.1)",
          borderColor: "rgba(0,123,255,1)",
          pointBackgroundColor: "#ffffff",
          pointHoverBackgroundColor: "rgb(0,123,255)",
          borderWidth: 1.5,
          pointRadius: 0,
          pointHoverRadius: 3
        },
        {
          label: "Previous 4 weeks",
          fill: "start",
          data: prevMonth,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgba(255,65,105,1)",
          pointBackgroundColor: "#ffffff",
          pointHoverBackgroundColor: "rgba(255,65,105,1)",
          borderDash: [3, 3],
          borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 2,
          pointBorderColor: "rgba(255,65,105,1)"
        }
      ]
    };
  
    const chartOptions = {
      ...{
        responsive: true,
        legend: {
          position: "top"
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
            }
          ],
          yAxes: [
            {
              ticks: {
                //suggestedMax: 5,
                callback(tick) {
                  if (Math.floor(tick) === tick) {
                    return tick;
                  }
                }
              }
            }
          ]
        },
        hover: {
          mode: "nearest",
          intersect: false
        },
        tooltips: {
          custom: false,
          mode: "nearest",
          intersect: false
        }
      },
      ...this.props.chartOptions
    };

    const BlogUsersOverview = new Chart(this.canvasRef.current, {
      type: "LineWithLine",
      data: overviewData,
      options: chartOptions
    });

    // They can still be triggered on hover.
    const buoMeta = BlogUsersOverview.getDatasetMeta(0);
    buoMeta.data[0]._model.radius = 0;
    buoMeta.data[overviewData.datasets[0].data.length - 1]._model.radius = 0;

    // Render the chart.
    BlogUsersOverview.render();
  }

  render() {
    const { title } = this.props;
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          <canvas
            height="80"
            ref={this.canvasRef}
            style={{ maxWidth: "100% !important" }}
          />
        </CardBody>
      </Card>
    );
  }
}

ApplicationsOverview.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart dataset.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};

ApplicationsOverview.defaultProps = {
  title: "Applications Overview",
};

export default ApplicationsOverview;
