import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { ModalContext } from "../../modal/ModalContext";
import EntitlementsBlock from "./EntitlementsBlock";
import { NewRequestContext } from "./NewRequestContext";
import traderapi from "../../../traderapi/api";
import { pushToast } from "../../toaster/helper";

import "./request-dialog.css";

const DetailsMenu = ({traderInfo}) => {
  const history = useHistory();
  const { hideModal, setModalSize } = useContext(ModalContext);
  const {
    traderId,
    setConfirmationType,
    setIsNewApplication,
    source,
    setSource,
    requestedItems,
    entitlementsData,
    isNewApplication,
    requestedMarketData,
  } = useContext(NewRequestContext);

  const createMarketDataRequest = () => {
    const body = { ...entitlementsData };
    body.applicationID = traderId.id;
    if (isNewApplication) {
      traderapi.createUpdateMDRequest(isNewApplication, body);
    } else {
      if (requestedMarketData.status !== "OPEN") {
        body.status = "OPEN";
      }
      body.date = body.duedate;
      traderapi.createUpdateMDRequest(
        isNewApplication,
        body,
        requestedMarketData._id
      );
    }
    hideModal();
    pushToast(`New Market Data Request on behalf of ${traderId.value} ${traderId.label} was successfully made.`)
  };

  const setIsDisabledButton = () => {
    if (isNewApplication) {
      return (
        (!entitlementsData?.ext?.addMarkets?.length &&
          !entitlementsData?.ext?.removeMarkets?.length) ||
        (requestedMarketData &&
          moment(requestedMarketData?.schedule?.duedate).isSame(
            moment(entitlementsData?.date)
          ))
      );
    } else {
      // comparing two market arrays: the one that we got from back and other that is going to be send to back
      let marketsToCompare = ["addMarkets", "removeMarkets"];
      marketsToCompare.forEach((m, index) => {
        marketsToCompare.splice(
          index,
          1,
          compareArrays(
            requestedMarketData?.ext[m].map((i) => i.market),
            entitlementsData?.ext[m].map((i) => i.market)
          )
        );
      });
      return marketsToCompare.every((m) => m) && (requestedMarketData &&
        moment(requestedMarketData?.schedule?.duedate).isSame(
          moment(entitlementsData?.duedate)
        ));
    }
  };

  const onCancelRequestClick = (e) => {
    history.push({
      pathname: "/confirmation",
      state: { header: "Cancel Market Data request?" },
    });
    setConfirmationType("cancel-request");
  };

  useEffect(() => {
    if (requestedItems) {
      const result = requestedItems.some(
        (i) =>
          i.type === "MARKETDATA" &&
          (i.status === "OPEN" ||
            i.status === "ONHOLD" ||
            i.status === "INPROGRESS" ||
            i.status === "BLOCKED")
      );
      if (result) {
        setIsNewApplication(false);
        history.push({
          pathname: "/details-mnu",
          state: { header: "Change Request on behalf of Client: Market Data" },
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedItems]);

  const compareArrays = (arr1, arr2) => {
    const array2Sorted = arr2.slice().sort();
    return (
      arr1.length === arr2.length &&
      arr1
        .slice()
        .sort()
        .every((value, index) => value === array2Sorted[index])
    );
  };

  const renderTraderEditInfoPart = () => {
    const infoData1 = {
      "Date of initial request": moment(traderInfo?.createdAt).format("YYYY-MM-DD"),
      "Initial request received by": "Trader portal(static)",
      "Request ID": traderInfo?.sequenceNumber,
      "Status": traderInfo?.status,
    };
    const infoData2 = {
      "Trader ID": traderInfo?.applicationID.reference.client,
      "Platform": traderInfo?.applicationID.software,
      "Balance": "$234.34(static)",
    }
    const renderData = (arrray) => {
      return Object.entries(arrray).map((item, index) => {
        return (
          <div className="info-item" key={index}>
            <div className="font-weight-bold">{item[0]}: </div>
            <div>{item[1]}</div>
          </div>
        )
      })
    }
    if (isNewApplication)
      return (
        <div className="d-flex">
          <div className="w-25 d-flex flex-column justify-content-center">
            Trader ID
          </div>
          <div className="w-50" style={{overflow: 'hidden'}}>
            {traderId?.value} ({traderId?.label})
          </div>
          <div className="d-flex flex-column justify-content-center">
            Balance $777.77 (static)
          </div>
        </div>
      );
    return (
      <div className="trader-edit-info-block">
        <div className="info-box">{renderData(infoData1)}</div> 
        <div className="info-box">{renderData(infoData2)}</div>
      </div>
    )
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setModalSize('lg'), [])

  return (
    <>
      <div className="details-menu-block">
        <div className="d-flex mb-2">
          <div className="w-25 d-flex flex-column justify-content-center">
            Received by
          </div>
          {!isNewApplication ? (
            <div className="w-25">
              <select
                className="form-control"
                placeholder="Select source"
                value={source}
                onChange={(event) => setSource(event.target.value)}
              >
                <option value="" disabled>
                  Select source
                </option>
                <option value="phone">Phone</option>
                <option value="email">Email</option>
              </select>
            </div>
          ) : (
            <div className="w-50">{source}</div>
          )}
        </div>
        {renderTraderEditInfoPart()}
      </div>
      <div>
        <hr />
      </div>
      <div>
        <EntitlementsBlock />
      </div>
      <div>
        <hr />
      </div>
      <div className="d-flex justify-content-center">
        {!isNewApplication && (
          <button
            type="button"
            onClick={onCancelRequestClick}
            className="btn btn-danger w-25 mx-1"
          >
            Cancel Request
          </button>
        )}
        <button
          type="button"
          disabled={setIsDisabledButton()}
          className="btn btn-primary w-25 mx-1"
          onClick={() => {
              createMarketDataRequest();
          }}
        >
          {isNewApplication ? "Create" : "Apply changes"}
        </button>
        <button
          type="button"
          className="btn btn-outline-secondary w-25 mx-1"
          onClick={hideModal}
        >
          {isNewApplication ? "Cancel" : "Discard changes"}
        </button>
      </div>
    </>
  );
};

export default DetailsMenu;
