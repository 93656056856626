import React from "react";
import Tables from "./Tables";

const tableData = (options) => {
  class TableData extends React.Component {
    render() {
      return (
        <Tables 
          {...options}
          {...this.props}
          />
      )
    }
  }
  return TableData;
}

export default tableData;
