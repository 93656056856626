import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Row,
  Col
} from "shards-react";
import {Events, Store} from "../../flux";
import { utilityFunctions } from "../../utils/utilityFunctions";

const LIMITS = {
  idx: 0,
  levels: [5, 10, 25, 100]
};
  class RecentApplications extends React.Component {
    constructor(props) {
      super(props);
  
      this.onDataChanged = this.onDataChanged.bind(this);
      this.state = { applications: [], limitIndex: 0, crmurl: 'crm'};
    }
  
    componentDidMount() {
      Store.resetDatasetListener(this.onDataChanged, true);
      Store.resetEmitter(true, Events.USERDATA, this.onDataChanged);
      this.onDataChanged();
    }
  
    componentWillUnmount() {
      Store.resetDatasetListener(this.onDataChanged, false);
      Store.resetEmitter(false, Events.USERDATA, this.onDataChanged);
    }

    onDataChanged() {
      const { dataget } = this.props;
      const applications = dataget() || [];

      const crmurl = Store.getLoggedUser()?.configuration?.crmurl;

      this.setState({applications, crmurl})
    }

    generateCRMlink(app, crmurl) {
      if(app.reference?.crm?.id) {
        return (
        [" | ",
        <a 
          key={app.reference.crm.id}
          className="text-info" 
          href={`${crmurl}/${app.reference.crm.id}`}
          target="_blank" rel="noopener noreferrer"
        >
          open in CRM
        </a>
        ]
      );
      }
    }

    render() {
      const { applications, limitIndex, crmurl } = this.state;

      return (
      <Card small className="blog-comments">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{this.props.title}</h6>
        </CardHeader>

        <CardBody className="p-0">
          {applications.slice(0, LIMITS.levels[limitIndex]).map((app, idx) => (
            <div key={idx} className="blog-comments__item d-flex p-3">

              {/* Content */}
              <div className="blog-comments__content">
                {/* Content :: Title */}
                <div className="blog-comments__meta text-mutes">
                {`${(idx+1)}. `}
                  {/* <i className="material-icons">edit</i>{" "} */}
                  <a className="text-info" href={(process.env.REACT_APP_BASENAME || "")+"/a/"+app._id}>
                  
                    {[app.personal?.firstName, app.personal?.middleName, app.personal?.lastName].join(' ')}
                  </a>
                  {" is "}
                
                  {app.status}
                
                  {" - "}
                  <span className="text-mutes">  {utilityFunctions.tableTimeFormat(app.updatedAt)}</span>

                  {this.generateCRMlink(app, crmurl)}
                  
                </div>

              </div>
            </div>
          ))}
        </CardBody>

        {
          (limitIndex<LIMITS.levels.length-1) && (LIMITS.levels[limitIndex] < applications.length) && 
            (<CardFooter className="border-top">
            <Row>
              <Col className="text-center view-report">
                <Button theme="white" type="submit" onClick={()=>{
                  this.setState({limitIndex: (this.state.limitIndex+1)%LIMITS.levels.length})
                }}>
                  Show more
                </Button>
              </Col>
            </Row>
          </CardFooter>
          )
        }

        {
          (
            (limitIndex===LIMITS.levels.length-1) ||
            (LIMITS.levels[limitIndex] >= applications.length && limitIndex>0) ||
            (applications.length===0)
          ) &&
          (<CardFooter className="border-top">
          <Row>
            <Col className="text-center view-report">
              <Button theme="white" type="submit" onClick={()=>{
                this.setState({limitIndex: 0})
              }}>
                {
                  (applications.length>0) ? "Show less" : "No records"
                }
                
              </Button>
            </Col>
          </Row>
          </CardFooter>
          )
        }
        
      </Card>
      )
    };
  }

export default RecentApplications;
